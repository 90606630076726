"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  BistroTheme: () => BistroTheme,
  BonefishgrillCmsTheme: () => BonefishgrillCmsTheme,
  BonefishgrillGiftCardButton: () => BonefishgrillGiftCardButton,
  BonefishgrillGrayButton: () => BonefishgrillGrayButton,
  BonefishgrillSharedTheme: () => BonefishgrillSharedTheme,
  BonefishgrillTheme: () => BonefishgrillTheme,
  BonefishgrillTranparentButton: () => BonefishgrillTranparentButton,
  BonefishgrillUITheme: () => BonefishgrillUITheme,
  BonefishgrillWhiteButton: () => BonefishgrillWhiteButton,
  Brand: () => Brand,
  CarrabbasCmsTheme: () => CarrabbasCmsTheme,
  CarrabbasGrayButton: () => CarrabbasGrayButton,
  CarrabbasSharedTheme: () => CarrabbasSharedTheme,
  CarrabbasTheme: () => CarrabbasTheme,
  CarrabbasUITheme: () => CarrabbasUITheme,
  OutbackCmsTheme: () => OutbackCmsTheme,
  OutbackRedButton: () => OutbackRedButton,
  OutbackSharedTheme: () => OutbackSharedTheme,
  OutbackTheme: () => OutbackTheme,
  OutbackTransparentButton: () => OutbackTransparentButton,
  OutbackUITheme: () => OutbackUITheme
});
module.exports = __toCommonJS(src_exports);

// src/bbi/outback/outbackSharedTheme.ts
var import_styled_components = require("styled-components");
var OutbackSharedTheme = {
  colors: {
    primary: {
      light: "#cd2b2b",
      lightContrast: "#d63d3d",
      medium: "#891a1c",
      dark: "#60181b"
    },
    secondary: {
      light: "#f6f2e8",
      medium: "#e6ddcf",
      dark: "#d3c6b3"
    },
    terciary: {
      light: "#f6f6f6",
      medium: "#757575",
      dark: "#2c2c2b"
    },
    complementary: {
      success: "#2d8633",
      error: "#b00020",
      errorText: "#fff",
      warning: "#ffB61f",
      information: "#1c9Bdb",
      hyperlink: "#3078cd",
      errorMessage: {
        bannerErrorMessage: "#fff",
        compactErrorMessage: "#D63D3D"
      }
    },
    neutral: {
      black: "#000000",
      white: "#ffffff",
      gray: "#eaeaea",
      disabled: "#ABABAA"
    }
  },
  typography: {
    headline: {
      h1: import_styled_components.css`
				font-family: 'Veneer';
				font-size: 48px;
				line-height: 52px;
			`,
      h2: import_styled_components.css`
				font-family: 'Veneer';
				font-size: 40px;
				line-height: 44px;
			`,
      h3: import_styled_components.css`
				font-family: 'Veneer';
				font-size: 32px;
				line-height: 36px;
			`,
      h4: import_styled_components.css`
				font-family: 'Veneer';
				font-size: 24px;
				line-height: 28px;
			`,
      h5: import_styled_components.css`
				font-family: 'Veneer';
				font-size: 20px;
				line-height: 24px;
			`,
      h6: import_styled_components.css`
				font-family: 'Veneer';
				font-size: 16px;
				line-height: 20px;
			`
    },
    subtitle: {
      subtitle: import_styled_components.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`
    },
    body: {
      bodySmall: import_styled_components.css`
				font-size: 14px;
				line-height: 20px;
			`,
      body: import_styled_components.css`
				font-size: 16px;
				line-height: 20px;
			`,
      bodyLarge: import_styled_components.css`
				font-size: 18px;
				line-height: 28px;
			`,
      bodySmallBold: import_styled_components.css`
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
			`
    },
    inputs: {
      buttonSmall: import_styled_components.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;
			`,
      button: import_styled_components.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
			`,
      buttonLarge: import_styled_components.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
			`,
      italicButton: import_styled_components.css`
				font-family: Roboto;
				font-style: italic;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
			`,
      textInputs: import_styled_components.css`
				font-size: 16px;
				line-height: 20px;
			`,
      textInputsSmall: import_styled_components.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    caption: {
      caption: import_styled_components.css`
				font-size: 12px;
				line-height: 16px;
			`,
      captionMedium: import_styled_components.css`
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			`,
      captionLarge: import_styled_components.css`
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
			`
    },
    overline: {
      overline: import_styled_components.css`
				font-size: 10px;
				line-height: 14px;
				letter-spacing: 1.5px;
				text-transform: uppercase;
			`
    }
  },
  background: {
    dark: {
      image: "/image/outback/dark-background-image.png",
      color: "#262524"
    },
    light: {
      image: "",
      color: ""
    }
  }
};

// src/bbi/outback/outbackCmsTheme.ts
var import_IconLocation = require("@maverick/icons/dist/IconLocation");
var import_IconLocationOutline = require("@maverick/icons/dist/IconLocationOutline");
var import_OutbackSign = require("@maverick/icons/dist/OutbackSign");
var import_WarningMarkComplete = require("@maverick/icons/dist/WarningMarkComplete");
var import_styled_components2 = require("styled-components");
var import_IconCurbside = require("@maverick/icons/dist/IconCurbside");
var import_IconDelivery = require("@maverick/icons/dist/IconDelivery");
var OutbackCmsTheme = {
  articlePage: {
    fontFamily: "",
    lineHeight: "",
    a: {
      color: "#891a1c"
    }
  },
  icons: {
    curbside: import_IconCurbside.IconCurbside,
    delivery: import_IconDelivery.IconDelivery,
    iconLocation: import_IconLocation.IconLocation,
    iconLocationOutline: import_IconLocationOutline.IconLocationOutline,
    iconSearchLocation: import_OutbackSign.OutbackSign,
    iconSearchLocationSize: import_styled_components2.css`
			width: 112px;
			height: 202px;
		`,
    iconWarning: import_WarningMarkComplete.WarningMarkComplete
  },
  shortName: "Outback"
};

// src/bbi/outback/outbackComponentsStyle/outbackAccordion.ts
var outbackAccordionStyle = {
  variant: {
    primary: {
      background: "url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Background/20240715-outback-red-background-with-texture.jpg)",
      fontColor: "#ffffff",
      titleColor: "#ffffff",
      subtitleColor: "#ffffff"
    },
    secondary: {
      background: "none",
      fontColor: "#000000",
      titleColor: "#891A1C",
      subtitleColor: "#2C2C2B"
    }
  },
  title: {
    fontFamily: "BigBloke, sans-serif",
    fontColor: "",
    fontWeight: "400",
    fontSizes: {
      small: "32px",
      medium: "44px"
    }
  },
  subtitle: {
    fontFamily: "Roboto, sans-serif",
    fontColor: "",
    fontWeight: "400",
    fontSizes: {
      small: "24px",
      medium: "24px"
    }
  },
  accordionItem: {
    title: {
      fontFamily: "BigBloke, sans-serif",
      fontColor: "#2C2C2B",
      fontWeight: "400",
      fontSizes: {
        small: "24px",
        medium: "24px"
      },
      paddingRight: ""
    },
    text: {
      fontFamily: "Roboto, sans-serif",
      fontColor: "#2C2C2B",
      fontWeight: "400",
      fontSizes: {
        medium: "18px"
      }
    },
    chevronColor: "#2C2C2B",
    textTransform: "uppercase",
    borderColor: "#2C2C2B"
  },
  titleAlign: "center"
};

// src/bbi/outback/outbackComponentsStyle/outbackCardSecondary.ts
var outbackCardSecondaryStyles = {
  title: {
    primary: {
      fontColor: "#931C20",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontSizes: {
        medium: "1.5rem"
      },
      fontWeight: "400"
    },
    white: {
      fontColor: "#FFFFFF",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontSizes: {
        medium: "1.5rem"
      },
      fontWeight: "400"
    }
  },
  notes: {
    fontColor: "#757575",
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "18px"
    },
    fontWeight: ""
  },
  text: {
    primary: {
      fontColor: "#333",
      fontFamily: "Roboto, sans-serif",
      fontSizes: {
        medium: "18px"
      },
      fontWeight: "400"
    },
    white: {
      fontColor: "#FFFFFF",
      fontFamily: "Roboto, sans-serif",
      fontSizes: {
        medium: "18px"
      },
      fontWeight: "400"
    }
  },
  button: {
    primary: {
      fontColor: "#FFFFFF",
      fontFamily: "Roboto-Condensed-Bold",
      fontSizes: {
        medium: "20px"
      },
      fontWeight: "400",
      hoverOpacity: "none",
      borderRadius: "2px",
      borderWidth: "0px",
      hoverColor: "#CD2B2B",
      hoverFontColor: "#FFFFFF",
      padding: "24px",
      backgroundColor: "#891A1C"
    },
    white: {
      fontColor: "#FFFFFF",
      fontFamily: "Roboto-Condensed-Bold",
      fontSizes: {
        medium: "20px"
      },
      fontWeight: "400",
      hoverOpacity: "none",
      borderRadius: "2px",
      borderWidth: "2px",
      hoverColor: "#FFFFFF",
      hoverFontColor: "#2C2C2B",
      padding: "24px",
      backgroundColor: "transparent"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackButtonCms.ts
var outbackButtonCmsStyle = {
  primary: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    lineHeight: "1.2",
    backgroundColor: "#891a1c",
    hover: {
      fontColor: "",
      backgroundColor: "#CD2B2B",
      opacity: 1
    },
    fontWeight: "400",
    fontSizes: {
      medium: "1.5rem",
      large: "1.4rem",
      small: "0.95rem"
    },
    border: {
      color: "transparent",
      radius: "2px",
      width: "",
      style: ""
    },
    fontColor: "#fff",
    padding: "24px",
    paddingMobile: "16px",
    letterSpace: ""
  },
  secondary: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    lineHeight: "1.2",
    fontSizes: {
      medium: "1.5rem",
      large: "1.4rem",
      small: "0.95rem"
    },
    hover: {
      fontColor: "#2C2C2B",
      backgroundColor: "#FFFFFF",
      opacity: 1
    },
    backgroundColor: "transparent",
    fontWeight: "400",
    border: {
      color: "#ffff",
      radius: "2px",
      width: "",
      style: ""
    },
    fontColor: "#ffff",
    padding: "24px",
    paddingMobile: "16px",
    letterSpace: ""
  },
  terciary: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    lineHeight: "1.2",
    fontSizes: {
      large: "1.5rem",
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1.2rem"
    },
    hover: {
      fontColor: "",
      backgroundColor: "",
      opacity: 0.8
    },
    backgroundColor: "transparent",
    fontWeight: "400",
    border: {
      color: "#000",
      radius: "2px",
      width: "",
      style: ""
    },
    fontColor: "#000",
    padding: "24px",
    paddingMobile: "16px",
    letterSpace: ""
  },
  bodyBannerVariant: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    lineHeight: "1.2",
    fontSizes: {
      medium: "1.5rem",
      large: "1.5rem",
      small: "0.95rem"
    },
    hover: {
      fontColor: "#2C2C2B",
      backgroundColor: "#FFFFFF",
      opacity: 1
    },
    backgroundColor: "transparent",
    fontWeight: "400",
    border: {
      color: "#fff",
      radius: "2px",
      width: "",
      style: ""
    },
    fontColor: "#ffff",
    padding: "24px",
    paddingMobile: "16px",
    letterSpace: ""
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackCatalog.ts
var outbackCatalogStyle = {
  title: {
    fontColor: "#2C2C2B",
    fontFamily: "TradeGothic, sans-serif",
    fontSizes: {
      medium: "2rem"
    },
    fontWeight: "700"
  },
  button: {
    fontColor: "#FFFFFF",
    backgroundColor: "#891a1c",
    fontFamily: "TradeGothic, sans-serif",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem"
    },
    fontWeight: "700",
    hover: {
      backgroundColor: "#cd2b2b",
      fontColor: "#FFF",
      opacity: 1
    },
    border: {
      width: "",
      radius: "",
      color: "",
      style: ""
    },
    padding: "16px",
    letterSpace: ""
  },
  backgroundColor: "transparent",
  maxWidthView: "1114px",
  verticalPadding: "20px 0px",
  verticalPaddingMobile: "20px 0px",
  gap: "32px"
};

// src/bbi/outback/outbackComponentsStyle/outbackCatalogItem.ts
var outbackCatalogItemStyle = {
  borderRadius: "0px",
  title: {
    font: {
      fontFamily: "BigBloke",
      fontColor: "#2C2C2B",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "2.25rem",
        small: "2rem",
        smallest: "1.75rem"
      }
    }
  },
  subtitle: {
    font: {
      fontFamily: "Roboto",
      fontColor: "#2C2C2B",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "1.25rem",
        small: "1rem",
        smallest: "0.75rem"
      }
    }
  },
  text: {
    font: {
      fontFamily: "Roboto",
      fontColor: "#2C2C2B",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    }
  },
  note: {
    font: {
      fontFamily: "Gibson-Regular",
      fontColor: "#931c20",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    }
  },
  button: {
    backgroundColor: "#891a1c",
    hover: {
      backgroundColor: "#cd2b2b",
      fontColor: "#FFF",
      opacity: 1
    },
    border: {
      width: "",
      radius: "unset",
      color: "transparent",
      style: ""
    },
    padding: "12px 16px",
    letterSpace: "1px",
    fontColor: "#FFF",
    fontFamily: "TradeGothic",
    fontWeight: "bold",
    fontSizes: {
      smallest: void 0,
      small: "1.25rem",
      medium: "1.5rem",
      large: void 0,
      largest: void 0
    },
    marginRight: "auto"
  },
  backgroundColor: {
    default: "#fff",
    verticalView: "#fff"
  },
  verticalView: {
    image: {
      padding: "0"
    },
    textAlign: "left"
  },
  margin: "auto 0"
};

// src/bbi/outback/outbackComponentsStyle/outbackCatalogItemSecondary.ts
var outbackCatalogItemSecondaryStyle = {
  title: {
    font: {
      fontFamily: "TradeGothic, sans-serif",
      fontColor: "#2C2C2B",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "0.75rem",
        smallest: "0.5rem"
      }
    },
    textTransform: "uppercase",
    marginBottom: "12px"
  },
  subtitle: {
    font: {
      fontFamily: "BigBloke, sans-serif",
      fontColor: "#2C2C2B",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "40px",
        small: "36px",
        smallest: "32px"
      }
    },
    lineHeightMobile: "normal",
    marginBottom: "27px"
  },
  text: {
    font: {
      fontFamily: "Roboto, sans-serif",
      fontColor: "#2C2C2B",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    },
    marginBottom: "24px"
  },
  button: {
    font: {
      fontFamily: "TradeGothic, sans-serif",
      fontColor: "#891A1C",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "24px",
        small: "24px",
        smallest: "24px"
      }
    },
    marginTop: "44px",
    marginTopMobile: "16px",
    padding: "12px 0px",
    marginInternal: "",
    iconColor: "#891A1C"
  },
  backgroundColor: "#fff",
  contentPaddingMobile: "0px 36px",
  contentPadding: "0px 76px",
  containerFlex: "",
  alignMain: "center",
  verticalView: {
    fontColor: "",
    title: {
      fontFamily: "TradeGothic, sans-serif"
    },
    subtitle: {
      fontFamily: "TradeGothic, sans-serif"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackInputCms.ts
var outbackInputCmsStyle = {
  primary: {
    input: {
      fontColor: "#262524",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: "14px",
        large: "1.29rem",
        largest: "1.37rem"
      },
      lineHeights: {
        medium: "4.08rem",
        large: "4.36rem",
        largest: "4.63rem"
      },
      background: "#fff",
      placeholderFontColor: "transparent"
    },
    label: {
      fontColor: "#ffffff",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: "1.12rem"
      },
      lineHeights: {
        medium: "1.2rem"
      },
      initialLabelColor: "#ffff",
      finalLabelColor: "#ffff"
    },
    error: {
      fontColor: "",
      fontFamily: "Roboto",
      fontWeight: "",
      fontSizes: {
        small: "0.75rem",
        medium: "0.8rem",
        large: "0.85rem",
        largest: "0.9rem"
      },
      lineHeights: {
        small: "0.9rem",
        medium: "0.95rem",
        large: "1rem",
        largest: "1.07rem"
      }
    },
    borderColor: "#e6ddcf"
  },
  secondary: {
    input: {
      fontColor: "#a87139",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: "16px"
      },
      lineHeights: {
        medium: "1.2"
      },
      background: "",
      placeholderFontColor: "#757575"
    },
    label: {
      fontColor: "",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: ""
  },
  terciary: {
    input: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      background: "",
      placeholderFontColor: "transparent"
    },
    label: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#e8dcc2"
  },
  modal: {
    input: {
      fontColor: "",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "400",
      fontSizes: {},
      lineHeights: {},
      background: "",
      placeholderFontColor: "transparent"
    },
    label: {
      fontColor: "#121211",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: "",
      display: "block"
    },
    error: {
      fontColor: "#121211",
      fontFamily: "Roboto, sans-serif",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#818181"
  },
  compact: {
    input: {
      fontColor: "#2C2C2B",
      fontFamily: "Roboto-Condensed-Bold",
      fontWeight: "400",
      fontSizes: { medium: "14px" },
      lineHeights: { medium: "16.79px" },
      background: "",
      placeholderFontColor: ""
    },
    label: {
      fontColor: "#F6F6F6",
      fontFamily: "Roboto-Condensed-Bold",
      fontWeight: "400",
      fontSizes: { medium: "12px" },
      lineHeights: { medium: "14.39px" },
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "inherit",
      fontFamily: "Roboto-Condensed-Bold",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#FFFFFF"
  },
  height: "68px"
};

// src/bbi/outback/outbackComponentsStyle/outbackJumbotron.ts
var outbackJumbotronStyles = {
  title: {
    fontColor: "#891A1C",
    fontFamily: "BigBloke, sans-serif",
    fontSizes: {
      medium: "2.75rem",
      small: "2rem"
    },
    fontWeight: "400"
  },
  text: {
    fontColor: "#232222",
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "1.5rem",
      small: "1.25rem"
    },
    fontWeight: "400",
    lineHeight: {
      small: "1.5rem",
      medium: "2rem"
    },
    paddingBottom: "32px"
  },
  singleButton: {
    fontColor: "#FFFFFF",
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem"
    },
    fontWeight: "400",
    backgroundColor: "#891A1C",
    border: {
      radius: "2px",
      color: "",
      width: "",
      style: ""
    },
    hover: {
      backgroundColor: "#CD2B2B",
      fontColor: "",
      opacity: 0.8
    },
    padding: "16px",
    letterSpace: "1px",
    lineHeight: "28px"
  },
  button: {
    fontColor: "#FFFFFF",
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontSizes: {
      small: "1.25rem",
      medium: "1.25rem",
      large: "1.5rem"
    },
    fontWeight: "400",
    backgroundColor: "#891A1C",
    hover: {
      backgroundColor: "#CD2B2B",
      fontColor: "",
      opacity: 0.8
    },
    border: {
      radius: "2px",
      color: "",
      width: "",
      style: ""
    },
    padding: "24px",
    letterSpace: "1px",
    paddingMobile: "16px"
  },
  notes: {
    fontColor: "#232222",
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "1rem"
    },
    fontWeight: "400"
  },
  padding: "30px",
  paddingMobile: "0px",
  titlePadding: "16px 0px"
};

// src/bbi/outback/outbackComponentsStyle/outbackLine.ts
var outbackLine = {
  text: {
    font: {
      fontColor: "#666666",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.187rem",
        small: "1.187rem",
        smallest: "1.187rem"
      }
    }
  },
  borderTop: {
    Dotted: "1px dashed #9e1d21",
    Solid: "1px solid #666666"
  },
  backgroundColor: "transparent"
};

// src/bbi/outback/outbackComponentsStyle/outbackRewardsModal.ts
var outbackRewardsModal = {
  backgroundColor: "#E5C8A2",
  closeIconColor: "#891A1C",
  checkbox: {
    fontColor: "#121211",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontSize: "15px",
    fontStyle: "italic",
    border: "none"
  },
  logoColor: "#121211",
  button: {
    backgroundColor: "#891A1C",
    fontColor: "#FFFFFF",
    borderColor: "transparent",
    hoverColor: "#CD2B2B"
  },
  title: {
    fontColor: "#121211",
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      small: "1.6rem",
      medium: "1.9rem",
      large: "2.1rem"
    }
  },
  subtitle: {
    fontColor: "#121211",
    fontFamily: "Roboto, sans-serif"
  },
  image: {
    width: "150px",
    height: "58px"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackNewsletterForm.ts
var outbackNewsletterForm = {
  background: "url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/texture-slate.png) 0 0 repeat #262524",
  title: {
    fontSizes: {
      medium: "2.25rem"
    },
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "bold",
    fontColor: "#ffffff"
  },
  agreement: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "bold",
    fontColor: "#ffffff",
    fontSizes: {
      medium: "0.75rem"
    }
  },
  button: {
    fontWeight: "bold",
    fontFamily: "TradeGothic, sans-serif",
    fontColor: "#ffffff",
    fontSizes: {
      smallest: "1.25rem",
      small: "1.45rem",
      medium: "1.6rem",
      large: "1.85rem",
      largest: "1.95rem"
    },
    backgroundColor: "transparent",
    borderColor: "#ffffff"
  },
  form: {
    fontFamily: "TradeGothic, sans-serif",
    fontColor: "#ffffff",
    fontWeight: ""
  },
  response: {
    fontFamily: "TradeGothic, sans-serif",
    fontColor: "#ffffff",
    fontWeight: "600",
    fontSizes: {
      small: "1.75rem",
      medium: "2rem",
      large: "2.25rem"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackSectionBanner.ts
var outbackSectionBanner = {
  backgroundColor: "#262524",
  fontColor: "#ffff",
  aside: {
    fontColor: "",
    fontFamily: "jenna-sue, sans-serif",
    fontWeight: "",
    fontSizes: {
      small: "2.2rem",
      medium: "2.4rem"
    }
  },
  image: {
    desktop: {
      height: "600px",
      width: "auto"
    },
    mobile: {
      height: "unset",
      width: "80%"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackTabNavigationItem.ts
var outbackTabNavigationItem = {
  backgroundColorInactive: "#FFFFFF",
  backgroundColorActive: "#891A1C",
  separatorColor: "#757575",
  link: {
    font: {
      fontColor: "#121211",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "0.75rem"
      }
    }
  },
  linkActiveColor: "#ffffff",
  underlineColor: {
    inactive: {
      zeroOpacity: "rgba(64, 64, 65, 0)",
      fullOpacity: "rgba(64, 64, 65, 1)"
    },
    active: {
      zeroOpacity: "rgba(255, 255, 255, 0)",
      fullOpacity: "rgba(255, 255, 255, 1)"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackQuestionsAndAnswers.ts
var outbackQuestionsAndAnswers = {
  title: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "600",
    fontColor: "#666666",
    fontSizes: {
      medium: "4rem",
      small: "1.5rem"
    }
  },
  text: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "2rem",
      small: "1.25rem"
    }
  },
  pagination: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1rem",
      small: "0.75rem"
    }
  },
  arrowColor: "#2c2c2b",
  paginationBorderColor: "#666666"
};

// src/bbi/outback/outbackComponentsStyle/outbackQuestionsAndAnswersItem.ts
var outbackQuestionsAndAnswersItem = {
  name: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  questionAnswer: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1.125rem"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackLateralBar.ts
var outbackLateralBar = {
  backgroundColor: "#931a1a",
  font: {
    fontFamily: "TradeGothic, sans-serif",
    fontColor: "#ffffff",
    fontWeight: "600",
    fontSizes: {
      medium: "16px"
    }
  },
  opacity: "0.8",
  hoverColor: "#ffffff",
  hoverBackground: "#931a1a",
  border: "",
  borderRadius: "4px",
  padding: "8px"
};

// src/bbi/outback/outbackComponentsStyle/outbackLegalPages.ts
var outbackLegalPagesStyle = {
  backgroundColor: "#f6f2e8",
  header: {
    title: {
      fontColor: "#891A1C",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    },
    subtitle: {
      fontColor: "#891A1C",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    }
  },
  mainContent: {
    subtitles: {
      fontColor: "#891A1C",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "700",
      fontSizes: {
        medium: "1.2rem"
      }
    },
    texts: {
      fontColor: "#000",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: ""
      }
    }
  },
  table: {
    backgroundColor: "#262524",
    borderColor: "#ddd"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackVideoTextButton.ts
var outbackVideoTextButton = {
  title: {
    fontColor: "#232222",
    fontFamily: "TradeGothic, sans-serif",
    fontSizes: {
      medium: "2rem",
      small: "1.5rem"
    },
    fontWeight: "600"
  },
  text: {
    fontColor: "#232222",
    fontFamily: "TradeGothic, sans-serif",
    fontSizes: {
      medium: "1.25rem"
    },
    fontWeight: "400"
  },
  note: {
    fontColor: "#232222",
    fontFamily: "TradeGothic, sans-serif",
    fontSizes: {
      medium: "1.25rem"
    },
    fontWeight: "400"
  },
  button: {
    fontColor: "#ffffff",
    fontFamily: "TradeGothic, sans-serif",
    fontSizes: {
      small: "0.875rem",
      medium: "1.5rem"
    },
    fontWeight: "600",
    backgroundColor: "#404041"
  },
  lineColor: "#232222"
};

// src/bbi/outback/outbackComponentsStyle/outbackHero.ts
var outbackHero = {
  button: {
    primary: {
      borderRadius: "2px",
      fontColor: "#FFFFFF",
      fontWeight: "400",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      letterSpacing: "0",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem"
      },
      backgroundColor: "none",
      hover: {
        opacity: 1,
        backgroundColor: "#FFFFFF",
        letterSpacing: "0",
        font: {
          fontColor: "#000000",
          fontFamily: "Roboto-Condensed-Regular, sans-serif",
          fontWeight: "400"
        }
      },
      borderWidth: "2px",
      lineHeight: "1.2",
      padding: "16px"
    },
    secondary: {
      borderRadius: "2px",
      fontColor: "#FFFFFF",
      fontWeight: "400",
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      letterSpacing: "0",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem"
      },
      backgroundColor: "#891A1C",
      hover: {
        opacity: 1,
        backgroundColor: "#CD2B2B",
        letterSpacing: "0",
        font: {
          fontColor: "#FFFFFF",
          fontFamily: "Roboto-Condensed-Regular, sans-serif",
          fontWeight: "400"
        }
      },
      borderWidth: "0",
      lineHeight: "1.2",
      padding: "16px"
    }
  },
  overTitle: {
    fontColor: "#FFFFFF",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "600",
    textShadow: "0",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    },
    letterSpacing: "unset"
  },
  title: {
    fontColor: "#FFFFFF",
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "400",
    textShadow: "0",
    fontSizes: {
      medium: "3rem",
      small: "3rem",
      smallest: "2rem"
    },
    textTransform: "uppercase",
    lineHeight: {
      medium: "3.6rem",
      small: "3.6rem",
      smallest: "2.4rem"
    }
  },
  text: {
    fontColor: "#FFFFFF",
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "400",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    }
  },
  container: {
    background: "linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.2) 100%);"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackPod.ts
var outbackPodStyle = {
  large: {
    title: {
      fontFamily: "BigBloke, sans-serif",
      fontSizes: {
        smallest: "32px",
        small: "32px",
        medium: "36px",
        large: "36px",
        largest: "36px"
      },
      fontColor: { desktop: "#fff", mobile: "#891A1C" },
      fontWeight: "400",
      textShadow: "none"
    },
    boxShadow: "",
    borders: "",
    description: {
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontSizes: {
        smallest: "14px",
        small: "14px",
        medium: "16px",
        large: "16px",
        largest: "16px"
      },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "400",
      textTransform: "uppercase"
    },
    button: {
      backgroundColor: { desktop: "#891A1C", mobile: "#891A1C" },
      font: {
        fontFamily: "Roboto-Condensed-Bold, sans-serif",
        fontSizes: {
          smallest: "20px",
          small: "20px",
          medium: "24px",
          large: "24px",
          largest: "24px"
        },
        fontColor: { desktop: "#fff", mobile: "#fff" },
        fontWeight: "400"
      },
      borderColor: { desktop: "none", mobile: "none" },
      hoverColor: { desktop: "#cd2b2b", mobile: "#cd2b2b", fontColor: "" },
      paddingBottom: {
        largest: "35px"
      }
    },
    padding: "",
    flexDirectionMobile: "column-reverse"
  },
  middle: {
    title: {
      fontFamily: "BigBloke, sans-serif",
      fontSizes: {},
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "normal",
      paddingMobile: "0px",
      textShadow: "none"
    },
    borders: "",
    description: {
      fontFamily: "Roboto, sans-serif",
      fontSizes: {},
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "700",
      textTransform: ""
    },
    button: {
      backgroundColor: { desktop: "transparent", mobile: "transparent" },
      font: {
        fontFamily: "Roboto-Condensed-Bold, sans-serif",
        fontSizes: {},
        fontColor: { desktop: "#fff", mobile: "#000" },
        fontWeight: "400"
      },
      borderColor: { desktop: "#fff", mobile: "#000" },
      hoverColor: { desktop: "#fff", mobile: "#fff", fontColor: "#2C2C2B" }
    },
    padding: "10px 15px"
  },
  small: {
    title: {
      fontFamily: "BigBloke, sans-serif",
      fontSizes: {
        smallest: "1.625rem",
        small: "1.775rem",
        medium: "2rem",
        large: "2.25rem",
        largest: "2.5rem"
      },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "normal",
      paddingMobile: "0px",
      textShadow: "none"
    },
    borders: "",
    description: {
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      fontSizes: { medium: "1rem" },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "400",
      textTransform: ""
    },
    button: {
      backgroundColor: { desktop: "transparent", mobile: "transparent" },
      font: {
        fontFamily: "Roboto-Condensed-Bold, sans-serif",
        fontSizes: {
          smallest: "20px",
          small: "20px",
          medium: "20px",
          large: "20px",
          largest: "20px"
        },
        fontColor: { desktop: "#fff", mobile: "#000" },
        fontWeight: "400"
      },
      borderColor: { desktop: "#fff", mobile: "#000" },
      hoverColor: { desktop: "#fff", mobile: "#fff", fontColor: "#2C2C2B" }
    },
    padding: ""
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackHeader.ts
var outbackHeaderStyle = {
  image: {
    width: "96px",
    height: "36px"
  },
  title: {
    fontSizes: {
      small: "1.2rem",
      medium: "16px"
    }
  },
  subTitle: {
    fontSizes: {
      small: "14px",
      medium: "18px"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackSeating.ts
var outbackSeating = {
  container: {
    background: "",
    color: "#262524",
    h1: {
      fontFamily: "Roboto-Condensed-Bold",
      fontWeight: "normal"
    }
  },
  location: {
    color: "#931a1a",
    div: {
      margin: ""
    },
    span: {
      fontFamily: "Roboto-Condensed-Bold",
      fontWeight: "",
      textTransform: "uppercase",
      margin: "0 0 0 4px",
      fontSizes: {
        medium: "1.4rem"
      }
    }
  },
  button: {
    background: "#931a1a",
    margin: "23px 0 0",
    border: "2px solid #931a1a",
    hover: {
      opacity: "0.9"
    },
    secondary: { background: "transparent", backgroundColor: "#931a1a", border: "" },
    fontFamily: "Roboto-Condensed-Bold",
    fontWeight: ""
  },
  info: {
    fontFamily: "Roboto",
    a: {
      hover: {
        color: "#931a1a",
        opacity: ""
      }
    }
  },
  guestInfo: {
    margin: "",
    border: "",
    color: "",
    height: ""
  },
  modalContent: {
    background: "#f6f2e8",
    fontFamily: {
      container: "Roboto-Condensed-Bold",
      content: "Roboto"
    }
  },
  modalClose: {
    color: "#a10000"
  },
  successContainer: {
    background: "url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Seating/seating-success-bg.webp) center no-repeat",
    backgroundColor: "",
    minHeight: "90vh"
  },
  successContent: {
    textTransform: "uppercase",
    h1: {
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      textTransform: "",
      fontSizes: {
        medium: "3.4rem"
      }
    },
    h2: {
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      lineHeight: "",
      lineWeight: "",
      fontWeight: "normal",
      marginTop: "0",
      fontSizes: {
        medium: "1.8rem"
      }
    },
    p: {
      fontWeight: "bold",
      fontFamily: "Roboto-Condensed-Regular, sans-serif",
      fontSizes: {
        medium: "1.8rem"
      },
      lineHeight: "",
      margin: "32px 0 0"
    },
    button: {
      background: "transparent",
      borderColor: "#fff",
      color: "",
      fontFamily: "Roboto-Condensed-Bold",
      lineHeight: "",
      fontSizes: {
        medium: ""
      },
      hover: {
        opacity: "",
        background: "#fff",
        color: "#000"
      }
    }
  },
  item: {
    fontWeight: "normal",
    fontFamily: "Roboto-Condensed-Bold",
    borderBottom: "",
    p: {
      a: {
        textDecoration: "underline",
        hover: {
          color: "#a10000",
          opacity: ""
        }
      }
    }
  },
  detailsButton: {
    p: {
      padding: "",
      alignCenter: "",
      margin: "0 0 0 24px",
      mediaQuery: {
        viewport768px: {
          padding: ""
        }
      },
      fontFamily: "Roboto",
      hover: {
        opacity: "",
        color: "#a10000"
      }
    }
  },
  inline: {
    fontFamily: "Roboto"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackContainer.ts
var outbackContainerStyle = {
  background: ""
};

// src/bbi/outback/outbackComponentsStyle/outbackCmsDropdown.ts
var outbackCmsDropdownStyle = {
  label: {
    textTransform: "uppercase",
    fontFamily: "Roboto-Condensed-Bold",
    fontSize: "1.12rem",
    fontWeight: ""
  },
  wrapper: {
    border: "2px solid #e8dcc2",
    fontFamily: "Roboto-Condensed-Bold",
    fontWeight: ""
  },
  chevron: {
    margin: "auto 0"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackTopMessage.ts
var outbackTopMessage = {
  backgroundColor: "#891a1c",
  arrowColor: "#ffffff",
  buttonColor: "#ffffff",
  text: {
    fontColor: "#ffffff",
    fontFamily: "IntroRust, sans-serif",
    fontWeight: "400",
    fontSizes: {
      small: "12px",
      medium: "15px",
      large: "18px"
    },
    paddingTop: "0"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackCarousel.ts
var outbackCarouselStyle = {
  arrows: {
    height: "calc(100% - 44px)"
  },
  dots: {
    iconPlayAndPause: {
      background: "#f6f2e8",
      color: "#891A1C"
    },
    background: "#f6f2e8",
    backgroundMobile: "#f6f2e8",
    width: "32px",
    mobileWidth: "24px",
    height: "8px",
    radius: "10px"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackDrawer.ts
var outbackDrawerStyle = {
  background: "#f9ebde"
};

// src/bbi/outback/outbackComponentsStyle/outbackDRForm.ts
var outbackDRFormStyle = {
  button: {
    fontColor: "#FFFFFF",
    backgroundColor: "transparent",
    fontWeight: "400",
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontSizes: { small: "1.25rem", medium: "1.5rem", large: "1.7rem" },
    hoverColor: "#fff",
    border: "2px solid transparent",
    hoverFontColor: "#2C2C2B",
    borderRadius: "2px",
    opacity: "",
    hoverFontColorModal: "#FFFFFF",
    hoverColorModal: "#CD2B2B",
    letterSpacing: ""
  },
  texts: {
    title: {
      fontColor: "#FFFFFF",
      fontSizes: { small: "1.75rem", medium: "2rem", large: "2.25rem" },
      fontFamily: "Bigbloke, sans-serif",
      fontWeight: "400"
    },
    description: {
      fontColor: "#FFFFFF",
      fontSizes: { medium: "1rem" },
      fontFamily: "Roboto-Condensed-Regular, sans-serif",
      fontWeight: "400"
    },
    label: {
      fontColor: "#FFFFFF",
      fontFamily: "Arial, sans-serif",
      fontWeight: "600"
    }
  },
  backgroundColor: "#262524",
  backgroundImage: "url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Background/20240715-outback-red-background-with-texture.jpg) 0 0 repeat",
  backgroundModalImage: "",
  borders: "#FFF"
};

// src/bbi/outback/outbackComponentsStyle/outbackFooter.ts
var outbackFooterStyle = {
  borderTop: "none",
  letterSpacing: "-1px",
  backgroundColor: "#E5C8A2",
  navbar: {
    marginBottom: "0px",
    color: "#121211",
    featuredItems: {
      fontFamily: "Roboto-Condensed-Bold, sans-serif",
      menuItem: {
        fontSizes: {
          smallest: "0.8rem",
          small: "0.85rem",
          medium: "0.9rem",
          large: "0.95rem",
          largest: "1rem"
        }
      }
    },
    unfeaturedItems: {
      fontFamily: "Roboto-Condensed-Regular, sans-serif",
      menuItem: {
        fontSizes: {
          smallest: "0.7rem",
          small: "0.75rem",
          medium: "0.8rem",
          large: "0.85rem",
          largest: "0.9rem"
        }
      }
    }
  },
  socialMediaPanel: {
    fontFamily: "Roboto-Condensed-Regular, sans-serif",
    fontSizes: {
      medium: "0.7rem",
      large: "0.75rem",
      largest: "0.8rem"
    }
  },
  cookiesBar: {
    backgroundColor: "#232222",
    fontFamily: "Roboto-Condensed-Bold, sans-serif"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackAnimatedCard.ts
var outbackAnimatedCard = {
  title: {
    textAlign: "center",
    textTranform: "uppercase",
    fontColor: "#931C20",
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "2rem",
      large: "2.25rem"
    }
  },
  text: {
    fontColor: "#404041",
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontSizes: {
      medium: "1rem"
    },
    textAlign: "center"
  },
  linkText: {
    textAlign: "center",
    fontColor: "#ffffff",
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "2rem"
    },
    lineHeight: "2.75rem"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackNavBar.ts
var outbackNavBarStyle = {
  primary: {
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "400",
    fontColor: "",
    fontSizes: {
      default: "24px"
    },
    color: "#E5C8A2",
    backgroundColor: "#891A1C"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackBodyBanner.ts
var outbackBodyBannerStyle = {
  padding: {
    desktop: "48px 0",
    mobile: ""
  },
  content: {
    marginLeft: {
      desktop: "48px",
      mobile: "0px"
    },
    gap: {
      desktop: "",
      mobile: ""
    }
  },
  main: {
    marginLeft: {
      desktop: "40px",
      mobile: "0"
    },
    gap: {
      desktop: "",
      mobile: ""
    }
  },
  backgroundColor: "",
  overtitle: {
    fontFamily: "Roboto, sans-serif",
    fontColor: "#fff",
    fontWeight: "400",
    fontSizes: {}
  },
  text: {
    fontFamily: "Roboto, sans-serif",
    fontColor: "#fff",
    fontWeight: "400",
    fontSizes: { medium: "18px", small: "1rem" }
  },
  title: {
    fontFamily: "BigBloke, sans-serif",
    fontColor: "#fff",
    fontWeight: "400",
    fontSizes: { medium: "2rem", small: "26px" }
  },
  subtitle: {
    fontFamily: "Roboto, sans-serif",
    fontColor: "#fff",
    fontWeight: "400",
    fontSizes: { medium: "1.5rem", small: "5vw" }
  },
  nav: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontColor: "#fff",
    fontWeight: "400",
    fontSizes: { medium: "1rem", small: "0.8rem" },
    justifyContent: "space-between",
    marginTop: {
      desktop: "40px",
      mobile: "20px"
    },
    paddingRight: {
      desktop: "80px",
      mobile: "0"
    }
  },
  button: {
    fontColor: "#fff",
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontSizes: { medium: "18px", small: "16px" },
    fontWeight: "400",
    hover: { fontColor: "#2c2c2b", backgroundColor: "#fff", borderColor: "#fff" }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackAnimatedHero.ts
var outbackAnimatedHeroStyle = {
  title: {
    fontColor: "#333333",
    fontFamily: "BigBloke",
    fontSizes: {
      medium: "clamp(2.5rem,10vw,3.5rem)"
    },
    fontWeight: "400"
  },
  text: {
    fontColor: "",
    fontFamily: "BigBloke",
    fontSizes: {},
    fontWeight: ""
  },
  imageBannerMargin: ""
};

// src/bbi/outback/outbackComponentsStyle/outbackMenuNavigation.ts
var outbackMenuNavigation = {
  backgroundColorInactive: "#FFFFFF",
  backgroundColorActive: "#891A1C",
  separatorColor: "#757575",
  link: {
    font: {
      fontColor: "#121211",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "700"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "0.75rem"
      }
    },
    lineHeight: "20px"
  },
  linkActiveColor: "#ffffff",
  underlineColor: {
    inactive: {
      zeroOpacity: "rgba(64, 64, 65, 0)",
      fullOpacity: "rgba(64, 64, 65, 1)"
    },
    active: {
      zeroOpacity: "rgba(255, 255, 255, 0)",
      fullOpacity: "rgba(255, 255, 255, 1)"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackMenuItem.ts
var outbackMenuItem = {
  fontColor: "#404041",
  newMessageColor: "#931C20",
  title: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontColor: "#404041",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  subTitle: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "600",
    fontColor: "#404041",
    fontSizes: {
      medium: "1rem"
    }
  },
  description: {
    fontFamily: "TradeGothic, sans-serif",
    fontWeight: "400",
    fontColor: "#404041",
    fontSizes: {
      medium: "1rem"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackReservations.ts
var outbackReservations = {
  floatingLabelColor: ""
};

// src/bbi/outback/outbackComponentsStyle/outbackDeliveryContent.ts
var outbackDeliveryContentStyle = {
  headerTitle: {
    fontFamily: "Roboto-Bold, sans-serif",
    fontSizes: {
      large: "20px"
    },
    fontColor: "#121211",
    fontWeight: "600"
  },
  inputLabel: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  selectAddressButton: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      small: "12px",
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "500"
  },
  mainText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "700"
  },
  subText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  headerButtonsColor: "#2C2C2B",
  backgroundColor: "#f6f2e8"
};

// src/bbi/outback/outbackComponentsStyle/outbackDrawerMessage.ts
var outbackDrawerMessageStyle = {
  mainText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "700"
  },
  subText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  color: "#D5D0C3",
  backgroundColor: "#F6F2E8"
};

// src/bbi/outback/outbackComponentsStyle/outbackVideo.ts
var outbackVideo = {
  container: {
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "400",
    fontColor: "",
    textAlign: "",
    padding: "40px 0",
    light: {
      fontColor: "#fff",
      backgroundColor: "transparent"
    },
    dark: {
      fontColor: "#2c2c2b",
      backgroundColor: "transparent"
    }
  },
  title: {
    fontSizes: {
      medium: "32px"
    },
    textAlignMobile: "center"
  },
  text: {
    fontFamily: "Roboto",
    fontSizes: {
      small: "24px"
    },
    textAlignMobile: "center"
  },
  play: {
    fontColor: "#ffffff"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackBanner.ts
var outbackBannerStyle = {
  padding: "120px 0",
  paddingMobile: "60px 0",
  title: {
    fontFamily: "TradeGothic, sans-serif",
    fontColor: "#fff",
    fontWeight: "500",
    fontSizes: { medium: "2.6rem", small: "2.4rem" }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackCategoryItem.ts
var outbackCategoryItemStyle = {
  container: {
    backgroundImage: "",
    color: "#2C2C2B",
    padding: "3px 3px 3px 0"
  },
  content: {
    backgroundColor: "#E6DDCF",
    borderRadius: "0 0.25rem 0.25rem 0"
  },
  name: {
    fontFamily: "Roboto",
    fontSize: {
      fontSizes: {
        medium: "1.125rem",
        small: "0.875rem"
      }
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackProductMenu.ts
var SITECORE_IMAGES_URL = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Order`;
var outbackProductMenu = {
  backgroundColor: "#E5C8A2",
  itemTitle: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "16px"
    }
  },
  itemSubTitle: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "500",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "14px"
    }
  },
  containerTitle: {
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "400",
    fontColor: "#000000",
    fontSizes: {
      medium: "32px"
    },
    textTransform: "uppercase"
  },
  productImagePlaceholder: `${SITECORE_IMAGES_URL}/outback-product-placeholder.webp`,
  detailsTitle: {
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      small: "32px",
      medium: "36px"
    },
    textTransform: "uppercase"
  },
  detailsSubtitle: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  },
  detailsDescription: {
    fontFamily: "Roboto-Condensed-Regular, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  },
  nutritionalDescription: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "20px",
      small: "12px"
    }
  },
  detailsCalories: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      small: "14px",
      medium: "16px"
    }
  },
  detailsMoreNutritionalInfo: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      small: "14px",
      medium: "16px"
    }
  },
  detailsTag: {
    fontFamily: "IntroRust, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      small: "12px",
      medium: "16px"
    }
  },
  detailsReturnButton: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif"
  },
  highlightTag: {
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "700",
    fontColor: "#FFFFFF",
    fontSizes: {
      small: "12px",
      medium: "16px"
    }
  },
  restrictionTag: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      small: "12px",
      medium: "14px"
    }
  },
  highlightTagBackground: "#891A1C",
  detailsBackgroundColor: "#E5C8A2",
  relatedProducts: {
    title: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: "700",
      fontColor: "#FFFFFF",
      fontSizes: {
        medium: "16px",
        small: "14px"
      }
    },
    subtitle: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: "500",
      fontColor: "#EAEAEA",
      fontSizes: {
        medium: "14px",
        small: "16px"
      }
    }
  },
  descriptionCategory: {
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#E6DDCF",
    lineHeight: "14px",
    fontColor: "#2C2C2B"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackDineRewardsForm.ts
var outbackDineRewardsForm = {
  banner: {
    container: {
      maxWidth: ""
    },
    titleContainer: {
      font: { fontColor: "#FFFFFF", fontFamily: "Bigbloke", fontWeight: "400" },
      textTransform: "uppercase"
    },
    title: {
      fontSize: { fontSizes: { medium: "23px", small: "23px" } },
      lineHeight: { desktop: "27.6px", mobile: "27.6px" },
      content: "Want A FREE BLOOMIN\u2019 ONION\xAE, MATE?"
    },
    subtitle: {
      fontSize: { fontSizes: { medium: "20px", small: "16px" } },
      lineHeight: { desktop: "24px", mobile: "19.2px" },
      content: "Sign up for dine rewards now!"
    },
    backgroundColor: "#2C2C2B",
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "Roboto-Condensed-Bold", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "19.18px", mobile: "19.18px" },
      backgroundColor: "transparent",
      hoverbackgroundColor: "#fff",
      content: "I want my free bloomin\u2019 onion\xAE",
      border: "2px solid #FFF",
      hoverBannerFontColor: "#2C2C2B"
    },
    mobile: {
      padding: {
        desktopBreakpoint: "24px 46px 24px 80px",
        desktopLargeBreakpoint: "24px 46px 24px 80px"
      }
    },
    imageUrl: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Dine-Rewards-Form/DR-form-outback-banner-956-490.webp"
  },
  form: {
    information: {
      font: { fontColor: "#EAEAEA", fontFamily: "TradeGothic", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "12px", small: "12px" } },
      lineHeight: { desktop: "13.99px", mobile: "13.99px" },
      iconColor: "#FFFFFF",
      fontColorCompact: "#2C2C2B",
      iconColorCompact: "#121211"
    },
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "Roboto-Condensed-Bold", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "19.18px", mobile: "19.18px" },
      backgroundColor: {
        banner: "transparent",
        compact: "#891A1C"
      },
      hoverbackgroundColor: {
        banner: "#FFFFFF",
        compact: "#CD2B2B"
      },
      content: "Sign Up",
      border: {
        banner: "2px solid #fff",
        compact: "none"
      },
      hoverBannerFontColor: "#2C2C2B"
    },
    description: {
      font: { fontColor: "#FFFFFF", fontFamily: "Roboto-Condensed-Regular", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "12px", small: "12px" } },
      lineHeight: { desktop: "13.99px", mobile: "13.99px" },
      brand: "Outback",
      fontColorCompact: "#404041"
    }
  },
  compact: {
    title: {
      font: { fontColor: "#FFFFFF", fontFamily: "BigBloke", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "1.5rem", small: "1.5rem" } },
      lineHeight: { desktop: "28.8px", mobile: "28.8px" },
      firstContent: "Want A FREE BLOOMIN\u2019 ONION\xAE?",
      secondContent: "sign up for dine rewards now, Mate!",
      textTransform: "uppercase"
    },
    backgroundColor: "#2C2C2B",
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "Roboto-Condensed-Bold", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "19.18px", mobile: "19.18px" },
      backgroundColor: "transparent",
      hoverbackgroundColor: "#fff",
      content: "I want my free bloomin\u2019 onion\xAE",
      border: "2px solid #fff",
      hoverBannerFontColor: "#2C2C2B"
    },
    modal: {
      title: {
        font: { fontColor: "#404041", fontFamily: "BigBloke", fontWeight: "400" },
        fontSize: { fontSizes: { medium: "24px" } },
        lineHeight: { desktop: "28.8px", mobile: "28.8px" },
        content: "JOINING DINE REWARDS IS A BLOOMIN\u2019BRILLIANT IDEA"
      },
      description: {
        font: { fontColor: "#2C2C2B", fontFamily: "Roboto", fontWeight: "" },
        fontSize: { fontSizes: { medium: "14px" } },
        lineHeight: { desktop: "16.8px", mobile: "16.8px" },
        content: `Join Dine Rewards today and get a FREE, golden, delicious and extra crispy Bloomin\u2019 Onion\xAE and start earning points with every purchase.`,
        infoContent: { fontFamily: "Roboto" }
      },
      icon: { color: "#891A1C", hoverColor: "#60181b" },
      logo: {
        url: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/outback-red-logo.svg",
        width: "127px"
      },
      backgroundColor: "#E5C8A2",
      labelColor: "#2C2C2B"
    }
  },
  feature: {
    description: {
      font: { fontColor: "#ffffff", fontFamily: "Roboto-Condensed-Bold", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "19.2px", mobile: "19.2px" }
    },
    icon: {
      color: "#fff",
      iconSize: {
        width: "32px",
        height: "32px",
        minWidth: "32px",
        minHeight: "32px"
      }
    }
  },
  successCard: {
    title: {
      font: { fontColor: "#FFFFFF", fontFamily: "Bigbloke", fontWeight: "400" },
      textTransform: "uppercase",
      fontSize: { fontSizes: { medium: "23px" } },
      lineHeight: "27.6px"
    },
    description: {
      font: { fontColor: "#FFFFFF", fontFamily: "Roboto-Condensed-Bold", fontWeight: "400" },
      fontSize: {
        fontSizes: {
          medium: "20px"
        }
      },
      lineHeight: "24px"
    },
    banner: {
      fontColor: "#FFFFFF",
      button: {
        font: { fontColor: "#FFF", fontFamily: "Roboto-Condensed-Bold", fontWeight: "400" },
        fontSize: { fontSizes: { medium: "16px" } },
        lineHeight: "19.18px",
        backgroundColor: "transparent",
        hoverbackgroundColor: "#fff",
        border: "2px solid #fff",
        hoverBannerFontColor: "#2C2C2B"
      }
    },
    compact: {
      fontColor: "#404041",
      button: {
        backgroundColor: "#891A1C",
        hoverbackgroundColor: "#CD2B2B",
        fontColor: "#fff",
        hoverFontColor: "#FFFFFF",
        borderRadius: "2px",
        border: "none"
      }
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackCardList.ts
var outbackCardListStyle = {
  selectedColor: "#891A1C",
  backgroundColor: "#F6F2E8",
  borderColor: "#D3C6B3",
  restaurantHoursBackgroundColor: "#E6DDCF",
  iconLocationColor: "#891A1C",
  emptyText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#121211",
    fontWeight: "700"
  },
  restaurantName: {
    fontFamily: "Roboto-Bold, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "600"
  },
  restaurantDistance: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  restaurantAddress: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  restaurantHours: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#121211",
    fontWeight: "400"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackLocationDisplay.ts
var outbackLocationDisplayStyle = {
  container: {
    backgroundColor: "#E6DDCF",
    borderColor: "#D3C6B3",
    color: "#2C2C2B",
    fontFamily: "Roboto, sans-serif"
  },
  boldFontFamily: "Roboto, sans-serif"
};

// src/bbi/outback/outbackComponentsStyle/outbackCurbsideSearchContent.ts
var outbackCurbsideSearchContentStyle = {
  headerTitle: {
    fontFamily: "Roboto-Bold, sans-serif",
    fontSizes: {
      large: "20px"
    },
    fontColor: "#121211",
    fontWeight: "600"
  },
  inputLabel: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  selectLocationButton: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "700"
  },
  useMyLocation: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#891a1c",
    fontWeight: "700"
  },
  toast: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "400"
  },
  headerButtonsColor: "#2C2C2B",
  backgroundColor: "#f6f2e8"
};

// src/bbi/outback/outbackComponentsStyle/outbackPriceTag.ts
var outbackPriceTag = {
  name: {
    fontFamily: "Roboto-Condensed-Regular, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  },
  cost: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackSeePrice.ts
var outbackSeePrice = {
  seePriceText: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      small: "14px"
    }
  },
  backgroundColor: "#E6DDCF"
};

// src/bbi/outback/outbackComponentsStyle/outbackConfirmLocation.ts
var outbackConfirmLocationStyle = {
  backgroundColor: "#f6f2e8",
  header: {
    fontColor: "#121211",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "20px"
    }
  },
  title: {
    fontColor: "#2c2c2b",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: {
      medium: "18px"
    }
  },
  icon: {
    color: "#2c2c2b"
  },
  bottomButton: {
    fontColor: "#891a1c",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: { medium: "14px" }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackChooseHandoffMethod.ts
var outbackChooseHandoffMethodStyle = {
  backgroundColor: "#f6f2e8",
  header: {
    fontColor: "#121211",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "20px"
    }
  },
  button: {
    border: "2px solid #2c2c2b",
    fontColor: "#2c2c2b",
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "400",
    fontSizes: {
      medium: "24px"
    },
    textTransform: "uppercase"
  },
  disableButton: {
    borderColor: "#d4d4d4",
    backgroundColor: "#d4d4d4",
    color: "white"
  },
  selectedButton: {
    backgroundColor: "#891a1c",
    borderColor: "#891a1c",
    color: "white"
  },
  icon: { color: "#2c2c2b" },
  bottomButton: {
    fontColor: "#891a1c",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: { medium: "14px" }
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackAlternativeMenuItem.ts
var outbackAlternativeMenuItemStyle = {
  title: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px",
      large: "18px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  icon: {
    color: "#2C2C2B",
    size: "36px"
  },
  containerBorderColor: "#D3C6B3",
  backgroundColor: "#E6DDCF"
};

// src/bbi/outback/outbackComponentsStyle/outbackSideOptions.ts
var outbackSideOptionsStyle = {
  title: {
    fontColor: "#232222",
    boldColor: "#891A1C",
    fontFamily: "BigBloke, sans-serif",
    fontSizes: {
      medium: "36px",
      small: "32px"
    },
    fontWeight: "400",
    textTransform: "uppercase"
  },
  fontFamily: "Roboto, sans-serif",
  backgroundColor: "#E5C8A2"
};

// src/bbi/outback/outbackComponentsStyle/outbackProductTag.ts
var outbackProductTag = {
  clipPathRight: {
    content: "polygon(0px 100%, 0% 0%, 100% 0px, calc(100% - 12px) 50%, 100% 100%);",
    before: "polygon(100% 0px, 100% 100%, 0px 0px);"
  },
  showClipPathLeft: true,
  left: "-9px",
  top: "4px",
  borderTopLeft: "0px",
  smallTag: {
    fontSize: "12px",
    lineHeight: "",
    left: "-9px",
    content: {
      padding: "4px 18px 4px 6px"
    }
  },
  largeTag: {
    fontSize: "16px",
    lineHeight: "",
    left: "-13px",
    content: {
      padding: "4px 20px 4px 8px"
    }
  },
  fontWeight: 400,
  textTransform: "uppercase",
  height: {
    large: "32px",
    small: "32px"
  },
  icon: {
    widthDesktop: "",
    heightDesktop: "",
    widthMobile: "",
    heightMobile: ""
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackCardSlider.ts
var outbackCardSliderStyle = {
  arrow: {
    color: "#ABABAB",
    hover: "#D63D3D"
  }
};

// src/bbi/outback/outbackComponentsStyle/outbackRelatedProducts.ts
var outbackRelatedProductsStyle = {
  title: {
    fontColor: "#FFFFFF",
    fontFamily: "Bigbloke, sans-serif",
    fontSizes: {
      medium: "36px",
      small: "32px"
    },
    fontWeight: "400",
    textTransform: "uppercase"
  },
  background: "url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Background/20240715-outback-red-background-with-texture.jpg) 0 0 repeat"
};

// src/bbi/outback/outbackComponentsStyle/outbackOptionCard.ts
var SITECORE_IMAGES_URL2 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback`;
var outbackOptionCardStyle = {
  title: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      small: "14px",
      medium: "16px",
      large: "18px"
    },
    fontColor: "#121211",
    fontWeight: "400"
  },
  backgroundColor: "#E6DDCF",
  optionImagePlaceHolder: `${SITECORE_IMAGES_URL2}/outback-red-logo.svg`,
  defaultImageBackground: "#D3C6B3"
};

// src/bbi/outback/outbackUITheme.ts
var OutbackUITheme = {
  accordion: outbackAccordionStyle,
  buttonCMS: outbackButtonCmsStyle,
  carousel: outbackCarouselStyle,
  container: outbackContainerStyle,
  footer: outbackFooterStyle,
  navBar: outbackNavBarStyle,
  topMessage: outbackTopMessage,
  header: outbackHeaderStyle,
  pod: outbackPodStyle,
  forms: outbackDRFormStyle,
  hero: outbackHero,
  animatedCard: outbackAnimatedCard,
  menuItem: outbackMenuItem,
  reservations: outbackReservations,
  deliveryContent: outbackDeliveryContentStyle,
  drawerMessage: outbackDrawerMessageStyle,
  video: outbackVideo,
  banner: outbackBannerStyle,
  productMenu: outbackProductMenu,
  cardList: outbackCardListStyle,
  dineRewardsForm: outbackDineRewardsForm,
  priceTag: outbackPriceTag,
  seePrice: outbackSeePrice,
  productTag: outbackProductTag,
  animatedCardSecondary: {
    title: {
      fontColor: "#404041",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: "1.5rem"
      }
    },
    text: {
      fontColor: "#666666",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: "1rem"
      }
    },
    button: {
      fontColor: "#ffffff",
      fontFamily: "TradeGothic, sans-serif",
      fontWeight: "600",
      fontSizes: {
        medium: "0.875rem"
      },
      backgroundColor: "#404041"
    }
  },
  catalogItem: outbackCatalogItemStyle,
  catalogItemSecondary: outbackCatalogItemSecondaryStyle,
  textButtonBanner: {
    title: {
      fontColor: "#ffffff",
      fontFamily: "TradeGothic, sans-serif",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem",
        large: "1.5rem"
      },
      fontWeight: "600"
    },
    button: {
      fontColor: "#404041",
      fontFamily: "TradeGothic, sans-serif",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem",
        large: "1.5rem"
      },
      fontWeight: "600",
      backgroundColor: "#ffffff",
      hover: {
        backgroundColor: "",
        opacity: "0.8"
      }
    }
  },
  inputCms: outbackInputCmsStyle,
  rewardsModal: outbackRewardsModal,
  cardSecondary: outbackCardSecondaryStyles,
  grid: {
    maxWidth: "none",
    margin: "auto"
  },
  drawer: outbackDrawerStyle,
  jumbotron: outbackJumbotronStyles,
  catalog: outbackCatalogStyle,
  line: outbackLine,
  newsletterForm: outbackNewsletterForm,
  tabNavigationItem: outbackTabNavigationItem,
  questionsAndAnswers: outbackQuestionsAndAnswers,
  questionsAndAnswersItem: outbackQuestionsAndAnswersItem,
  lateralBar: outbackLateralBar,
  legalPages: outbackLegalPagesStyle,
  videoTextButton: outbackVideoTextButton,
  seating: outbackSeating,
  dropdownCms: outbackCmsDropdownStyle,
  sectionBanner: outbackSectionBanner,
  bodyBanner: outbackBodyBannerStyle,
  animatedHero: outbackAnimatedHeroStyle,
  menuNavigation: outbackMenuNavigation,
  categoryItem: outbackCategoryItemStyle,
  locationDisplay: outbackLocationDisplayStyle,
  curbsideSearchContent: outbackCurbsideSearchContentStyle,
  confirmLocation: outbackConfirmLocationStyle,
  chooseHandoffMethod: outbackChooseHandoffMethodStyle,
  alternativeMenuItem: outbackAlternativeMenuItemStyle,
  optionCard: outbackOptionCardStyle,
  cardSlider: outbackCardSliderStyle,
  relatedProducts: outbackRelatedProductsStyle,
  sideOptions: outbackSideOptionsStyle
};

// src/order_ui/Brand.ts
var Brand = /* @__PURE__ */ ((Brand2) => {
  Brand2["Outback"] = "outback";
  Brand2["Carrabbas"] = "carrabbas";
  Brand2["Bonefish"] = "bonefishgrill";
  return Brand2;
})(Brand || {});

// src/bbi/outback/outbackOrderUi.tsx
var import_styled_components3 = require("styled-components");
var import_IconUnion = require("@maverick/icons/dist/IconUnion");
var import_IconCurbside2 = require("@maverick/icons/dist/IconCurbside");
var import_IconEdit = require("@maverick/icons/dist/IconEdit");
var import_IconDelivery2 = require("@maverick/icons/dist/IconDelivery");
var import_IconPickup = require("@maverick/icons/dist/IconPickup");
var import_IconLocation2 = require("@maverick/icons/dist/IconLocation");
var import_IconLocationOutline2 = require("@maverick/icons/dist/IconLocationOutline");
var import_IconBeef = require("@maverick/icons/dist/IconBeef");
var import_IconBeefStroke = require("@maverick/icons/dist/IconBeefStroke");
var import_IconClipboard = require("@maverick/icons/dist/IconClipboard");
var import_IconDollarSign = require("@maverick/icons/dist/IconDollarSign");
var import_SeloIcon = require("@maverick/icons/dist/SeloIcon");
var import_IconUser = require("@maverick/icons/dist/IconUser");
var import_IconUserFilled = require("@maverick/icons/dist/IconUserFilled");
var import_IconBag = require("@maverick/icons/dist/IconBag");
var import_IconBagStampOutback = require("@maverick/icons/dist/IconBagStampOutback");
var import_IconMountains = require("@maverick/icons/dist/IconMountains");
var import_IconCall = require("@maverick/icons/dist/IconCall");
var import_WarningMark = require("@maverick/icons/dist/WarningMark");
var import_IconMapDeliveryOutback = require("@maverick/icons/dist/IconMapDeliveryOutback");
var import_IconLogo = require("@maverick/icons/dist/IconLogo");
var import_RightCloudIcon = require("@maverick/icons/dist/RightCloudIcon");
var import_LeftCloudIcon = require("@maverick/icons/dist/LeftCloudIcon");
var import_IconThermometer = require("@maverick/icons/dist/IconThermometer");
var import_OutbackSign2 = require("@maverick/icons/dist/OutbackSign");
var import_IconBoomerang = require("@maverick/icons/dist/IconBoomerang");
var import_IconAppOnlyCategory = require("@maverick/icons/dist/IconAppOnlyCategory");
var import_IconMissingPoints = require("@maverick/icons/dist/IconMissingPoints");
var import_UserLoggedOutback = require("@maverick/icons/dist/UserLoggedOutback");
var import_FavoriteOutback = require("@maverick/icons/dist/FavoriteOutback");
var import_jsx_runtime = require("react/jsx-runtime");
var BRAND = "outback" /* Outback */;
var SITECORE_IMAGES_URL3 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/${BRAND}/Order`;
var OutbackOrderUi = {
  app: {
    brand: "outback" /* Outback */,
    name: "Outback Steakhouse",
    shortName: "Outback",
    providerAuth0: "outbackAuth0"
  },
  global: import_styled_components3.css`
		body {
			font-family: 'Roboto', sans-serif;
			color: #2c2c2b;
		}
	`,
  mapPopup: import_styled_components3.css`
		font-family: 'Roboto', sans-serif;
	`,
  colors: {
    primary: {
      lightContrast: "#D63D3D",
      light: "#CD2B2B",
      medium: "#891A1C",
      dark: "#60181B",
      variant: "#891A1C",
      lightVariant: "#CD2B2B"
    },
    secondary: {
      light: "#F6F2E8",
      medium: "#E6DDCF",
      dark: "#D3C6B3",
      variant: "#891A1C",
      darkVariant: "#891A1C"
    },
    tertiary: {
      light: "#F6F6F6",
      medium: "#757575",
      dark: "#2C2C2B"
    },
    complementary: {
      success: "#2D8633",
      error: "#B00020",
      warning: "#FFB61F",
      information: "#1C9BDB",
      hyperlink: "#3078CD",
      disabled: "#ABABAB"
    },
    neutral: {
      light: "#FFFFFF",
      medium: "#EAEAEA",
      dark: "#000000"
    }
  },
  typography: {
    headline: {
      h1: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 48px;
				line-height: 52px;
			`,
      h2: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 40px;
				line-height: 44px;
			`,
      h3: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 32px;
				line-height: 36px;
			`,
      h4: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 24px;
				line-height: 28px;
			`,
      h4Variant: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 24px;
				line-height: 28px;
			`,
      h5: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 20px;
				line-height: 24px;
			`,
      h6: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 16px;
				line-height: 20px;
			`
    },
    title: {
      title: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				font-size: 32px;
				line-height: 36px;
			`,
      subtitle: import_styled_components3.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`,
      dRBanner: import_styled_components3.css`
				font-family: 'Veneer', sans-serif;
				width: 65%;
			`
    },
    body: {
      bodyLarge: import_styled_components3.css`
				font-size: 18px;
				line-height: 24px;
			`,
      body: import_styled_components3.css`
				font-size: 16px;
				line-height: 20px;
			`,
      bodySmall: import_styled_components3.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    inputs: {
      buttonLarge: import_styled_components3.css`
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
			`,
      button: import_styled_components3.css`
				font-weight: 700;
				font-size: 16px;
				line-height: 20px;
			`,
      buttonSmall: import_styled_components3.css`
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;
			`,
      inputText: import_styled_components3.css`
				font-size: 16px;
				line-height: 20px;
			`,
      buttonItalic: import_styled_components3.css`
				font-weight: 550;
				font-style: italic;
				font-size: 14px;
				line-height: 18px;
			`,
      inputLabel: import_styled_components3.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    caption: {
      caption: import_styled_components3.css`
				font-size: 12px;
				line-height: 16px;
			`,
      captionMedium: import_styled_components3.css`
				font-weight: 550;
				font-size: 12px;
				line-height: 16px;
			`
    },
    overline: {
      overline: import_styled_components3.css`
				font-size: 10px;
				line-height: 14px;
			`
    },
    tagFeature: import_styled_components3.css`
			font-family: 'IntroRust', sans-serif;
			font-size: 12px;
			padding: 4px 6px;
		`,
    tagProduct: import_styled_components3.css`
			font-family: 'BigBloke';
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			text-transform: uppercase;
		`
  },
  fontImports: () => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(import_jsx_runtime.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)("link", { rel: "preconnect", href: "https://fonts.googleapis.com" }),
    /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
      "link",
      {
        href: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap",
        rel: "stylesheet"
      }
    )
  ] }),
  fontFaces: import_styled_components3.css`
		@font-face {
			font-family: 'Veneer';
			src: url('/order/fonts/veneer/Veneer.woff2') format('woff2'),
				url('/order/fonts/veneer/Veneer.woff') format('woff');
		}

		@font-face {
			font-family: 'TradeGothic';
			src: url('/order/fonts/TradeGothic/Trade-Gothic-LT-Condensed-No-18.woff') format('woff');
			font-weight: normal;
			font-style: normal;
		}

		@font-face {
			font-family: 'TradeGothic';
			src: url('/order/fonts/TradeGothic/Trade-Gothic-LT-Bold-Condensed-No-20.woff') format('woff');
			font-weight: bold;
			font-style: normal;
		}

		@font-face {
			font-family: 'IntroRust';
			src: url('/order/fonts/IntroRust/IntroRust-Base.otf') format('opentype');
		}

		@font-face {
			font-family: 'BigBloke';
			src: url('/order/fonts/BigBloke/BigBloke2Full.woff') format('woff');
		}
	`,
  texts: {
    SelectHandoffMethodTitle: "Welcome, mate!",
    CheckoutPoints: "POINTS",
    DineRewardsCardTitleGuest: "Hey Mate!",
    DineRewardsCardTitleLogged: "Hey Mate!",
    DineRewardsAuthenticatedBannerText: "It's our pleasure to serve you! <br/>  Check back in 24 hours to see points earned from this order!",
    BlankOrderSummaryTitle: "Crikey!",
    BlankOrderSummarySubtitle: `There's nothing here, mate.`,
    DineRewardsBannerTitle: "Hey mate!",
    NewFavoriteNamePlaceholder: "e.g. Down Under Dinner",
    AddOnsDescription: "What a luck, mate! All orders come with our famous honey wheat bread and butter.",
    DineRewardsSalutation: "Hello",
    EmptyRecentOrdersBoldText: `It seems you don't have any orders yet.`,
    EmptyRecentOrdersNormalText: "Get your steak now, mate!",
    MyFavoritesPageTitle: "My faves",
    EmptyMyFavoritesBoldText: `You don't have any Fave yet.`,
    EmptyMyFavoritesNormalText: "Get your steak and fave your order!",
    ModalTitle: "Heads up mate!",
    TitleMessageErrorPage: "Sorry mate, an unexpected error has occurred.",
    OrderSummarySuggestion: "Complete your meal",
    AddOnsSubtitle: "Add Ons",
    FreeSubtitle: "Free",
    EmptytRewardsNormalText: "Get your steak on to start earning!",
    EmptytActivityNormalText: "Get your steak now!",
    DRBannerTitle: "Get A free Bloomin\u2019 Onion and rewards, mate!",
    SignUpRewardText: "Earn points & redeem rewards",
    SignUpPhoneText: "Seamless online ordering",
    SignUpReorderText: "Quickly reorder recents & favorites",
    NoRestaurantFoundDelivery: "Sorry Mate delivery is not available at this location, but you can still place an order for Curbside pickup."
  },
  icons: {
    curbside: import_IconCurbside2.IconCurbside,
    delivery: import_IconDelivery2.IconDelivery,
    pickup: import_IconPickup.IconPickup,
    iconDollarSign: import_IconDollarSign.IconDollarSign,
    iconCall: import_IconCall.IconCall,
    iconEdit: import_IconEdit.IconEdit,
    iconUnion: import_IconUnion.IconUnion,
    iconLocation: import_IconLocation2.IconLocation,
    iconLocationColor: "#83121E",
    iconLocationOutline: import_IconLocationOutline2.IconLocationOutline,
    iconLocationOutlineColor: "#2C2C2B",
    iconMapDelivery: import_IconMapDeliveryOutback.IconMapDeliveryOutback,
    iconRecentOrders: import_IconBeef.IconBeef,
    iconMyRewards: import_SeloIcon.SeloIcon,
    iconContactInfo: import_IconUserFilled.IconUserFilled,
    iconFooter: import_IconMountains.IconMountains,
    iconBasketStamp: import_IconBagStampOutback.IconBagStampOutback,
    iconBasketHeader: import_IconBag.IconBag,
    iconUserHeader: import_IconUser.IconUser,
    iconUserLoggedHeader: import_IconUserFilled.IconUserFilled,
    iconUserLoggedAnimation: import_UserLoggedOutback.UserLoggedOutback,
    iconHeaderColor: "#E5C8A2",
    iconModal: import_WarningMark.WarningMark,
    iconEat: import_IconBeefStroke.IconBeefStroke,
    iconEarn: import_IconClipboard.IconClipboard,
    iconLogo: import_IconLogo.IconLogo,
    iconProfilePageLeft: import_LeftCloudIcon.LeftCloudIcon,
    iconProfilePageRight: import_RightCloudIcon.RightCloudIcon,
    iconTemperature: import_IconThermometer.IconThermometer,
    iconNoRestaurantsFound: import_OutbackSign2.OutbackSign,
    iconNoRestaurantsFoundSize: import_styled_components3.css`
			width: 112px;
			height: 202px;
		`,
    iconSelectedCard: import_IconBoomerang.IconBoomerang,
    iconAppOnlyCategory: import_IconAppOnlyCategory.IconAppOnlyCategory,
    iconMissingPoints: import_IconMissingPoints.IconMissingPoints,
    iconFavoriteAnimation: import_FavoriteOutback.FavoriteOutback
  },
  images: {
    checkoutBackground: `${SITECORE_IMAGES_URL3}/${BRAND}-checkout-bg.webp`,
    orderReceivedBackground: `${SITECORE_IMAGES_URL3}/${BRAND}-order-received-bg.webp`,
    blankOrderSummaryBackground: `${SITECORE_IMAGES_URL3}/${BRAND}-empty-basket-bg.webp`,
    dineRewardsBanner: `${SITECORE_IMAGES_URL3}/${BRAND}-dr-card-order-summary.webp`,
    bgRewardsBanner: `${SITECORE_IMAGES_URL3}/${BRAND}-dr-banner-bg.webp`,
    recentOrdersRightSide: `${SITECORE_IMAGES_URL3}/${BRAND}-recent-orders-bg.webp`,
    myFavoritesRightSide: `${SITECORE_IMAGES_URL3}/${BRAND}-my-faves-bg.webp`,
    myRewardsRightSide: `${SITECORE_IMAGES_URL3}/${BRAND}-my-rewards-bg.webp`,
    myActivityRightSide: `${SITECORE_IMAGES_URL3}/${BRAND}-my-activity-bg.webp`,
    productPlaceholder: `${SITECORE_IMAGES_URL3}/${BRAND}-product-placeholder.webp`,
    emptyUserPageBackground: import_styled_components3.css`
			background: url(${`${SITECORE_IMAGES_URL3}/${BRAND}-empty-user-pages-bg.webp`}) center;
			background-size: auto 195%;
		`,
    DRBannerBackgroundImage: `${SITECORE_IMAGES_URL3}/${BRAND}-order-summary-dr-banner.webp`
  },
  links: {
    nutritionAdditionalInfo: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/files/obs-full-nutrition-information.pdf",
    privacyPolicy: "https://www.outback.com/privacy-rights/privacy-policy",
    logoMeta: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Auth0/outback-logo.svg",
    navBarBackground: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/texture-slate.png",
    appDeepLink: "outback://rewards",
    appStoreAndroid: "https://play.google.com/store/apps/details?id=com.outback.tampa",
    appStoreIos: "https://apps.apple.com/us/app/outback/id1035361376"
  },
  booleans: {
    productImageTextureDetail: true
  },
  tipOptions: {
    tipOptions: [
      { label: "15%", value: 0.15 },
      { label: "18%", value: 0.18 },
      { label: "20%", value: 0.2 }
    ]
  },
  fonts: {
    src: 'url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2")',
    family: "Roboto"
  },
  specificStyle: {
    orderTimeLabelText: import_styled_components3.css``,
    rewardsCounter: import_styled_components3.css``,
    messageInfoMessage: import_styled_components3.css``
  },
  productTag: {
    clipPathRight: "polygon(0 100%, 0% 0%, 100% 0, calc(100% - 12px) 50%, 100% 100%)",
    showClipPathLeft: true,
    left: "-9px",
    top: "4px",
    borderTopLeft: "0px",
    smallTag: {
      left: "-9px"
    },
    largeTag: {
      left: "-13px"
    },
    fontWeight: 400
  }
};

// src/bbi/outback/outbackTheme.ts
var OutbackTheme = __spreadProps(__spreadValues({}, OutbackSharedTheme), {
  components: __spreadValues({}, OutbackUITheme),
  cmsComponents: __spreadValues({}, OutbackCmsTheme),
  orderUi: OutbackOrderUi
});

// src/bbi/outback/outbackButtons.ts
var import_styled_components4 = __toESM(require("styled-components"));
var OutbackBaseButton = import_styled_components4.default.a`
	width: auto;
	text-transform: uppercase;
	text-align: center;
	border: 1px solid;
	font-family: 'Roboto-Condensed-Bold';
	font-size: 24px;
	font-weight: 400;
	padding: 16px;

	@media (max-width: 768px) {
		width: 100%;
		font-size: 20px;
	}
`;
var OutbackTransparentButton = (0, import_styled_components4.default)(OutbackBaseButton)`
	color: #2c2c2b;
	border-color: #2c2c2b;
`;
var OutbackRedButton = (0, import_styled_components4.default)(OutbackBaseButton)`
	color: #ffffff;
	border-color: #891a1c;
	background-color: #891a1c;

	&:hover {
		border-color: #cd2b2b;
		background-color: #cd2b2b;
	}
`;

// src/bbi/carrabbas/carrabbasSharedTheme.ts
var import_styled_components5 = require("styled-components");
var CarrabbasSharedTheme = {
  colors: {
    primary: {
      light: "#437F78",
      lightContrast: "#184722",
      medium: "#9E1D21",
      dark: "#9E1D21"
    },
    secondary: {
      light: "#EDEDED",
      medium: "#e6ddcf",
      dark: "#d3c6b3"
    },
    terciary: {
      light: "#f6f6f6",
      medium: "#404041",
      dark: "#F5F3EB"
    },
    complementary: {
      success: "#2d8633",
      error: "#b00020",
      errorText: "#b00020",
      warning: "#ffB61f",
      information: "#1c9Bdb",
      hyperlink: "#3078cd",
      errorMessage: {
        bannerErrorMessage: "#b00020",
        compactErrorMessage: "#b00020"
      }
    },
    neutral: {
      black: "#404041",
      white: "#ffffff",
      gray: "#eaeaea",
      disabled: "#ABABAA"
    }
  },
  typography: {
    headline: {
      h1: import_styled_components5.css`
				font-family: 'Veneer';
				font-size: 48px;
				line-height: 52px;
			`,
      h2: import_styled_components5.css`
				font-family: 'Veneer';
				font-size: 40px;
				line-height: 44px;
			`,
      h3: import_styled_components5.css`
				font-family: 'Veneer';
				font-size: 32px;
				line-height: 36px;
			`,
      h4: import_styled_components5.css`
				font-family: 'Veneer';
				font-size: 24px;
				line-height: 28px;
			`,
      h5: import_styled_components5.css`
				font-family: 'Veneer';
				font-size: 20px;
				line-height: 24px;
			`,
      h6: import_styled_components5.css`
				font-family: 'Veneer';
				font-size: 16px;
				line-height: 20px;
			`
    },
    subtitle: {
      subtitle: import_styled_components5.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`
    },
    body: {
      bodySmall: import_styled_components5.css`
				font-size: 14px;
				line-height: 20px;
			`,
      body: import_styled_components5.css`
				font-size: 16px;
				line-height: 20px;
			`,
      bodyLarge: import_styled_components5.css`
				font-size: 18px;
				line-height: 28px;
			`,
      bodySmallBold: import_styled_components5.css`
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
			`
    },
    inputs: {
      buttonSmall: import_styled_components5.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;
			`,
      button: import_styled_components5.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
			`,
      buttonLarge: import_styled_components5.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
			`,
      italicButton: import_styled_components5.css`
				font-family: Roboto;
				font-style: italic;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
			`,
      textInputs: import_styled_components5.css`
				font-size: 16px;
				line-height: 20px;
			`,
      textInputsSmall: import_styled_components5.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    caption: {
      caption: import_styled_components5.css`
				font-size: 12px;
				line-height: 16px;
			`,
      captionMedium: import_styled_components5.css`
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			`,
      captionLarge: import_styled_components5.css`
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
			`
    },
    overline: {
      overline: import_styled_components5.css`
				font-size: 10px;
				line-height: 14px;
				letter-spacing: 1.5px;
				text-transform: uppercase;
			`
    }
  },
  background: {
    dark: {
      image: "/image/outback/dark-background-image.png",
      color: "#262524"
    },
    light: {
      image: "",
      color: ""
    }
  }
};

// src/bbi/carrabbas/carrabbasCmsTheme.ts
var import_IconCarrabbasCurbside = require("@maverick/icons/dist/IconCarrabbasCurbside");
var import_ExclamationIcon = require("@maverick/icons/dist/ExclamationIcon");
var import_IconlocationCarrabbas = require("@maverick/icons/dist/IconlocationCarrabbas");
var import_IconLocationOutlineCarrabbas = require("@maverick/icons/dist/IconLocationOutlineCarrabbas");
var import_IconPin = require("@maverick/icons/dist/IconPin");
var import_styled_components6 = require("styled-components");
var import_IconCarrabbasDelivery = require("@maverick/icons/dist/IconCarrabbasDelivery");
var CarrabbasCmsTheme = {
  articlePage: {
    fontFamily: "",
    lineHeight: "",
    a: {
      color: "blue"
    }
  },
  icons: {
    curbside: import_IconCarrabbasCurbside.IconCarrabbasCurbside,
    delivery: import_IconCarrabbasDelivery.IconCarrabbasDelivery,
    iconLocation: import_IconlocationCarrabbas.IconlocationCarrabbas,
    iconLocationOutline: import_IconLocationOutlineCarrabbas.IconLocationOutlineCarrabbas,
    iconSearchLocation: import_IconPin.IconPin,
    iconSearchLocationSize: import_styled_components6.css`
			width: 42px;
			height: 54px;
		`,
    iconWarning: import_ExclamationIcon.ExclamationIcon
  },
  shortName: "Carrabba's"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasAcccordion.ts
var carrabbasAccordion = {
  variant: {
    primary: {
      background: "#ffffff",
      fontColor: "#666666",
      titleColor: "#666666",
      subtitleColor: "#666666"
    },
    secondary: {
      background: "#DEDACE",
      fontColor: "#666666",
      titleColor: "#666666",
      subtitleColor: "#666666"
    }
  },
  title: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#ffff",
    fontWeight: "400",
    fontSizes: {
      small: "1.5rem",
      medium: "2rem"
    }
  },
  subtitle: {
    fontFamily: "Gibson-Regular",
    fontColor: "#ffff",
    fontWeight: "400",
    fontSizes: {
      small: "0.9",
      medium: "1.2rem"
    }
  },
  accordionItem: {
    title: {
      fontFamily: "Gibson-SemiBold",
      fontColor: "#666666",
      fontWeight: "600",
      fontSizes: {
        small: "2rem",
        medium: "2rem"
      },
      paddingRight: ""
    },
    text: {
      fontFamily: "Gibson-Regular",
      fontColor: "#666666",
      fontWeight: "400",
      fontSizes: {
        small: "1.25rem",
        medium: "1.25rem"
      }
    },
    chevronColor: "",
    textTransform: "uppercase",
    borderColor: ""
  },
  titleAlign: "center"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasButtonCms.ts
var carrabbasButtonCmsStyle = {
  primary: {
    fontFamily: "Gibson-SemiBold",
    lineHeight: "1.75rem",
    backgroundColor: "#9e1d21",
    hover: {
      fontColor: "",
      backgroundColor: "#60181b",
      opacity: 1
    },
    fontWeight: "600",
    fontSizes: {
      medium: "1.5rem",
      large: "1rem",
      small: "1.6rem"
    },
    border: {
      color: "transparent",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#fff",
    padding: "10px 0px",
    paddingMobile: "",
    letterSpace: ""
  },
  secondary: {
    fontFamily: "Gibson-SemiBold",
    lineHeight: "1rem",
    fontSizes: {
      medium: "1.6rem",
      large: "1rem",
      small: "1.6rem"
    },
    hover: {
      fontColor: "",
      backgroundColor: "",
      opacity: 1
    },
    backgroundColor: "#fff",
    fontWeight: "600",
    border: {
      color: "#000",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#000",
    padding: "0",
    paddingMobile: "",
    letterSpace: ""
  },
  terciary: {
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      large: "1.5rem",
      medium: "0.875rem",
      small: "1.2rem",
      smallest: "1.2rem"
    },
    hover: {
      fontColor: "",
      backgroundColor: "",
      opacity: 0.8
    },
    backgroundColor: "#404041",
    fontWeight: "600",
    lineHeight: "",
    border: {
      color: "#fff",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#fff",
    padding: "26px 31px",
    paddingMobile: "",
    letterSpace: ""
  },
  bodyBannerVariant: {
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      medium: "1.5rem",
      small: "0.875rem"
    },
    hover: {
      fontColor: "",
      backgroundColor: "",
      opacity: 0.8
    },
    backgroundColor: "#404041",
    fontWeight: "600",
    lineHeight: "1rem",
    border: {
      color: "#fff",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#fff",
    padding: "16px",
    paddingMobile: "",
    letterSpace: ""
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCardSecondaryStyle.ts
var carrabbasCardSecondaryStyles = {
  title: {
    primary: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        medium: "1.5rem"
      },
      fontWeight: "500"
    },
    white: {
      fontColor: "#232222",
      fontFamily: "Gibson-Regular",
      fontSizes: {
        medium: "1.5rem"
      },
      fontWeight: "400"
    }
  },
  notes: {
    fontColor: "#404041",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1.4rem"
    },
    fontWeight: ""
  },
  text: {
    primary: {
      fontColor: "#404041",
      fontFamily: "Gibson-Regular",
      fontSizes: {
        medium: "1.2rem"
      },
      fontWeight: ""
    },
    white: {
      fontColor: "#232222",
      fontFamily: "Gibson-Regular",
      fontSizes: {
        medium: "1.2rem"
      },
      fontWeight: "400"
    }
  },
  button: {
    primary: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        medium: "1.2rem"
      },
      fontWeight: "bold",
      hoverOpacity: "0.6",
      borderRadius: "",
      borderWidth: "2px",
      hoverColor: "",
      hoverFontColor: "",
      padding: "10px 16px",
      backgroundColor: "transparent"
    },
    white: {
      fontColor: "#232222",
      fontFamily: "Gibson-Regular",
      fontSizes: {
        medium: "1.2rem"
      },
      fontWeight: "400",
      hoverOpacity: "0.6",
      borderRadius: "",
      borderWidth: "2px",
      hoverColor: "",
      hoverFontColor: "",
      padding: "10px 16px",
      backgroundColor: "transparent"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCatalog.ts
var carrabbasCatalogStyle = {
  title: {
    fontColor: "#404041",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "2rem"
    },
    fontWeight: "400"
  },
  button: {
    fontColor: "#FFFFFF",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem"
    },
    fontWeight: "400",
    backgroundColor: "#404041",
    hover: {
      backgroundColor: "#404041",
      fontColor: "#FFFFFF",
      opacity: 0.8
    },
    border: {
      width: "",
      radius: "2px",
      color: "",
      style: ""
    },
    padding: "12px 16px",
    letterSpace: "1px"
  },
  backgroundColor: "#ededed",
  maxWidthView: "1114px",
  verticalPadding: "20px 0px",
  verticalPaddingMobile: "20px 0px",
  gap: "32px"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCatalogItem.ts
var carrabbasCatalogItemStyle = {
  borderRadius: "0px",
  title: {
    font: {
      fontFamily: "Gibson-SemiBold",
      fontColor: "#931c20",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "2.25rem",
        small: "2rem",
        smallest: "1.75rem"
      }
    }
  },
  subtitle: {
    font: {
      fontFamily: "Gibson-SemiBold",
      fontColor: "#404041",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "1.25rem",
        small: "1rem",
        smallest: "0.75rem"
      }
    }
  },
  text: {
    font: {
      fontFamily: "Gibson-Regular",
      fontColor: "#404041",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    }
  },
  note: {
    font: {
      fontFamily: "Gibson-Regular",
      fontColor: "#931c20",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    }
  },
  button: {
    backgroundColor: "#404041",
    hover: {
      backgroundColor: "#404041",
      fontColor: "#fff",
      opacity: 0.8
    },
    border: {
      width: "",
      radius: "unset",
      color: "transparent",
      style: ""
    },
    padding: "12px 16px",
    letterSpace: "1px",
    fontColor: "#fff",
    fontFamily: "Gibson-SemiBold",
    fontWeight: "bold",
    fontSizes: {
      smallest: "1.25rem",
      small: "1.25rem",
      medium: "1.5rem",
      large: "1.25rem",
      largest: "1.25rem"
    },
    marginRight: ""
  },
  backgroundColor: {
    default: "#EDEDED",
    verticalView: "#ffffff"
  },
  verticalView: {
    image: {
      padding: "0"
    },
    textAlign: "center"
  },
  margin: ""
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCatalogItemSecondary.ts
var carrabbasCatalogItemSecondaryStyle = {
  title: {
    font: {
      fontFamily: "Gibson-Regular",
      fontColor: "#666666",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "0.75rem",
        smallest: "0.5rem"
      }
    },
    textTransform: "uppercase",
    marginBottom: "12px"
  },
  subtitle: {
    font: {
      fontFamily: "Queens-W",
      fontColor: "#404041",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "3.5rem",
        small: "3rem",
        smallest: "2rem"
      }
    },
    lineHeightMobile: "44px",
    marginBottom: "27px"
  },
  text: {
    font: {
      fontFamily: "Gibson-Regular",
      fontColor: "#666666",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    },
    marginBottom: "24px"
  },
  button: {
    font: {
      fontFamily: "Gibson-Regular",
      fontColor: "#666666",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "1.25rem",
        small: "1rem",
        smallest: "1rem"
      }
    },
    marginTop: "44px",
    marginTopMobile: "16px",
    padding: "12px 0px",
    marginInternal: "",
    iconColor: "#666666"
  },
  backgroundColor: "#fff",
  contentPaddingMobile: "0px 36px",
  contentPadding: "0px 76px",
  containerFlex: "",
  alignMain: "center",
  verticalView: {
    fontColor: "",
    title: {
      fontFamily: "Gibson-Regular"
    },
    subtitle: {
      fontFamily: "Gibson-Regular"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasHeader.ts
var carrabbasHeaderStyle = {
  image: {
    width: "130px",
    height: "auto"
  },
  title: {
    fontSizes: {
      small: "1rem",
      medium: "1rem"
    }
  },
  subTitle: {
    fontSizes: {
      small: "14px",
      medium: "18px"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasHero.ts
var carrabbasHero = {
  button: {
    primary: {
      borderRadius: "0",
      fontColor: "#404041",
      fontWeight: "600",
      fontFamily: "Gibson-SemiBold",
      letterSpacing: "0",
      fontSizes: {
        medium: "1.5rem"
      },
      backgroundColor: "#ffffff",
      hover: {
        opacity: 0.8,
        backgroundColor: "#FFFFFF",
        letterSpacing: "0",
        font: {
          fontColor: "#000000",
          fontFamily: "TradeGothic, sans-serif",
          fontWeight: "400"
        }
      },
      borderWidth: "2px",
      lineHeight: "50px",
      padding: "0 40px"
    },
    secondary: {
      borderRadius: "0",
      fontColor: "#404041",
      fontWeight: "600",
      fontFamily: "Gibson-SemiBold",
      letterSpacing: "0",
      fontSizes: {
        medium: "1.5rem"
      },
      backgroundColor: "#ffffff",
      hover: {
        opacity: 0.8,
        backgroundColor: "#FFFFFF",
        letterSpacing: "0",
        font: {
          fontColor: "#000000",
          fontFamily: "TradeGothic, sans-serif",
          fontWeight: "400"
        }
      },
      borderWidth: "2px",
      lineHeight: "50px",
      padding: "0 40px"
    }
  },
  overTitle: {
    fontColor: "#FFFFFF",
    fontFamily: "Gibson-SemiBold",
    fontWeight: "600",
    textShadow: "0",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    },
    letterSpacing: "1px"
  },
  title: {
    fontColor: "#FFFFFF",
    fontFamily: "Queens-W",
    fontWeight: "500",
    textShadow: "0",
    fontSizes: {
      medium: "4.25rem",
      small: "3rem",
      smallest: "3rem"
    },
    textTransform: "none",
    lineHeight: {
      medium: "5.4rem",
      small: "3.25rem",
      smallest: "3.25rem"
    }
  },
  text: {
    fontColor: "#FFFFFF",
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    }
  },
  container: {
    background: "linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.2) 100%);"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasInputCms.ts
var carrabbasInputCmsStyle = {
  primary: {
    input: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600",
      fontSizes: {
        medium: "1rem",
        large: "1rem",
        largest: "1rem"
      },
      lineHeights: {
        medium: "4.08rem",
        large: "4.36rem",
        largest: "4.63rem"
      },
      background: "#fff",
      placeholderFontColor: "#6d6d6d"
    },
    label: {
      fontColor: "transparent",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "700",
      fontSizes: {
        medium: "0.875rem"
      },
      lineHeights: {
        medium: "1.2rem"
      },
      initialLabelColor: "transparent",
      finalLabelColor: "#232222"
    },
    error: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {
        small: "0.75rem",
        medium: "0.8rem",
        large: "0.85rem",
        largest: "0.9rem"
      },
      lineHeights: {
        small: "0.9rem",
        medium: "0.95rem",
        large: "1rem",
        largest: "1.07rem"
      }
    },
    borderColor: "#404041"
  },
  secondary: {
    input: {
      fontColor: "#a87139",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: "1.21rem"
      },
      lineHeights: {
        medium: "1.2"
      },
      background: "",
      placeholderFontColor: "#404041"
    },
    label: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: ""
  },
  terciary: {
    input: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      background: "",
      placeholderFontColor: ""
    },
    label: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#e8dcc2"
  },
  modal: {
    input: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      background: "",
      placeholderFontColor: "#666666"
    },
    label: {
      fontColor: "transparent",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: "",
      display: "none"
    },
    error: {
      fontColor: "#333",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#666666"
  },
  compact: {
    input: {
      fontColor: "#232222",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: { medium: "14px" },
      lineHeights: { medium: "14px" },
      background: "",
      placeholderFontColor: ""
    },
    label: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600",
      fontSizes: { medium: "12px" },
      lineHeights: { medium: "12px" },
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#666666"
  },
  height: "68px"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasJumbotron.ts
var carrabbasJumbotronStyles = {
  title: {
    fontColor: "#232222",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "2rem",
      small: ""
    },
    fontWeight: "500"
  },
  text: {
    fontColor: "#232222",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1.25rem",
      small: "1.25rem"
    },
    fontWeight: "400",
    lineHeight: { small: "", medium: "" },
    paddingBottom: ""
  },
  singleButton: {
    fontColor: "#404041",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem"
    },
    fontWeight: "600",
    backgroundColor: "#ffffff",
    border: {
      radius: "",
      color: "",
      width: "",
      style: ""
    },
    hover: {
      backgroundColor: "",
      fontColor: "",
      opacity: 0.8
    },
    padding: "20px 32px",
    letterSpace: "1px",
    lineHeight: "28px"
  },
  button: {
    fontColor: "#ffffff",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "0.875rem",
      medium: "1rem",
      large: "1.3rem"
    },
    fontWeight: "600",
    backgroundColor: "#404041",
    hover: {
      backgroundColor: "",
      fontColor: "",
      opacity: 0.8
    },
    border: {
      radius: "2px",
      width: "",
      color: "",
      style: ""
    },
    padding: "16px",
    letterSpace: "1px",
    paddingMobile: "16px"
  },
  notes: {
    fontColor: "#232222",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1rem"
    },
    fontWeight: "400"
  },
  padding: "30px",
  titlePadding: "16px 0px",
  paddingMobile: "40px 34px"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasLateralBar.ts
var carrabbasLateralBar = {
  backgroundColor: "#404041",
  font: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#ffffff",
    fontWeight: "600",
    fontSizes: {
      medium: "16px"
    }
  },
  opacity: "0.8",
  hoverColor: "#ffffff",
  hoverBackground: "#404041",
  border: "",
  borderRadius: "4px",
  padding: "8px"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasLegalPages.ts
var carrabbasLegalPagesStyle = {
  backgroundColor: "#ffffff",
  header: {
    title: {
      fontColor: "#666666",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    },
    subtitle: {
      fontColor: "#666666",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    }
  },
  mainContent: {
    subtitles: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    },
    texts: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: ""
      }
    }
  },
  table: {
    backgroundColor: "#232222",
    borderColor: "#EAEAEA"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasLine.ts
var carrabbasLine = {
  text: {
    font: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.187rem",
        small: "1.187rem",
        smallest: "1.187rem"
      }
    }
  },
  borderTop: {
    Dotted: "1px dashed #9e1d21",
    Solid: "1px solid #666666"
  },
  backgroundColor: "transparent"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasNewsletterForm.ts
var carrabbasNewsletterForm = {
  background: "#F5F3EB",
  title: {
    fontSizes: {
      medium: "2.25rem"
    },
    fontFamily: "Gibson-Regular",
    fontWeight: "600",
    fontColor: "#232222"
  },
  agreement: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "1rem"
    }
  },
  button: {
    fontWeight: "bold",
    fontFamily: "Gibson-SemiBold",
    fontColor: "#404041",
    fontSizes: {
      smallest: "1.25rem",
      small: "1.45rem",
      medium: "1.6rem",
      large: "1.85rem",
      largest: "1.95rem"
    },
    backgroundColor: "#ffffff",
    borderColor: "transparent"
  },
  form: {
    fontFamily: "Gibson-Regular",
    fontColor: "#404041",
    fontWeight: ""
  },
  response: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#232222",
    fontWeight: "600",
    fontSizes: {
      small: "1.75rem",
      medium: "2rem",
      large: "2.25rem"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasPod.ts
var carrabbasPodStyle = {
  large: {
    title: {
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        smallest: "1.75rem",
        small: "1.75rem",
        medium: "1.75rem",
        large: "2rem",
        largest: "2rem"
      },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "bold",
      textShadow: "none"
    },
    boxShadow: "0 0 0 2px #262524 inset",
    borders: "",
    description: {
      fontFamily: "Gibson-Regular",
      fontSizes: {
        small: "1rem",
        medium: "0.875rem",
        large: "0.875rem"
      },
      fontColor: { desktop: "#fff", mobile: "#404041" },
      fontWeight: "normal",
      textTransform: "none"
    },
    button: {
      backgroundColor: { desktop: "#9E1D21", mobile: "#9E1D21" },
      font: {
        fontFamily: "Gibson-SemiBold",
        fontSizes: {
          smallest: "1.15rem",
          small: "1.25rem",
          medium: "1.25rem",
          large: "1.5rem",
          largest: "1.5rem"
        },
        fontColor: { desktop: "#fff", mobile: "#fff" },
        fontWeight: "bold"
      },
      borderColor: { desktop: "none", mobile: "none" },
      hoverColor: { desktop: "#731518", mobile: "#731518", fontColor: "" },
      paddingBottom: {
        largest: "85px"
      }
    },
    padding: "",
    flexDirectionMobile: "column"
  },
  middle: {
    title: {
      fontFamily: "Gibson-Regular",
      fontSizes: {},
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "bold",
      paddingMobile: "16px",
      textShadow: "none"
    },
    borders: "2px solid #000",
    description: {
      fontFamily: "Gibson-Regular",
      fontSizes: {},
      fontColor: { desktop: "#fff", mobile: "#404041" },
      fontWeight: "normal",
      textTransform: ""
    },
    button: {
      backgroundColor: { desktop: "#fff", mobile: "#9E1D21" },
      font: {
        fontFamily: "Gibson-Regular",
        fontSizes: {},
        fontColor: { desktop: "#000", mobile: "#fff" },
        fontWeight: "bold"
      },
      borderColor: { desktop: "#fff", mobile: "#9E1D21" },
      hoverColor: { desktop: "#EDEDED", mobile: "#731518", fontColor: "" }
    },
    padding: "10px 24px;"
  },
  small: {
    title: {
      fontFamily: "Gibson-Regular",
      fontSizes: {
        smallest: "1.75rem",
        small: "1.75rem",
        medium: "1.75rem",
        large: "2rem",
        largest: "2rem"
      },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "bold",
      paddingMobile: "16px",
      textShadow: "none"
    },
    borders: "2px solid #000",
    description: {
      fontFamily: "Gibson-Regular",
      fontSizes: { medium: "1rem" },
      fontColor: { desktop: "#fff", mobile: "#404041" },
      fontWeight: "normal",
      textTransform: ""
    },
    button: {
      backgroundColor: { desktop: "#fff", mobile: "#9E1D21" },
      font: {
        fontFamily: "Gibson-SemiBold",
        fontSizes: {
          smallest: "1.15rem",
          small: "1.25rem",
          medium: "1.25rem",
          large: "1.5rem",
          largest: "1.5rem"
        },
        fontColor: { desktop: "#000", mobile: "#fff" },
        fontWeight: "bold"
      },
      borderColor: { desktop: "transparent", mobile: "transparent" },
      hoverColor: { desktop: "#EDEDED", mobile: "#731518", fontColor: "" }
    },
    padding: ""
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasQuestionsAndAnswersItem.ts
var carrabbasQuestionsAndAnswersItem = {
  name: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  questionAnswer: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1.125rem"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasQuestionsAndAnswers.ts
var carrabbasQuestionsAndAnswers = {
  title: {
    fontFamily: "Gibson-SemiBold",
    fontWeight: "600",
    fontColor: "#666666",
    fontSizes: {
      medium: "4rem",
      small: "1.5rem"
    }
  },
  text: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "2rem",
      small: "1.25rem"
    }
  },
  pagination: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1rem",
      small: "0.75rem"
    }
  },
  arrowColor: "#2c2c2b",
  paginationBorderColor: "#666666"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasRewardsModal.ts
var carrabbasRewardsModal = {
  backgroundColor: "#F6F6F6",
  closeIconColor: "#9E1D21",
  checkbox: {
    fontColor: "#404041",
    fontFamily: "Gibson-Regular",
    fontWeight: "500",
    fontSize: "14px",
    fontStyle: "italic",
    border: "2px solid #6666"
  },
  logoColor: "purple",
  button: {
    backgroundColor: "#404041",
    fontColor: "#ffff",
    borderColor: "#404041",
    hoverColor: "#6666"
  },
  title: {
    fontColor: "#404041",
    fontFamily: "",
    fontSizes: {
      small: "1.5rem",
      medium: "1.5rem",
      large: "2.1rem"
    }
  },
  subtitle: {
    fontFamily: "",
    fontColor: "#404041"
  },
  image: {
    width: "150px",
    height: "58px"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasSeating.ts
var carrabbasSeating = {
  container: {
    background: "#ffffff",
    color: "#666666",
    h1: {
      fontFamily: "Gibson-SemiBold",
      fontWeight: ""
    }
  },
  location: {
    color: "#666666",
    div: {
      margin: "16px 0 0 4px"
    },
    span: {
      fontFamily: "Gibson-Regular",
      fontWeight: "Bold",
      textTransform: "uppercase",
      margin: "16px 0 0 4px",
      fontSizes: {
        medium: "1.4rem"
      }
    }
  },
  button: {
    background: "#404041",
    margin: "22px 0px 0px",
    border: "none",
    hover: {
      opacity: "0.6"
    },
    fontFamily: "",
    fontWeight: "Bold",
    secondary: { background: "transparent", backgroundColor: "#931c20", border: "2px solid #931c20" }
  },
  info: {
    fontFamily: "",
    a: {
      hover: {
        color: "",
        opacity: "0.6"
      }
    }
  },
  guestInfo: {
    margin: "8px 0 0",
    border: "2px solid #404041",
    color: "#666666",
    height: "68px"
  },
  modalContent: {
    background: "#ffffff",
    fontFamily: {
      container: "",
      content: ""
    }
  },
  modalClose: {
    color: "#931c20"
  },
  successContainer: {
    background: "",
    backgroundColor: "#404041",
    minHeight: "calc(100vh - 242px)"
  },
  successContent: {
    textTransform: "",
    h1: {
      fontFamily: "Gibson-SemiBold",
      textTransform: "uppercase",
      fontSizes: {
        medium: "40px"
      }
    },
    h2: {
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      lineHeight: "38px",
      lineWeight: "400",
      marginTop: "24px",
      fontSizes: {
        medium: "24px"
      }
    },
    p: {
      fontWeight: "",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        medium: "24px"
      },
      lineHeight: "38px",
      margin: "24px 0 0"
    },
    button: {
      background: "#fff",
      borderColor: "#fff",
      color: "#404041",
      fontFamily: "Gibson-SemiBold",
      lineHeight: "28px",
      fontSizes: {
        medium: "24px"
      },
      hover: {
        opacity: "0.6",
        background: "",
        color: ""
      }
    }
  },
  item: {
    fontWeight: "",
    fontFamily: "",
    borderBottom: "",
    p: {
      a: {
        textDecoration: "",
        hover: {
          color: "",
          opacity: "0.6"
        }
      }
    }
  },
  detailsButton: {
    p: {
      padding: "10px 24px",
      alignCenter: "center",
      margin: "",
      fontFamily: "",
      mediaQuery: {
        viewport768px: {
          padding: "5px 24px"
        }
      },
      hover: {
        opacity: "0.6",
        color: ""
      }
    }
  },
  inline: {
    fontFamily: ""
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasSectionBanner.ts
var carrabbasSectionBanner = {
  backgroundColor: "#ffff",
  fontColor: "#000",
  aside: {
    fontColor: "",
    fontFamily: "jenna-sue, sans-serif",
    fontWeight: "",
    fontSizes: {
      small: "2.2rem",
      medium: "2.4rem"
    }
  },
  image: {
    desktop: {
      height: "600px",
      width: "auto"
    },
    mobile: {
      height: "unset",
      width: "80%"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasTabNavigationItem.ts
var carrabbasTabNavigationItem = {
  backgroundColorInactive: "#ededed",
  backgroundColorActive: "#184722",
  separatorColor: "#d3d3d3",
  link: {
    font: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "0.75rem"
      }
    }
  },
  linkActiveColor: "#ffffff",
  underlineColor: {
    inactive: {
      zeroOpacity: "rgba(64, 64, 65, 0)",
      fullOpacity: "rgba(64, 64, 65, 1)"
    },
    active: {
      zeroOpacity: "rgba(255, 255, 255, 0)",
      fullOpacity: "rgba(255, 255, 255, 1)"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasVideoTextButton.ts
var carrabbasVideoTextButton = {
  title: {
    fontColor: "#666666",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      medium: "2rem",
      small: "1.5rem"
    },
    fontWeight: "600"
  },
  text: {
    fontColor: "#666666",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1.25rem"
    },
    fontWeight: "400"
  },
  note: {
    fontColor: "#666666",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1.25rem"
    },
    fontWeight: "400"
  },
  button: {
    fontColor: "#ffffff",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "0.875rem",
      medium: "1.5rem"
    },
    fontWeight: "600",
    backgroundColor: "#404041"
  },
  lineColor: "#666666"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasContainer.ts
var carrabbasContainerStyle = {
  background: "none"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCmsDropdown.ts
var carrabbasCmsDropdownStyle = {
  label: {
    textTransform: "",
    fontFamily: "",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  wrapper: {
    border: "3px solid rgb(64, 64, 65)",
    fontFamily: "",
    fontWeight: "bold"
  },
  chevron: {
    margin: "auto 0"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasTopMessage.ts
var carrabbasTopMessage = {
  backgroundColor: "#9E1D21",
  arrowColor: "#ffffff",
  buttonColor: "#ffffff",
  text: {
    fontColor: "#ffffff",
    fontFamily: "Gibson-SemiBold",
    fontWeight: "400",
    fontSizes: {
      small: "12px",
      medium: "14px",
      large: "18px"
    },
    paddingTop: "0"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCarousel.ts
var carrabbasCarouselStyle = {
  arrows: {
    height: "calc(100% - 41px)"
  },
  dots: {
    iconPlayAndPause: {
      background: "transparent",
      color: "#9E1E22"
    },
    background: "#ffffff",
    backgroundMobile: "#ededed",
    width: "32px",
    mobileWidth: "24px",
    height: "8px",
    radius: "10px"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasDrawer.ts
var carrabbasDrawerStyle = {
  background: "rgb(247, 242, 233)"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasDRForm.ts
var carrabbasDRFormStyle = {
  button: {
    fontColor: "#404041",
    backgroundColor: "#FFFFFF",
    border: "2px solid",
    fontWeight: "600",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem",
      large: "1.5rem"
    },
    hoverColor: "#EDEDED",
    hoverFontColor: "#404041",
    opacity: "0.6",
    borderRadius: "2px",
    hoverColorModal: "#404041",
    hoverFontColorModal: "#FFFFFF",
    letterSpacing: ""
  },
  texts: {
    title: {
      fontColor: "#232222",
      fontSizes: {
        small: "1.75rem",
        medium: "2rem",
        large: "2.25rem"
      },
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600"
    },
    description: {
      fontColor: "#232222",
      fontSizes: { medium: "1rem" },
      fontFamily: "Gibson-Regular",
      fontWeight: "400"
    },
    label: {
      fontColor: "#404041",
      fontFamily: "Gibson-Regular",
      fontWeight: "600"
    }
  },
  backgroundColor: "#F5F3EB",
  backgroundImage: "",
  backgroundModalImage: "",
  borders: "#404041"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasFooter.ts
var carrabbasFooterStyle = {
  borderTop: "1px solid #e8dcc2",
  letterSpacing: "0px",
  backgroundColor: "#EDEDED",
  navbar: {
    marginBottom: "10px",
    color: "#404041",
    featuredItems: {
      fontFamily: "Gibson-SemiBold",
      menuItem: {
        fontSizes: {
          smallest: "16px",
          small: "16px",
          medium: "16px",
          large: "16px",
          largest: "16px"
        }
      }
    },
    unfeaturedItems: {
      fontFamily: "Gibson-Regular",
      menuItem: {
        fontSizes: {
          smallest: "14px",
          small: "14px",
          medium: "14px",
          large: "14px",
          largest: "14px"
        }
      }
    }
  },
  socialMediaPanel: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      medium: "14px",
      large: "14px",
      largest: "14px"
    }
  },
  cookiesBar: {
    backgroundColor: "#404041",
    fontFamily: "Gibson-Regular, sans-serif"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasNavBar.ts
var carrabbasNavBarStyle = {
  primary: {
    fontFamily: "Gibson-SemiBold, Gibson, sans-serif",
    fontWeight: "bold",
    fontColor: "",
    fontSizes: {
      default: "1.5rem"
    },
    color: "#232222",
    backgroundColor: ""
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasAnimatedCard.ts
var carrabbasAnimatedCard = {
  title: {
    textAlign: "center",
    textTranform: "uppercase",
    fontColor: "#931C20",
    fontFamily: "Gibson-SemiBold",
    fontWeight: "600",
    fontSizes: {
      medium: "2rem",
      large: "2.25rem"
    }
  },
  text: {
    fontColor: "#404041",
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontSizes: {
      medium: "1rem"
    },
    textAlign: "center"
  },
  linkText: {
    textAlign: "center",
    fontColor: "#ffffff",
    fontFamily: "Gibson-SemiBold",
    fontWeight: "600",
    fontSizes: {
      medium: "2.5rem"
    },
    lineHeight: "2.75rem"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasBodyBanner.ts
var carrabbasBodyBannerStyle = {
  padding: {
    desktop: "72px 56px",
    mobile: "40px 24px"
  },
  content: {
    marginLeft: {
      desktop: "0",
      mobile: "0"
    },
    gap: {
      desktop: "56px",
      mobile: "32px"
    }
  },
  main: {
    marginLeft: {
      desktop: "0",
      mobile: "0"
    },
    gap: {
      desktop: "24px",
      mobile: "16px"
    }
  },
  backgroundColor: "#F6F6F6",
  overtitle: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: {}
  },
  text: {
    fontFamily: "DinNextLTPro-Condensed",
    fontColor: "#fff",
    fontWeight: "Gibson-SemiBold",
    fontSizes: { medium: "1.2rem", small: "1.2rem" }
  },
  title: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: { medium: "2.2rem", small: "1.6rem" }
  },
  subtitle: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: { medium: "1.5rem", small: "5vw" }
  },
  nav: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#666666",
    fontWeight: "600",
    fontSizes: { medium: "1rem", small: "0.8rem" },
    justifyContent: "none",
    marginTop: {
      desktop: "0",
      mobile: "12px"
    },
    paddingRight: {
      desktop: "80px",
      mobile: "0"
    }
  },
  button: {
    fontColor: "",
    fontFamily: "",
    fontSizes: { medium: "", small: "" },
    fontWeight: "",
    hover: { fontColor: "", backgroundColor: "", borderColor: "" }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasAnimatedHero.ts
var carrabbasAnimatedHeroStyle = {
  title: {
    fontColor: "#333333",
    fontFamily: "IntroRust",
    fontSizes: { medium: "clamp(2.5rem,10vw,4rem)" },
    fontWeight: "500"
  },
  text: {
    fontColor: "",
    fontFamily: "IntroRust",
    fontSizes: {},
    fontWeight: ""
  },
  imageBannerMargin: ""
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasMenuNavigation.ts
var carrabbasMenuNavigation = {
  backgroundColorInactive: "#ededed",
  backgroundColorActive: "#184722",
  separatorColor: "#d3d3d3",
  link: {
    font: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "0.75rem"
      }
    },
    lineHeight: "20px"
  },
  linkActiveColor: "#ffffff",
  underlineColor: {
    inactive: {
      zeroOpacity: "rgba(64, 64, 65, 0)",
      fullOpacity: "rgba(64, 64, 65, 1)"
    },
    active: {
      zeroOpacity: "rgba(255, 255, 255, 0)",
      fullOpacity: "rgba(255, 255, 255, 1)"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasMenuItem.ts
var carrabbasMenuItem = {
  fontColor: "#404041",
  newMessageColor: "#931C20",
  title: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#404041",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  subTitle: {
    fontFamily: "Gibson-SemiBold",
    fontWeight: "600",
    fontColor: "#404041",
    fontSizes: {
      medium: "1rem"
    }
  },
  description: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#404041",
    fontSizes: {
      medium: "1rem"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasReservations.ts
var carrabbasReservations = {
  floatingLabelColor: ""
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasDeliveryContent.ts
var carrabbasDeliveryContentStyle = {
  headerTitle: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      large: "20px"
    },
    fontColor: "#121211",
    fontWeight: "600"
  },
  inputLabel: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  selectAddressButton: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "700"
  },
  mainText: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "700"
  },
  subText: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  headerButtonsColor: "#2C2C2B",
  backgroundColor: "#ffffff"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasDrawerMessage.ts
var carrabbasDrawerMessageStyle = {
  mainText: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "700"
  },
  subText: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  color: "#ABABAB",
  backgroundColor: "#FFFFFF"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasVideo.ts
var carrabbasVideo = {
  container: {
    fontFamily: "IntroRust, sans-serif",
    fontWeight: "500",
    fontColor: "",
    textAlign: "",
    padding: "40px 0",
    light: {
      fontColor: "#2c2c2b",
      backgroundColor: "#f6f2e8"
    },
    dark: {
      fontColor: "#ffffff",
      backgroundColor: "#262524"
    }
  },
  title: {
    fontSizes: {
      medium: "1.6rem"
    },
    textAlignMobile: ""
  },
  text: {
    fontFamily: "",
    fontSizes: {
      small: "1rem"
    },
    textAlignMobile: ""
  },
  play: {
    fontColor: "#ffffff"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasBanner.ts
var carrabbasBannerStyle = {
  padding: "120px 0",
  paddingMobile: "60px 0",
  title: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#000000",
    fontWeight: "700",
    fontSizes: { medium: "56px", small: "30px" }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCategoryItem.ts
var carrabbasCategoryItemStyle = {
  container: {
    backgroundImage: "",
    color: "#404041",
    padding: ""
  },
  content: {
    backgroundColor: "#F5F3EB",
    borderRadius: "0 0.25rem 0.25rem 0"
  },
  name: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontSize: {
      fontSizes: {
        medium: "1.125rem",
        small: "0.875rem"
      }
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasProductMenu.ts
var SITECORE_IMAGES_URL4 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/carrabbas/Order`;
var carrabbasProductMenu = {
  backgroundColor: "",
  itemTitle: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#404041",
    fontSizes: {
      medium: "16px"
    }
  },
  itemSubTitle: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "500",
    fontColor: "#404041",
    fontSizes: {
      medium: "14px"
    }
  },
  containerTitle: {
    fontFamily: "Queens-W",
    fontWeight: "500",
    fontColor: "#404041",
    fontSizes: {
      medium: "32px"
    },
    textTransform: "none"
  },
  productImagePlaceholder: `${SITECORE_IMAGES_URL4}/carrabbas-product-placeholder.webp`,
  detailsTitle: {
    fontFamily: "Queens-W",
    fontWeight: "500",
    fontColor: "#232222",
    fontSizes: {
      medium: "32px"
    },
    textTransform: ""
  },
  detailsSubtitle: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#404041",
    fontSizes: {
      medium: "20px"
    }
  },
  detailsDescription: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "20px"
    }
  },
  nutritionalDescription: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "20px",
      small: "12px"
    }
  },
  detailsCalories: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#404041",
    fontSizes: {
      medium: "16px"
    }
  },
  detailsMoreNutritionalInfo: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#232222",
    fontSizes: {
      medium: "16px"
    }
  },
  detailsTag: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#232222",
    fontSizes: {
      small: "12px",
      medium: "16px"
    }
  },
  detailsReturnButton: {
    fontFamily: "Gibson-Semibold, sans-serif"
  },
  highlightTag: {
    fontFamily: "Gibson, sans-serif",
    fontWeight: "700",
    fontColor: "#232222",
    fontSizes: {
      small: "12px",
      medium: "16px"
    }
  },
  highlightTagBackground: "#891A1C",
  detailsBackgroundColor: "#FFFFFF",
  restrictionTag: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "700",
    fontColor: "#232222",
    fontSizes: {
      small: "12px",
      medium: "14px"
    }
  },
  relatedProducts: {
    title: {
      fontFamily: "Gibson-Regular",
      fontWeight: "600",
      fontColor: "#404041",
      fontSizes: {
        medium: "16px",
        small: "14px"
      }
    },
    subtitle: {
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontColor: "#232222",
      fontSizes: {
        medium: "14px",
        small: "12px"
      }
    }
  },
  descriptionCategory: {
    fontFamily: "Gibson, sans-serif",
    backgroundColor: "#f5f3eb",
    lineHeight: "12px",
    fontColor: "#404041"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasDineRewardsForm.ts
var carrabbasDineRewardsForm = {
  banner: {
    container: {
      maxWidth: "1393px"
    },
    titleContainer: {
      font: { fontColor: "#404041", fontFamily: "Queens-W", fontWeight: "500" },
      textTransform: ""
    },
    title: {
      fontSize: { fontSizes: { medium: "32px", small: "23px" } },
      lineHeight: { desktop: "40.96px", mobile: "26.33px" },
      content: "Want a free Calamari?"
    },
    subtitle: {
      fontSize: { fontSizes: { medium: "24px", small: "16px" } },
      lineHeight: { desktop: "30.72px", mobile: "20.48px" },
      content: "Sign up for Dine Rewards now!"
    },
    backgroundColor: "#F5F3EB",
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "16px", mobile: "16px" },
      backgroundColor: "#9E1E22",
      hoverbackgroundColor: "rgb(115, 21, 24)",
      content: "I want my free calamari",
      border: "0",
      hoverBannerFontColor: ""
    },
    mobile: {
      padding: {
        desktopBreakpoint: "24px 46px 24px 71px",
        desktopLargeBreakpoint: "24px 46px 24px 114px"
      }
    },
    imageUrl: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/carrabbas/Dine-Rewards-Form/DR-form-carrabbas-banner.webp"
  },
  form: {
    information: {
      font: { fontColor: "#404041", fontFamily: "Gibson-Regular", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "12px", small: "12px" } },
      lineHeight: { desktop: "12px", mobile: "12px" },
      iconColor: "#404041",
      fontColorCompact: "#404041",
      iconColorCompact: "#404041"
    },
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "16px", mobile: "16px" },
      backgroundColor: {
        banner: "#9E1E22",
        compact: "#9E1E22"
      },
      hoverbackgroundColor: {
        banner: "rgb(115, 21, 24)",
        compact: "rgb(115, 21, 24)"
      },
      content: "JOIN NOW",
      border: {
        banner: "0",
        compact: "0"
      },
      hoverBannerFontColor: ""
    },
    description: {
      font: { fontColor: "#232222", fontFamily: "Gibson-Regular", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "12px", small: "12px" } },
      lineHeight: { desktop: "12px", mobile: "12px" },
      brand: "Carrabbas\xB4s",
      fontColorCompact: "#232222"
    }
  },
  compact: {
    title: {
      font: { fontColor: "#232222", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
      fontSize: { fontSizes: { medium: "1.5rem", small: "1.5rem" } },
      lineHeight: { desktop: "27.48px", mobile: "27.48px" },
      firstContent: "Want a free Calamari?",
      secondContent: "Sign up to dine rewards now! ",
      textTransform: "uppercase"
    },
    backgroundColor: "#F5F3EB",
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
      fontSize: { fontSizes: { medium: "1rem", small: "1rem" } },
      lineHeight: { desktop: "16px", mobile: "16px" },
      backgroundColor: "#9E1E22",
      hoverbackgroundColor: "rgb(115, 21, 24)",
      content: "I want my free calamari",
      border: "0",
      hoverBannerFontColor: ""
    },
    modal: {
      title: {
        font: { fontColor: "#404041", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
        fontSize: { fontSizes: { medium: "1rem" } },
        lineHeight: { desktop: "18.32px", mobile: "18.32px" },
        content: "SIGN UP TODAY FOR A COMPLIMENTARY CALAMARI!"
      },
      description: {
        font: { fontColor: "#404041", fontFamily: "", fontWeight: "" },
        fontSize: { fontSizes: { medium: "14px" } },
        lineHeight: { desktop: "14px", mobile: "14px" },
        content: `When you join the Dine Rewards program, you\u2019ll earn a complimentary Calamari today and points with every future purchase.`,
        infoContent: {
          fontFamily: ""
        }
      },
      icon: { color: "#9E1E22", hoverColor: "rgb(115, 21, 24)" },
      logo: {
        url: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/carrabbas-logo.svg",
        width: "150px"
      },
      backgroundColor: "#F6F6F6F2",
      labelColor: "#404041"
    }
  },
  feature: {
    description: {
      font: { fontColor: "#404041", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
      fontSize: { fontSizes: { medium: "16px", small: "16px" } },
      lineHeight: { desktop: "16px", mobile: "16px" }
    },
    icon: {
      color: "#9b9b9b",
      iconSize: {
        width: "24px",
        height: "24px",
        minWidth: "24px",
        minHeight: "24px"
      }
    }
  },
  successCard: {
    title: {
      font: { fontColor: "#404041", fontFamily: "Queens-W", fontWeight: "500" },
      textTransform: "",
      fontSize: { fontSizes: { medium: "32px" } },
      lineHeight: "40.96px"
    },
    description: {
      font: { fontColor: "#404041", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
      fontSize: { fontSizes: { medium: "16px" } },
      lineHeight: "16px"
    },
    banner: {
      fontColor: "#404041",
      button: {
        font: { fontColor: "#FFFFFF", fontFamily: "Gibson-SemiBold", fontWeight: "600" },
        fontSize: { fontSizes: { medium: "16px" } },
        lineHeight: "16px",
        backgroundColor: "#9E1E22",
        hoverbackgroundColor: "rgb(115, 21, 24)",
        border: "0",
        hoverBannerFontColor: ""
      }
    },
    compact: {
      fontColor: "#404041",
      button: {
        backgroundColor: "transparent",
        hoverbackgroundColor: "#404041",
        fontColor: "#404041",
        hoverFontColor: "#FFFFFF",
        borderRadius: "6px",
        border: "2px solid"
      }
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCardList.ts
var carrabbasCardListStyle = {
  selectedColor: "#8B734B",
  backgroundColor: "#FFFFFF",
  borderColor: "#D7D3CD",
  restaurantHoursBackgroundColor: "#F5F3EB",
  iconLocationColor: "#184722",
  emptyText: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#121211",
    fontWeight: "600"
  },
  restaurantName: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "600"
  },
  restaurantDistance: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  restaurantAddress: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  restaurantHours: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#121211",
    fontWeight: "400"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasLocationDisplay.ts
var carrabbasLocationDisplayStyle = {
  container: {
    backgroundColor: "#F5F3EB",
    borderColor: "#D7D3CD",
    color: "#404041",
    fontFamily: "Gibson-Regular, sans-serif"
  },
  boldFontFamily: "Gibson-Semibold, sans-serif"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCurbsideSearchContent.ts
var carrabbasCurbsideSearchContentStyle = {
  headerTitle: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      large: "20px"
    },
    fontColor: "#232222",
    fontWeight: "600"
  },
  inputLabel: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#232222",
    fontWeight: "400"
  },
  selectLocationButton: {
    fontFamily: "#232222, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "600"
  },
  useMyLocation: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#9E1E22",
    fontWeight: "600"
  },
  toast: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "400"
  },
  headerButtonsColor: "#232222",
  backgroundColor: "#FFFFFF"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasPriceTag.ts
var carrabbasPriceTag = {
  cost: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  },
  name: {
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasChooseHandoffMethod.ts
var carrabbasChooseHandoffMethodStyle = {
  backgroundColor: "#ffffff",
  header: {
    fontColor: "#121211",
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "20px"
    }
  },
  button: {
    border: "2px solid #2c2c2b",
    fontColor: "#2c2c2b",
    fontFamily: "Gibson-Regular, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "24px"
    },
    textTransform: "none"
  },
  disableButton: {
    borderColor: "#d4d4d4",
    backgroundColor: "#d4d4d4",
    color: "white"
  },
  selectedButton: {
    backgroundColor: "#8B734B",
    borderColor: "#8B734B",
    color: "white"
  },
  icon: { color: "#2c2c2b" },
  bottomButton: {
    fontColor: "#891a1c",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: { medium: "14px" }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasConfirmLocation.ts
var carrabbasConfirmLocationStyle = {
  backgroundColor: "#ffffff",
  header: {
    fontColor: "#121211",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "20px"
    }
  },
  title: {
    fontColor: "#2c2c2b",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "400",
    fontSizes: {
      medium: "18px"
    }
  },
  icon: {
    color: "#2c2c2b"
  },
  bottomButton: {
    fontColor: "#8B734B",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: { medium: "14px" }
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasSeePrice.ts
var carrabbasSeePrice = {
  seePriceText: {
    fontFamily: "Gibson-Semibold, sans-serif",
    fontWeight: "700",
    fontColor: "#404041",
    fontSizes: {
      small: "14px"
    }
  },
  backgroundColor: "#F5F3EB"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasAlternativeMenuItem.ts
var carrabbasAlternativeMenuItemStyle = {
  title: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "16px",
      large: "18px"
    },
    fontColor: "#404041",
    fontWeight: "400"
  },
  icon: {
    color: "#404041",
    size: "36px"
  },
  containerBorderColor: "#D7D3CD",
  backgroundColor: ""
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasOptionCard.ts
var SITECORE_IMAGES_URL5 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/carrabbas`;
var carrabbasOptionCardStyle = {
  title: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      small: "14px",
      medium: "16px",
      large: "18px"
    },
    fontColor: "#232222",
    fontWeight: "400"
  },
  backgroundColor: "#F5F3EB",
  optionImagePlaceHolder: `${SITECORE_IMAGES_URL5}/small-carrabbas-logo.svg`,
  defaultImageBackground: "#E6DDCF"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasSideOptions.ts
var carrabbasSideOptionsStyle = {
  title: {
    fontColor: "#232222",
    boldColor: "#9E1E22",
    fontFamily: "Queens-W",
    fontSizes: {
      medium: "36px"
    },
    fontWeight: "400",
    textTransform: ""
  },
  fontFamily: "Gibson-Regular, sans-serif",
  backgroundColor: "#ffffff"
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasProductTag.ts
var carrabbasProductTag = {
  clipPathRight: {
    content: "polygon(0 0, 100% 0, calc(100% - 18px) 100%, 0% 100%)",
    before: ""
  },
  showClipPathLeft: false,
  left: "0px",
  top: "0px",
  borderTopLeft: "2px",
  smallTag: {
    fontSize: "12px",
    lineHeight: "12px",
    left: "0px",
    content: {
      padding: "4px 26px 4px 6px"
    }
  },
  largeTag: {
    fontSize: "14px",
    lineHeight: "14px",
    left: "0px",
    content: {
      padding: "4px 28px 4px 8px"
    }
  },
  fontWeight: 600,
  textTransform: "none",
  height: {
    large: "24px",
    small: "20px"
  },
  icon: {
    widthDesktop: "16px",
    heightDesktop: "16px",
    widthMobile: "12px",
    heightMobile: "12px"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasCardSlider.ts
var carrabbasCardSliderStyle = {
  arrow: {
    color: "#ABABAB",
    hover: "#184722"
  }
};

// src/bbi/carrabbas/carrabbasComponentsStyle/carrabbasRelatedProducts.ts
var carrabbasRelatedProductsStyle = {
  title: {
    fontColor: "#232222",
    fontFamily: "Queens-W",
    fontSizes: {
      medium: "36px"
    },
    fontWeight: "400",
    textTransform: ""
  },
  background: "#E6DDCF"
};

// src/bbi/carrabbas/carrabbasUITheme.ts
var CarrabbasUITheme = {
  accordion: carrabbasAccordion,
  buttonCMS: carrabbasButtonCmsStyle,
  carousel: carrabbasCarouselStyle,
  container: carrabbasContainerStyle,
  footer: carrabbasFooterStyle,
  navBar: carrabbasNavBarStyle,
  topMessage: carrabbasTopMessage,
  header: carrabbasHeaderStyle,
  pod: carrabbasPodStyle,
  forms: carrabbasDRFormStyle,
  hero: carrabbasHero,
  animatedCard: carrabbasAnimatedCard,
  menuItem: carrabbasMenuItem,
  reservations: carrabbasReservations,
  deliveryContent: carrabbasDeliveryContentStyle,
  drawerMessage: carrabbasDrawerMessageStyle,
  video: carrabbasVideo,
  banner: carrabbasBannerStyle,
  productMenu: carrabbasProductMenu,
  cardList: carrabbasCardListStyle,
  dineRewardsForm: carrabbasDineRewardsForm,
  curbsideSearchContent: carrabbasCurbsideSearchContentStyle,
  priceTag: carrabbasPriceTag,
  seePrice: carrabbasSeePrice,
  alternativeMenuItem: carrabbasAlternativeMenuItemStyle,
  optionCard: carrabbasOptionCardStyle,
  productTag: carrabbasProductTag,
  animatedCardSecondary: {
    title: {
      fontColor: "#404041",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: "2rem"
      }
    },
    text: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: "1rem"
      }
    },
    button: {
      fontColor: "#ffffff",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600",
      fontSizes: {
        medium: "0.875rem",
        large: "1.3rem"
      },
      backgroundColor: "#404041"
    }
  },
  catalogItem: carrabbasCatalogItemStyle,
  catalogItemSecondary: carrabbasCatalogItemSecondaryStyle,
  textButtonBanner: {
    title: {
      fontColor: "#ffffff",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem",
        large: "1.5rem"
      },
      fontWeight: "600"
    },
    button: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem",
        large: "1.5rem"
      },
      fontWeight: "600",
      backgroundColor: "#ffffff",
      hover: {
        backgroundColor: "",
        opacity: "0.8"
      }
    }
  },
  inputCms: carrabbasInputCmsStyle,
  rewardsModal: carrabbasRewardsModal,
  cardSecondary: carrabbasCardSecondaryStyles,
  grid: {
    maxWidth: "900px",
    margin: "auto"
  },
  drawer: carrabbasDrawerStyle,
  jumbotron: carrabbasJumbotronStyles,
  catalog: carrabbasCatalogStyle,
  line: carrabbasLine,
  newsletterForm: carrabbasNewsletterForm,
  tabNavigationItem: carrabbasTabNavigationItem,
  questionsAndAnswersItem: carrabbasQuestionsAndAnswersItem,
  questionsAndAnswers: carrabbasQuestionsAndAnswers,
  lateralBar: carrabbasLateralBar,
  legalPages: carrabbasLegalPagesStyle,
  videoTextButton: carrabbasVideoTextButton,
  seating: carrabbasSeating,
  dropdownCms: carrabbasCmsDropdownStyle,
  sectionBanner: carrabbasSectionBanner,
  bodyBanner: carrabbasBodyBannerStyle,
  animatedHero: carrabbasAnimatedHeroStyle,
  menuNavigation: carrabbasMenuNavigation,
  categoryItem: carrabbasCategoryItemStyle,
  locationDisplay: carrabbasLocationDisplayStyle,
  chooseHandoffMethod: carrabbasChooseHandoffMethodStyle,
  confirmLocation: carrabbasConfirmLocationStyle,
  cardSlider: carrabbasCardSliderStyle,
  relatedProducts: carrabbasRelatedProductsStyle,
  sideOptions: carrabbasSideOptionsStyle
};

// src/bbi/carrabbas/carrabbasOrderUi.tsx
var import_styled_components7 = require("styled-components");
var import_IconUnion2 = require("@maverick/icons/dist/IconUnion");
var import_IconDollarSignCarrabbas = require("@maverick/icons/dist/IconDollarSignCarrabbas");
var import_IconCarrabbasCurbside2 = require("@maverick/icons/dist/IconCarrabbasCurbside");
var import_IconCarrabbasDelivery2 = require("@maverick/icons/dist/IconCarrabbasDelivery");
var import_IconlocationCarrabbas2 = require("@maverick/icons/dist/IconlocationCarrabbas");
var import_IconLocationOutlineCarrabbas2 = require("@maverick/icons/dist/IconLocationOutlineCarrabbas");
var import_IconPickup2 = require("@maverick/icons/dist/IconPickup");
var import_IconRecentOrdersCarrabbas = require("@maverick/icons/dist/IconRecentOrdersCarrabbas");
var import_IconEarnCarrabbas = require("@maverick/icons/dist/IconEarnCarrabbas");
var import_SeloIconR = require("@maverick/icons/dist/SeloIconR");
var import_IconUserFilledCarrabbas = require("@maverick/icons/dist/IconUserFilledCarrabbas");
var import_IconUserCarrabbas = require("@maverick/icons/dist/IconUserCarrabbas");
var import_IconBagCarrabbas = require("@maverick/icons/dist/IconBagCarrabbas");
var import_IconBagStampCarrabbas = require("@maverick/icons/dist/IconBagStampCarrabbas");
var import_ItalianBuildings = require("@maverick/icons/dist/ItalianBuildings");
var import_IconLogoCarrabbas = require("@maverick/icons/dist/IconLogoCarrabbas");
var import_IconEdit2 = require("@maverick/icons/dist/IconEdit");
var import_IconMapDeliveryCarrabbas = require("@maverick/icons/dist/IconMapDeliveryCarrabbas");
var import_IconCall2 = require("@maverick/icons/dist/IconCall");
var import_RightCloudIcon2 = require("@maverick/icons/dist/RightCloudIcon");
var import_LeftCloudIcon2 = require("@maverick/icons/dist/LeftCloudIcon");
var import_IconFlame = require("@maverick/icons/dist/IconFlame");
var import_IconPin2 = require("@maverick/icons/dist/IconPin");
var import_IconCheck = require("@maverick/icons/dist/IconCheck");
var import_IconCarrabbasAppOnlyCategory = require("@maverick/icons/dist/IconCarrabbasAppOnlyCategory");
var import_IconMissingPoints2 = require("@maverick/icons/dist/IconMissingPoints");
var import_UserLoggedCarrabbas = require("@maverick/icons/dist/UserLoggedCarrabbas");
var import_FavoriteCarrabbas = require("@maverick/icons/dist/FavoriteCarrabbas");
var import_jsx_runtime2 = require("react/jsx-runtime");
var BRAND2 = "carrabbas" /* Carrabbas */;
var SITECORE_IMAGES_URL6 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/${BRAND2}/Order`;
var CarrabbasOrderUi = {
  app: {
    brand: "carrabbas" /* Carrabbas */,
    name: "Carrabba's Italian Grill",
    shortName: `Carrabba's`,
    providerAuth0: "carrabbasAuth0"
  },
  global: import_styled_components7.css`
		body {
			font-family: 'Gibson', sans-serif;
			color: #404041;
		}
	`,
  mapPopup: import_styled_components7.css`
		font-family: 'Gibson', sans-serif;
	`,
  colors: {
    primary: {
      lightContrast: "#D63D3D",
      light: "#CD2B2B",
      medium: "#9E1E22",
      dark: "#60181B",
      variant: "#8B734B",
      lightVariant: "#B1976D"
    },
    secondary: {
      light: "#F5F3EB",
      medium: "#E6DDCF",
      dark: "#D7D3CD",
      variant: "#184722",
      darkVariant: "#184722"
    },
    tertiary: {
      light: "#F6F6F6",
      medium: "#666666",
      dark: "#404041"
    },
    complementary: {
      success: "#2D8633",
      error: "#B00020",
      warning: "#FFB61F",
      information: "#1C9BDB",
      hyperlink: "#3078CD",
      disabled: "#ABABAB"
    },
    neutral: {
      light: "#FFFFFF",
      medium: "#EAEAEA",
      dark: "#232222"
    }
  },
  typography: {
    headline: {
      h1: import_styled_components7.css`
				font-family: 'Queens', sans-serif;
				font-weight: 500;
				font-size: 48px;
				line-height: 52px;
			`,
      h2: import_styled_components7.css`
				font-family: 'Queens', sans-serif;
				font-weight: 500;
				font-size: 40px;
				line-height: 44px;
			`,
      h3: import_styled_components7.css`
				font-family: 'Queens', sans-serif;
				font-weight: 500;
				font-size: 32px;
				line-height: 36px;
			`,
      h4: import_styled_components7.css`
				font-family: 'Queens', sans-serif;
				font-weight: 500;
				font-size: 24px;
				line-height: 28px;
			`,
      h4Variant: import_styled_components7.css`
				font-weight: 600;
				font-size: 24px;
				line-height: 28px;
			`,
      h5: import_styled_components7.css`
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
			`,
      h6: import_styled_components7.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`
    },
    title: {
      title: import_styled_components7.css`
				font-weight: 600;
				font-size: 32px;
				line-height: 36px;
			`,
      subtitle: import_styled_components7.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`,
      dRBanner: import_styled_components7.css`
				font-family: 'Gibson', sans-serif;
				width: 60%;
			`
    },
    body: {
      bodyLarge: import_styled_components7.css`
				font-size: 18px;
				line-height: 24px;
			`,
      body: import_styled_components7.css`
				font-size: 16px;
				line-height: 20px;
			`,
      bodySmall: import_styled_components7.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    inputs: {
      buttonLarge: import_styled_components7.css`
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
			`,
      button: import_styled_components7.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`,
      buttonSmall: import_styled_components7.css`
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
			`,
      inputText: import_styled_components7.css`
				font-size: 16px;
				line-height: 20px;
			`,
      buttonItalic: import_styled_components7.css`
				font-weight: 500;
				font-style: italic;
				font-size: 14px;
				font-weight: 18px;
			`,
      inputLabel: import_styled_components7.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    caption: {
      caption: import_styled_components7.css`
				font-size: 12px;
				line-height: 16px;
			`,
      captionMedium: import_styled_components7.css`
				font-weight: 600;
				font-size: 12px;
				line-height: 16px;
			`
    },
    overline: {
      overline: import_styled_components7.css`
				font-size: 10px;
				line-height: 14px;
			`
    },
    tagFeature: import_styled_components7.css`
			font-family: 'Queens', sans-serif;
			font-size: 12px;
			font-weight: 500;
			padding: 4px 6px 0px 6px;
		`,
    tagProduct: import_styled_components7.css``
  },
  fontImports: () => /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_jsx_runtime2.Fragment, {}),
  fontFaces: import_styled_components7.css`
		@font-face {
			font-family: 'Queens';
			src: url('/order/fonts/queens/Queens-Medium.woff2') format('woff2'),
				url('/order/fonts/queens/Queens-Medium.woff') format('woff');
		}

		@font-face {
			font-family: 'Gibson';
			src: url('/order/fonts/gibson/Gibson-Regular.woff2') format('woff2'),
				url('/order/fonts/gibson/Gibson-Regular.woff') format('woff');
		}

		@font-face {
			font-family: 'Gibson';
			src: url('/order/fonts/gibson/Gibson-SemiBold.woff2') format('woff2'),
				url('/order/fonts/gibson/Gibson-SemiBold.woff') format('woff');
			font-weight: 600;
		}

		@font-face {
			font-family: 'TradeGothic';
			src: url('/order/fonts/TradeGothic/Trade-Gothic-LT-Condensed-No-18.woff') format('woff');
			font-weight: normal;
			font-style: normal;
		}

		@font-face {
			font-family: 'TradeGothic';
			src: url('/order/fonts/TradeGothic/Trade-Gothic-LT-Bold-Condensed-No-20.woff') format('woff');
			font-weight: bold;
			font-style: normal;
		}
	`,
  texts: {
    SelectHandoffMethodTitle: "Ciao!",
    CheckoutPoints: "points",
    DineRewardsCardTitleGuest: "Ciao!",
    DineRewardsCardTitleLogged: "Ciao!",
    DineRewardsAuthenticatedBannerText: `It's our pleasure to serve you! <br/> Check back in 24 hours to see points earned from this order!`,
    BlankOrderSummaryTitle: "Oops!",
    BlankOrderSummarySubtitle: `There's nothing here except beautiful Italian vineyards!`,
    DineRewardsBannerTitle: "Ciao!",
    NewFavoriteNamePlaceholder: "e.g. Carrabba Dinner",
    AddOnsDescription: "",
    DineRewardsSalutation: "Ciao",
    EmptyRecentOrdersBoldText: `It seems you don't have any orders yet.`,
    EmptyRecentOrdersNormalText: "Get your steak now!",
    MyFavoritesPageTitle: "My favorites",
    EmptyMyFavoritesBoldText: `You don't have any favorites yet.`,
    EmptyMyFavoritesNormalText: "Get your steak and favorite your order!",
    ModalTitle: "Attention!",
    TitleMessageErrorPage: "Sorry, an unexpected error has occurred.",
    OrderSummarySuggestion: "Complete your meal",
    AddOnsSubtitle: "Add Ons",
    FreeSubtitle: "Free",
    EmptytRewardsNormalText: "Get your steak on to start earning!",
    EmptytActivityNormalText: "Get your steak now!",
    DRBannerTitle: "Get a free Calamari and rewards!",
    SignUpRewardText: "Earn points & redeem rewards",
    SignUpPhoneText: "Seamless online ordering",
    SignUpReorderText: "Quickly reorder recents & favorites",
    NoRestaurantFoundDelivery: "There are no locations near you that offer delivery to your destination. Please place your order for curbside pickup."
  },
  icons: {
    curbside: import_IconCarrabbasCurbside2.IconCarrabbasCurbside,
    delivery: import_IconCarrabbasDelivery2.IconCarrabbasDelivery,
    pickup: import_IconPickup2.IconPickup,
    iconDollarSign: import_IconDollarSignCarrabbas.IconDollarSignCarrabbas,
    iconCall: import_IconCall2.IconCall,
    iconEdit: import_IconEdit2.IconEdit,
    iconUnion: import_IconUnion2.IconUnion,
    iconLocation: import_IconlocationCarrabbas2.IconlocationCarrabbas,
    iconLocationColor: "#184722",
    iconLocationOutline: import_IconLocationOutlineCarrabbas2.IconLocationOutlineCarrabbas,
    iconLocationOutlineColor: "#184722",
    iconMapDelivery: import_IconMapDeliveryCarrabbas.IconMapDeliveryCarrabbas,
    iconRecentOrders: import_IconRecentOrdersCarrabbas.IconRecentOrdersCarrabbas,
    iconMyRewards: import_SeloIconR.SeloIconR,
    iconContactInfo: import_IconUserFilledCarrabbas.IconUserFilledCarrabbas,
    iconFooter: import_ItalianBuildings.ItalianBuildings,
    iconBasketStamp: import_IconBagStampCarrabbas.IconBagStampCarrabbas,
    iconBasketHeader: import_IconBagCarrabbas.IconBagCarrabbas,
    iconUserHeader: import_IconUserCarrabbas.IconUserCarrabbas,
    iconUserLoggedHeader: import_IconUserFilledCarrabbas.IconUserFilledCarrabbas,
    iconUserLoggedAnimation: import_UserLoggedCarrabbas.UserLoggedCarrabbas,
    iconHeaderColor: "#232222",
    iconModal: void 0,
    iconEat: import_IconRecentOrdersCarrabbas.IconRecentOrdersCarrabbas,
    iconEarn: import_IconEarnCarrabbas.IconEarnCarrabbas,
    iconLogo: import_IconLogoCarrabbas.IconLogoCarrabbas,
    iconProfilePageLeft: import_LeftCloudIcon2.LeftCloudIcon,
    iconProfilePageRight: import_RightCloudIcon2.RightCloudIcon,
    iconTemperature: import_IconFlame.IconFlame,
    iconNoRestaurantsFound: import_IconPin2.IconPin,
    iconNoRestaurantsFoundSize: import_styled_components7.css`
			width: 42px;
			height: 54px;
		`,
    iconSelectedCard: import_IconCheck.IconCheck,
    iconAppOnlyCategory: import_IconCarrabbasAppOnlyCategory.IconCarrabbasAppOnlyCategory,
    iconMissingPoints: import_IconMissingPoints2.IconMissingPoints,
    iconFavoriteAnimation: import_FavoriteCarrabbas.FavoriteCarrabbas
  },
  images: {
    checkoutBackground: `${SITECORE_IMAGES_URL6}/${BRAND2}-checkout-bg.webp`,
    orderReceivedBackground: `${SITECORE_IMAGES_URL6}/${BRAND2}-order-received-bg.webp`,
    blankOrderSummaryBackground: `${SITECORE_IMAGES_URL6}/${BRAND2}-empty-basket-bg.webp`,
    dineRewardsBanner: `${SITECORE_IMAGES_URL6}/${BRAND2}-dr-card-order-summary.webp`,
    bgRewardsBanner: `${SITECORE_IMAGES_URL6}/${BRAND2}-dr-banner-bg.webp`,
    recentOrdersRightSide: `${SITECORE_IMAGES_URL6}/${BRAND2}-recent-orders-bg.webp`,
    myFavoritesRightSide: `${SITECORE_IMAGES_URL6}/${BRAND2}-my-faves-bg.webp`,
    myRewardsRightSide: `${SITECORE_IMAGES_URL6}/${BRAND2}-my-rewards-bg.webp`,
    myActivityRightSide: `${SITECORE_IMAGES_URL6}/${BRAND2}-my-activity-bg.webp`,
    productPlaceholder: `${SITECORE_IMAGES_URL6}/${BRAND2}-product-placeholder.webp`,
    emptyUserPageBackground: import_styled_components7.css`
			background: url(${`${SITECORE_IMAGES_URL6}/${BRAND2}-empty-user-pages-bg.webp`}) bottom;
			background-size: cover;
		`,
    DRBannerBackgroundImage: `${SITECORE_IMAGES_URL6}/${BRAND2}-order-summary-dr-banner.webp`
  },
  links: {
    nutritionAdditionalInfo: "https://www.carrabbas.com/nutrition",
    privacyPolicy: "https://www.carrabbas.com/privacy-rights/privacy-policy",
    logoMeta: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/carrabbas/Auth0/cig-logo.svg",
    navBarBackground: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/texture-slate.png",
    appDeepLink: "carrabbas://rewards",
    appStoreAndroid: "https://play.google.com/store/apps/details?id=com.carrabbas",
    appStoreIos: "https://apps.apple.com/us/app/carrabbas/id1085789110"
  },
  booleans: {
    productImageTextureDetail: false
  },
  tipOptions: {
    tipOptions: [
      { label: "18%", value: 0.18 },
      { label: "20%", value: 0.2 },
      { label: "22%", value: 0.22 }
    ]
  },
  fonts: {
    src: 'url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/carrabbas/Auth0/Gibson-Regular.woff2) format("woff2")',
    family: "Gibson"
  },
  specificStyle: {
    orderTimeLabelText: import_styled_components7.css``,
    rewardsCounter: import_styled_components7.css``,
    messageInfoMessage: import_styled_components7.css``
  },
  productTag: {
    clipPathRight: "polygon(0 0, 100% 0, calc(100% - 18px) 100%, 0% 100%)",
    showClipPathLeft: false,
    left: "0px",
    top: "0px",
    borderTopLeft: "2px",
    smallTag: {
      left: "0px"
    },
    largeTag: {
      left: "0px"
    },
    fontWeight: 600
  }
};

// src/bbi/carrabbas/carrabbasTheme.ts
var CarrabbasTheme = __spreadProps(__spreadValues({}, CarrabbasSharedTheme), {
  components: __spreadValues({}, CarrabbasUITheme),
  cmsComponents: __spreadValues({}, CarrabbasCmsTheme),
  orderUi: CarrabbasOrderUi
});

// src/bbi/carrabbas/carrabbasButtons.ts
var import_styled_components8 = __toESM(require("styled-components"));
var CarrabbasBaseButton = import_styled_components8.default.a`
	width: auto;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-align: center;
	border-radius: unset;
	border: 1px solid;
	border-color: transparent;
	font-family: 'Gibson-SemiBold';
	font-weight: bold;
	font-size: 1.5rem;
	padding: 12px 16px;

	@media (max-width: 768px) {
		width: 100%;
	}
`;
var CarrabbasGrayButton = (0, import_styled_components8.default)(CarrabbasBaseButton)`
	color: #f6f6f6;
	background-color: #404041;
	padding: 20px 30px;
	border: unset;

	:hover {
		background-color: #404041;
		color: #fff;
		opacity: 0.8;
	}
`;

// src/bbi/bonefishgrill/bonefishgrillSharedTheme.ts
var import_styled_components9 = require("styled-components");
var BonefishgrillSharedTheme = {
  colors: {
    primary: {
      light: "#437F78",
      lightContrast: "#184722",
      medium: "#404041",
      dark: "#9E1D21"
    },
    secondary: {
      light: "#2d2926",
      medium: "#e6ddcf",
      dark: "#78838E"
    },
    terciary: {
      light: "#f6f6f6",
      medium: "#404041",
      dark: "#2d2926"
    },
    complementary: {
      success: "#2d8633",
      error: "#b00020",
      errorText: "#b00020",
      warning: "#ffB61f",
      information: "#1c9Bdb",
      hyperlink: "#3078cd",
      errorMessage: {
        bannerErrorMessage: "#b00020",
        compactErrorMessage: "#b00020"
      }
    },
    neutral: {
      black: "#2d2926",
      white: "#ffffff",
      gray: "#eaeaea",
      disabled: "#ABABAA"
    }
  },
  typography: {
    headline: {
      h1: import_styled_components9.css`
				font-family: 'Veneer';
				font-size: 48px;
				line-height: 52px;
			`,
      h2: import_styled_components9.css`
				font-family: 'Veneer';
				font-size: 40px;
				line-height: 44px;
			`,
      h3: import_styled_components9.css`
				font-family: 'Veneer';
				font-size: 32px;
				line-height: 36px;
			`,
      h4: import_styled_components9.css`
				font-family: 'Veneer';
				font-size: 24px;
				line-height: 28px;
			`,
      h5: import_styled_components9.css`
				font-family: 'Veneer';
				font-size: 20px;
				line-height: 24px;
			`,
      h6: import_styled_components9.css`
				font-family: 'Veneer';
				font-size: 16px;
				line-height: 20px;
			`
    },
    subtitle: {
      subtitle: import_styled_components9.css`
				font-weight: 600;
				font-size: 16px;
				line-height: 20px;
			`
    },
    body: {
      bodySmall: import_styled_components9.css`
				font-size: 14px;
				line-height: 20px;
			`,
      body: import_styled_components9.css`
				font-size: 16px;
				line-height: 20px;
			`,
      bodyLarge: import_styled_components9.css`
				font-size: 18px;
				line-height: 28px;
			`,
      bodySmallBold: import_styled_components9.css`
				font-size: 14px;
				line-height: 20px;
				font-weight: 700;
			`
    },
    inputs: {
      buttonSmall: import_styled_components9.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 14px;
				line-height: 18px;
			`,
      button: import_styled_components9.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 16px;
				line-height: 18px;
			`,
      buttonLarge: import_styled_components9.css`
				font-family: Roboto;
				font-weight: 700;
				font-size: 18px;
				line-height: 24px;
			`,
      italicButton: import_styled_components9.css`
				font-family: Roboto;
				font-style: italic;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
			`,
      textInputs: import_styled_components9.css`
				font-size: 16px;
				line-height: 20px;
			`,
      textInputsSmall: import_styled_components9.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    caption: {
      caption: import_styled_components9.css`
				font-size: 12px;
				line-height: 16px;
			`,
      captionMedium: import_styled_components9.css`
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
			`,
      captionLarge: import_styled_components9.css`
				font-weight: 400;
				font-size: 16px;
				line-height: 20px;
			`
    },
    overline: {
      overline: import_styled_components9.css`
				font-size: 10px;
				line-height: 14px;
				letter-spacing: 1.5px;
				text-transform: uppercase;
			`
    }
  },
  background: {
    dark: {
      image: "",
      color: "#262524"
    },
    light: {
      image: "",
      color: ""
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillCmsTheme.ts
var import_IconLocationBonefish = require("@maverick/icons/dist/IconLocationBonefish");
var import_IconLocationOutlineBonefish = require("@maverick/icons/dist/IconLocationOutlineBonefish");
var import_IconPin3 = require("@maverick/icons/dist/IconPin");
var import_ExclamationIcon2 = require("@maverick/icons/dist/ExclamationIcon");
var import_styled_components10 = require("styled-components");
var import_IconCurbside3 = require("@maverick/icons/dist/IconCurbside");
var import_IconDelivery3 = require("@maverick/icons/dist/IconDelivery");
var BonefishgrillCmsTheme = {
  articlePage: {
    fontFamily: "",
    lineHeight: "",
    a: {
      color: "blue"
    }
  },
  icons: {
    curbside: import_IconCurbside3.IconCurbside,
    delivery: import_IconDelivery3.IconDelivery,
    iconLocation: import_IconLocationBonefish.IconLocationBonefish,
    iconLocationOutline: import_IconLocationOutlineBonefish.IconLocationOutlineBonefish,
    iconSearchLocation: import_IconPin3.IconPin,
    iconSearchLocationSize: import_styled_components10.css`
			width: 42px;
			height: 54px;
		`,
    iconWarning: import_ExclamationIcon2.ExclamationIcon
  },
  shortName: "Bonefish"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillAcccordion.ts
var bonefishgrillAccordion = {
  variant: {
    primary: {
      background: "#ffffff",
      fontColor: "#000",
      titleColor: "#000",
      subtitleColor: "#000"
    },
    secondary: {
      background: "#DEDACE",
      fontColor: "#000",
      titleColor: "#000",
      subtitleColor: "#000"
    }
  },
  title: {
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontColor: "#000",
    fontWeight: "700",
    fontSizes: {
      small: "1.5rem",
      medium: "2rem"
    }
  },
  subtitle: {
    fontFamily: "DinNextLTPro-Regular",
    fontColor: "#000",
    fontWeight: "400",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem"
    }
  },
  accordionItem: {
    title: {
      fontFamily: "DinNextLTPro-LightCondensed",
      fontColor: "#000",
      fontWeight: "300",
      fontSizes: {
        small: "1.5rem",
        medium: "1.5rem"
      },
      paddingRight: "0.5rem"
    },
    text: {
      fontFamily: "DinNextLTPro-LightCondensed",
      fontColor: "#000",
      fontWeight: "300",
      fontSizes: {
        small: "1.25rem",
        medium: "1.25rem"
      }
    },
    chevronColor: "#C6C6C6",
    textTransform: "",
    borderColor: "#EDEDED"
  },
  titleAlign: "left"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillButtonCms.ts
var bonefishgrillButtonCmsStyle = {
  primary: {
    fontFamily: "DinNextLTPro-Regular",
    lineHeight: "1.75rem",
    backgroundColor: "#9e1d21",
    hover: {
      fontColor: "",
      backgroundColor: "#60181b",
      opacity: 1
    },
    fontWeight: "600",
    fontSizes: {
      medium: "1.5rem",
      large: "1rem",
      small: "1.6rem"
    },
    border: {
      color: "transparent",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#fff",
    padding: "0",
    paddingMobile: "",
    letterSpace: ""
  },
  secondary: {
    fontFamily: "DinNextLTPro-Regular",
    lineHeight: "1rem",
    fontSizes: {
      medium: "1.6rem",
      large: "1rem",
      small: "1.6rem"
    },
    hover: {
      fontColor: "",
      backgroundColor: "",
      opacity: 1
    },
    backgroundColor: "#fff",
    fontWeight: "600",
    border: {
      color: "#000",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#000",
    padding: "0",
    paddingMobile: "",
    letterSpace: ""
  },
  terciary: {
    fontColor: "#404041",
    fontFamily: "DinNextLTPro-Medium",
    fontSizes: {
      large: "1rem",
      medium: "1rem",
      small: "1rem",
      smallest: "1rem"
    },
    fontWeight: "500",
    backgroundColor: "#fff",
    hover: {
      backgroundColor: "#444",
      fontColor: "#fff",
      opacity: 1
    },
    border: {
      color: "#404041",
      radius: "6px",
      width: "1px",
      style: "solid"
    },
    letterSpace: "2.5px",
    padding: "20px",
    paddingMobile: "",
    lineHeight: "unset"
  },
  bodyBannerVariant: {
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      medium: "1.5rem",
      small: "0.875rem"
    },
    hover: {
      fontColor: "",
      backgroundColor: "",
      opacity: 1
    },
    backgroundColor: "#404041",
    fontWeight: "600",
    lineHeight: "1rem",
    border: {
      color: "#fff",
      radius: "",
      width: "",
      style: ""
    },
    fontColor: "#fff",
    padding: "16px",
    paddingMobile: "",
    letterSpace: ""
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCardSecondaryStyle.ts
var bonefishgrillCardSecondaryStyles = {
  title: {
    primary: {
      fontColor: "#232222",
      fontFamily: "DinNextLTPro-Regular",
      fontSizes: {
        medium: "1.5rem"
      },
      fontWeight: "500"
    },
    white: {
      fontColor: "#232222",
      fontFamily: "DinNextLTPro-Regular",
      fontSizes: {
        medium: "1.5rem"
      },
      fontWeight: "400"
    }
  },
  notes: {
    fontColor: "#232222",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      medium: "18px"
    },
    fontWeight: "400"
  },
  text: {
    primary: {
      fontColor: "#232222",
      fontFamily: "DinNextLTPro-Regular",
      fontSizes: {
        medium: "18px"
      },
      fontWeight: "400"
    },
    white: {
      fontColor: "#232222",
      fontFamily: "DinNextLTPro-Regular",
      fontSizes: {
        medium: "18px"
      },
      fontWeight: "400"
    }
  },
  button: {
    primary: {
      fontColor: "#2D2926",
      fontFamily: "DinNextLTPro-Regular",
      fontSizes: {
        medium: "1rem"
      },
      fontWeight: "500",
      hoverOpacity: "1",
      borderRadius: "6px",
      borderWidth: "2px",
      hoverColor: "#444444",
      hoverFontColor: "#FFFFFF",
      padding: "20px 20px",
      backgroundColor: "transparent"
    },
    white: {
      fontColor: "#232222",
      fontFamily: "DinNextLTPro-Regular",
      fontSizes: {
        medium: "1rem"
      },
      fontWeight: "400",
      hoverOpacity: "1",
      borderRadius: "6px",
      borderWidth: "2px",
      hoverColor: "#444444",
      hoverFontColor: "#FFFFFF",
      padding: "20px 20px",
      backgroundColor: "transparent"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCatalog.ts
var bonefishgrillCatalogStyle = {
  title: {
    fontColor: "#404041",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "2rem"
    },
    fontWeight: "400"
  },
  button: {
    fontColor: "#FFFFFF",
    backgroundColor: "#404041",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "1.25rem",
      medium: "1.5rem"
    },
    fontWeight: "400",
    hover: {
      backgroundColor: "",
      fontColor: "",
      opacity: 0
    },
    border: {
      width: "",
      radius: "",
      color: "",
      style: ""
    },
    padding: "",
    letterSpace: ""
  },
  backgroundColor: "none",
  maxWidthView: "",
  verticalPadding: "72px 66px",
  verticalPaddingMobile: "72px 24px",
  gap: "66px"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCatalogItem.ts
var bonefishgrillCatalogItemStyle = {
  borderRadius: "0px",
  title: {
    font: {
      fontFamily: "DinNextLTPro-Condensed",
      fontColor: "#444444",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "1.5rem",
        smallest: "1.5rem"
      }
    }
  },
  subtitle: {
    font: {
      fontFamily: "DinNextLTPro-Medium",
      fontColor: "#404041",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "1.25rem",
        small: "1rem",
        smallest: "0.75rem"
      }
    }
  },
  text: {
    font: {
      fontFamily: "DinNextLTPro-Regular",
      fontColor: "#444444",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    }
  },
  note: {
    font: {
      fontFamily: "DinNextLTPro-Regular",
      fontColor: "#444444",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    }
  },
  button: {
    backgroundColor: "#404041",
    hover: {
      backgroundColor: "#404041",
      fontColor: "#fff",
      opacity: 0.8
    },
    border: {
      width: "",
      radius: "unset",
      color: "transparent",
      style: ""
    },
    padding: "20px",
    letterSpace: "1px",
    fontColor: "#fff",
    fontFamily: "DinNextLTPro-Medium",
    fontWeight: "bold",
    fontSizes: {
      smallest: "1.25rem",
      small: "1rem",
      medium: "1.5rem",
      large: "1.25rem",
      largest: "1.25rem"
    },
    marginRight: ""
  },
  backgroundColor: {
    default: "#fff",
    verticalView: "#EAEAEA"
  },
  verticalView: {
    image: {
      padding: "0px 30px"
    },
    textAlign: "left"
  },
  margin: ""
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCatalogItemSecondary.ts
var bonefishgrillCatalogItemSecondaryStyle = {
  title: {
    font: {
      fontFamily: "DinNextLTPro-Condensed",
      fontColor: "#000",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "1.5rem",
        smallest: "1.5rem"
      }
    },
    textTransform: "",
    marginBottom: "16px"
  },
  subtitle: {
    font: {
      fontFamily: "DinNextLTPro-Regular",
      fontColor: "#000",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "3.5rem",
        small: "3rem",
        smallest: "2rem"
      }
    },
    lineHeightMobile: "44px",
    marginBottom: "16px"
  },
  text: {
    font: {
      fontFamily: "DinNextLTPro-Light",
      fontColor: "#000",
      fontWeight: "300"
    },
    fontSize: {
      fontSizes: {
        medium: "0.875rem;",
        small: "0.875rem;",
        smallest: "0.875rem;"
      }
    },
    marginBottom: "16px"
  },
  button: {
    font: {
      fontFamily: "DinNextLTPro-Condensed",
      fontColor: "#000",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.25rem",
        small: "1rem",
        smallest: "1rem"
      }
    },
    marginTop: "0px",
    marginTopMobile: "0px",
    padding: "0px 0px",
    marginInternal: "0 0 4px 8px",
    iconColor: ""
  },
  backgroundColor: "#EAEAEA",
  contentPaddingMobile: "",
  contentPadding: "",
  containerFlex: "1",
  alignMain: "flex-start",
  verticalView: {
    fontColor: "#444",
    title: {
      fontFamily: "DinNextLTPro-Condensed"
    },
    subtitle: {
      fontFamily: "DinNextLTPro-MediumCondensed"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillHero.ts
var bonefishgrillHero = {
  button: {
    primary: {
      fontColor: "#ffffff",
      fontWeight: "700",
      fontFamily: "DinNextLTPro-Regular, sans-serif",
      letterSpacing: "2.5px",
      fontSizes: {
        medium: "1rem"
      },
      borderRadius: "6px",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      hover: {
        opacity: 1,
        backgroundColor: "#ffffff",
        letterSpacing: "2.5px",
        font: {
          fontColor: "#444444",
          fontFamily: "DinNextLTPro-Regular, sans-serif",
          fontWeight: "400"
        }
      },
      borderWidth: "1px",
      lineHeight: "50px",
      padding: "0 40px"
    },
    secondary: {
      fontColor: "#ffffff",
      fontWeight: "700",
      fontFamily: "DinNextLTPro-Regular, sans-serif",
      letterSpacing: "2.5px",
      fontSizes: {
        medium: "1rem"
      },
      borderRadius: "6px",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      hover: {
        opacity: 1,
        backgroundColor: "#ffffff",
        letterSpacing: "2.5px",
        font: {
          fontColor: "#444444",
          fontFamily: "DinNextLTPro-Regular, sans-serif",
          fontWeight: "400"
        }
      },
      borderWidth: "1px",
      lineHeight: "50px",
      padding: "0 40px"
    }
  },
  overTitle: {
    fontColor: "#FFFFFF",
    fontFamily: "DinNextLTPro-Regular, sans-serif",
    fontWeight: "700",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    },
    letterSpacing: "1px",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.50);"
  },
  title: {
    fontColor: "#FFFFFF",
    fontFamily: "DinNextLTPro-Regular, sans-serif",
    fontWeight: "700",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.50)",
    fontSizes: {
      medium: "4rem",
      small: "3rem",
      smallest: "3rem"
    },
    textTransform: "none",
    lineHeight: {
      medium: "5.4rem",
      small: "3.25rem",
      smallest: "3.25rem"
    }
  },
  text: {
    fontColor: "#FFFFFF",
    fontFamily: "DinNextLTPro-Regular, sans-serif",
    fontWeight: "400",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    }
  },
  container: {
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillInputCms.ts
var bonefishgrillInputCmsStyle = {
  primary: {
    input: {
      fontColor: "#404041",
      fontFamily: "DinNextLTPro-Regular, sans-serif",
      fontWeight: "400",
      fontSizes: {
        medium: "1rem",
        large: "1rem",
        largest: "1rem"
      },
      lineHeights: {
        medium: "4.08rem",
        large: "4.36rem",
        largest: "4.63rem"
      },
      background: "#fff",
      placeholderFontColor: "#404041"
    },
    label: {
      fontColor: "transparent",
      fontFamily: "DinNextLTPro-Regular, sans-serif",
      fontWeight: "700",
      fontSizes: {
        medium: "0.875rem"
      },
      lineHeights: {
        medium: "1.2rem"
      },
      initialLabelColor: "transparent",
      finalLabelColor: "#232222"
    },
    error: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {
        small: "0.75rem",
        medium: "0.8rem",
        large: "0.85rem",
        largest: "0.9rem"
      },
      lineHeights: {
        small: "0.9rem",
        medium: "0.95rem",
        large: "1rem",
        largest: "1.07rem"
      }
    },
    borderColor: "#404041"
  },
  secondary: {
    input: {
      fontColor: "#a87139",
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: "1.21rem"
      },
      lineHeights: {
        medium: "1.2"
      },
      background: "",
      placeholderFontColor: "#404041"
    },
    label: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: ""
  },
  terciary: {
    input: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      background: "",
      placeholderFontColor: ""
    },
    label: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#e8dcc2"
  },
  modal: {
    input: {
      fontColor: "",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      background: "",
      placeholderFontColor: "#666666"
    },
    label: {
      fontColor: "transparent",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {},
      initialLabelColor: "",
      finalLabelColor: "",
      display: "none"
    },
    error: {
      fontColor: "#333",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#666666"
  },
  compact: {
    input: {
      fontColor: "#232222",
      fontFamily: "",
      fontWeight: "",
      fontSizes: { medium: "0.875rem" },
      lineHeights: { medium: "16.8px" },
      background: "",
      placeholderFontColor: ""
    },
    label: {
      fontColor: "#2D2926",
      fontFamily: "DinNextLTPro-Medium",
      fontWeight: "500",
      fontSizes: { medium: "0.75rem" },
      lineHeights: { medium: "14.4px" },
      initialLabelColor: "",
      finalLabelColor: ""
    },
    error: {
      fontColor: "inherit",
      fontFamily: "",
      fontWeight: "",
      fontSizes: {},
      lineHeights: {}
    },
    borderColor: "#767676"
  },
  height: "68px"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillJumbotron.ts
var bonefishgrillJumbotronStyles = {
  title: {
    fontColor: "#444",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontSizes: {
      medium: "2rem",
      small: ""
    },
    fontWeight: "500"
  },
  text: {
    fontColor: "#444",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      medium: "1rem",
      small: "1rem"
    },
    fontWeight: "400",
    lineHeight: { small: "", medium: "" },
    paddingBottom: ""
  },
  singleButton: {
    fontColor: "#404041",
    fontFamily: "DinNextLTPro-Medium",
    fontSizes: {
      small: "1rem",
      medium: "1rem"
    },
    fontWeight: "500",
    backgroundColor: "#fff",
    hover: {
      backgroundColor: "#444",
      fontColor: "#fff",
      opacity: 1
    },
    border: {
      color: "#404041",
      radius: "6px",
      width: "1px",
      style: "solid"
    },
    padding: "20px",
    letterSpace: "2.5px",
    lineHeight: ""
  },
  button: {
    fontColor: "#404041",
    fontFamily: "DinNextLTPro-Medium",
    fontSizes: {
      small: "1rem",
      medium: "1rem",
      large: "1rem"
    },
    fontWeight: "500",
    backgroundColor: "#fff",
    hover: {
      backgroundColor: "#444",
      fontColor: "#fff",
      opacity: 1
    },
    border: {
      color: "#404041",
      radius: "6px",
      width: "1px",
      style: "solid"
    },
    padding: "20px",
    letterSpace: "2.5px",
    paddingMobile: "20px"
  },
  notes: {
    fontColor: "#444",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      medium: "0.875rem"
    },
    fontWeight: "400"
  },
  padding: "30px",
  titlePadding: "16px 0px",
  paddingMobile: "40px 34px"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillLateralBar.ts
var bonefishgrillLateralBar = {
  backgroundColor: "#ffffff",
  font: {
    fontFamily: "DinNextLTPro-Regular",
    fontColor: "#444444",
    fontWeight: "500",
    fontSizes: {
      medium: "0.875rem"
    }
  },
  opacity: "",
  hoverColor: "#ffffff",
  hoverBackground: "#444444",
  border: "1px solid #000",
  borderRadius: "6px",
  padding: "16px"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillLegalPages.ts
var bonefishgrillLegalPagesStyle = {
  backgroundColor: "#ffffff",
  header: {
    title: {
      fontColor: "#666666",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    },
    subtitle: {
      fontColor: "#666666",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    }
  },
  mainContent: {
    subtitles: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "700",
      fontSizes: {
        medium: ""
      }
    },
    texts: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: ""
      }
    }
  },
  table: {
    backgroundColor: "#232222",
    borderColor: "#EAEAEA"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillLine.ts
var bonefishgrillLine = {
  text: {
    font: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.187rem",
        small: "1.187rem",
        smallest: "1.187rem"
      }
    }
  },
  borderTop: {
    Dotted: "3px dotted #ABABAB",
    Solid: "2px solid #AEB8C0"
  },
  backgroundColor: "transparent"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillNewsletterForm.ts
var bonefishgrillNewsletterForm = {
  background: "#F5F3EB",
  title: {
    fontSizes: {
      medium: "2.25rem"
    },
    fontFamily: "Gibson-Regular",
    fontWeight: "600",
    fontColor: "#232222"
  },
  agreement: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "1rem"
    }
  },
  button: {
    fontWeight: "bold",
    fontFamily: "Gibson-SemiBold",
    fontColor: "#404041",
    fontSizes: {
      smallest: "1.25rem",
      small: "1.45rem",
      medium: "1.6rem",
      large: "1.85rem",
      largest: "1.95rem"
    },
    backgroundColor: "#ffffff",
    borderColor: "transparent"
  },
  form: {
    fontFamily: "Gibson-Regular",
    fontColor: "#404041",
    fontWeight: ""
  },
  response: {
    fontFamily: "Gibson-SemiBold",
    fontColor: "#232222",
    fontWeight: "600",
    fontSizes: {
      small: "1.75rem",
      medium: "2rem",
      large: "2.25rem"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillPod.ts
var bonefishgrillPodStyle = {
  large: {
    title: {
      fontFamily: "GTWalsheim-Regular",
      fontSizes: {
        smallest: "1.75rem",
        small: "1.75rem",
        medium: "1.75rem",
        large: "2rem",
        largest: "2rem"
      },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "400",
      textShadow: "2px 0px 1px rgba(0, 0, 0, 0.60)"
    },
    boxShadow: "none",
    borders: "none",
    description: {
      fontFamily: "GTWalsheim-Regular",
      fontSizes: {
        small: "1rem",
        medium: "0.875rem",
        large: "0.875rem"
      },
      fontColor: { desktop: "#fff", mobile: "#404041" },
      fontWeight: "400",
      textTransform: "none"
    },
    button: {
      backgroundColor: { desktop: "#3E7A7B", mobile: "#3E7A7B" },
      font: {
        fontFamily: "DinNextLTPro-Regular",
        fontSizes: {
          smallest: "1rem",
          small: "1rem",
          medium: "1rem",
          large: "1rem",
          largest: "1rem"
        },
        fontColor: { desktop: "#fff", mobile: "#fff" },
        fontWeight: "500"
      },
      borderColor: { desktop: "none", mobile: "none" },
      hoverColor: { desktop: "#2B5556", mobile: "#2B5556", fontColor: "" },
      paddingBottom: {
        largest: "85px"
      }
    },
    padding: "",
    flexDirectionMobile: "column"
  },
  middle: {
    title: {
      fontFamily: "GTWalsheim-Regular",
      fontSizes: {},
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "400",
      paddingMobile: "16px",
      textShadow: "2px 0px 1px rgba(0, 0, 0, 0.60)"
    },
    borders: "none",
    description: {
      fontFamily: "GTWalsheim-Regular",
      fontSizes: {},
      fontColor: { desktop: "#fff", mobile: "#404041" },
      fontWeight: "400",
      textTransform: ""
    },
    button: {
      backgroundColor: { desktop: "#3E7A7B", mobile: "#3E7A7B" },
      font: {
        fontFamily: "DinNextLTPro-Regular",
        fontSizes: { smallest: "1rem", small: "1rem", medium: "1rem", large: "1rem", largest: "1rem" },
        fontColor: { desktop: "#fff", mobile: "#fff" },
        fontWeight: "500"
      },
      borderColor: { desktop: "#fff", mobile: "#9E1D21" },
      hoverColor: { desktop: "#2B5556", mobile: "#2B5556", fontColor: "" }
    },
    padding: "10px 24px;"
  },
  small: {
    title: {
      fontFamily: "GTWalsheim-Regular",
      fontSizes: {
        smallest: "1.75rem",
        small: "1.75rem",
        medium: "1.75rem",
        large: "2rem",
        largest: "2rem"
      },
      fontColor: { desktop: "#fff", mobile: "#000" },
      fontWeight: "400",
      paddingMobile: "16px",
      textShadow: "2px 0px 1px rgba(0, 0, 0, 0.60)"
    },
    borders: "none",
    description: {
      fontFamily: "GTWalsheim-Regular",
      fontSizes: { medium: "1rem" },
      fontColor: { desktop: "#fff", mobile: "#404041" },
      fontWeight: "400",
      textTransform: ""
    },
    button: {
      backgroundColor: { desktop: "#3E7A7B", mobile: "#3E7A7B" },
      font: {
        fontFamily: "DinNextLTPro-Regular",
        fontSizes: {
          smallest: "1rem",
          small: "1rem",
          medium: "1rem",
          large: "1rem",
          largest: "1rem"
        },
        fontColor: { desktop: "#fff", mobile: "#fff" },
        fontWeight: "500"
      },
      borderColor: { desktop: "transparent", mobile: "transparent" },
      hoverColor: { desktop: "#2B5556", mobile: "#2B5556", fontColor: "" }
    },
    padding: ""
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillQuestionsAndAnswersItem.ts
var bonefishgrillQuestionsAndAnswersItem = {
  name: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  questionAnswer: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1.125rem"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillQuestionsAndAnswers.ts
var bonefishgrillQuestionsAndAnswers = {
  title: {
    fontFamily: "Gibson-SemiBold",
    fontWeight: "600",
    fontColor: "#666666",
    fontSizes: {
      medium: "4rem",
      small: "1.5rem"
    }
  },
  text: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "2rem",
      small: "1.25rem"
    }
  },
  pagination: {
    fontFamily: "Gibson-Regular",
    fontWeight: "400",
    fontColor: "#666666",
    fontSizes: {
      medium: "1rem",
      small: "0.75rem"
    }
  },
  arrowColor: "#2c2c2b",
  paginationBorderColor: "#666666"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillRewardsModal.ts
var bonefishgrillRewardsModal = {
  backgroundColor: "#ffffff",
  closeIconColor: "#444",
  checkbox: {
    fontColor: "#404041",
    fontFamily: "DinNextLTPro-Regular",
    fontWeight: "500",
    fontSize: "14px",
    fontStyle: "italic",
    border: "2px solid #6666"
  },
  logoColor: "purple",
  button: {
    backgroundColor: "#ffffff",
    fontColor: "#404041",
    borderColor: "#404041",
    hoverColor: "#6666"
  },
  title: {
    fontColor: "#404041",
    fontFamily: "",
    fontSizes: {
      small: "1.5rem",
      medium: "1.5rem",
      large: "2.1rem"
    }
  },
  subtitle: {
    fontFamily: "",
    fontColor: "#404041"
  },
  image: {
    width: "95px",
    height: "80px"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillSeating.ts
var bonefishgrillSeating = {
  container: {
    background: "#ffffff",
    color: "#666666",
    h1: {
      fontFamily: "Gibson-SemiBold",
      fontWeight: ""
    }
  },
  location: {
    color: "#666666",
    div: {
      margin: "16px 0 0 4px"
    },
    span: {
      fontFamily: "Gibson-Regular",
      fontWeight: "Bold",
      textTransform: "uppercase",
      margin: "16px 0 0 4px",
      fontSizes: {
        medium: "1.4rem"
      }
    }
  },
  button: {
    background: "#404041",
    margin: "29px 0 0",
    border: "none",
    hover: {
      opacity: "0.6"
    },
    fontFamily: "",
    fontWeight: "Bold",
    secondary: { background: "transparent", backgroundColor: "#931c20", border: "2px solid #931c20" }
  },
  info: {
    fontFamily: "",
    a: {
      hover: {
        color: "",
        opacity: "0.6"
      }
    }
  },
  guestInfo: {
    margin: "",
    border: "2px solid #404041",
    color: "#666666",
    height: "68px"
  },
  modalContent: {
    background: "#ffffff",
    fontFamily: {
      container: "",
      content: ""
    }
  },
  modalClose: {
    color: "#931c20"
  },
  successContainer: {
    background: "",
    backgroundColor: "#404041",
    minHeight: "calc(100vh - 242px)"
  },
  successContent: {
    textTransform: "",
    h1: {
      fontFamily: "Gibson-SemiBold",
      textTransform: "uppercase",
      fontSizes: {
        medium: "40px"
      }
    },
    h2: {
      fontFamily: "Gibson-Regular",
      lineHeight: "38px",
      lineWeight: "400",
      fontWeight: "400",
      marginTop: "24px",
      fontSizes: {
        medium: "24px"
      }
    },
    p: {
      fontWeight: "",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        medium: "24px"
      },
      lineHeight: "38px",
      margin: "24px 0 0"
    },
    button: {
      background: "#fff",
      borderColor: "#fff",
      color: "#404041",
      fontFamily: "Gibson-SemiBold",
      lineHeight: "28px",
      fontSizes: {
        medium: "24px"
      },
      hover: {
        opacity: "0.6",
        background: "",
        color: ""
      }
    }
  },
  item: {
    fontWeight: "",
    fontFamily: "",
    borderBottom: "",
    p: {
      a: {
        textDecoration: "",
        hover: {
          color: "",
          opacity: "0.6"
        }
      }
    }
  },
  detailsButton: {
    p: {
      padding: "10px 24px",
      alignCenter: "center",
      margin: "",
      fontFamily: "",
      mediaQuery: {
        viewport768px: {
          padding: "5px 24px"
        }
      },
      hover: {
        opacity: "0.6",
        color: ""
      }
    }
  },
  inline: {
    fontFamily: ""
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillSectionBanner.ts
var bonefishgrillSectionBanner = {
  backgroundColor: "#ffff",
  fontColor: "#000",
  aside: {
    fontColor: "",
    fontFamily: "jenna-sue, sans-serif",
    fontWeight: "",
    fontSizes: {
      small: "2.2rem",
      medium: "2.4rem"
    }
  },
  image: {
    desktop: {
      height: "600px",
      width: "auto"
    },
    mobile: {
      height: "unset",
      width: "80%"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillTabNavigationItem.ts
var bonefishgrillTabNavigationItem = {
  backgroundColorInactive: "#EAEAEA",
  backgroundColorActive: "#3E7A7B",
  separatorColor: "#444",
  link: {
    font: {
      fontColor: "#121211",
      fontFamily: "DinNextLTPro-Medium",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "1.5rem",
        small: "0.75rem"
      }
    }
  },
  linkActiveColor: "#ffffff",
  underlineColor: {
    inactive: {
      zeroOpacity: "rgba(64, 64, 65, 0)",
      fullOpacity: "rgba(64, 64, 65, 1)"
    },
    active: {
      zeroOpacity: "rgba(255, 255, 255, 0)",
      fullOpacity: "rgba(255, 255, 255, 1)"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillVideoTextButton.ts
var bonefishgrillVideoTextButton = {
  title: {
    fontColor: "#666666",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      medium: "2rem",
      small: "1.5rem"
    },
    fontWeight: "600"
  },
  text: {
    fontColor: "#666666",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1.25rem"
    },
    fontWeight: "400"
  },
  note: {
    fontColor: "#666666",
    fontFamily: "Gibson-Regular",
    fontSizes: {
      medium: "1.25rem"
    },
    fontWeight: "400"
  },
  button: {
    fontColor: "#ffffff",
    fontFamily: "Gibson-SemiBold",
    fontSizes: {
      small: "0.875rem",
      medium: "1.5rem"
    },
    fontWeight: "600",
    backgroundColor: "#404041"
  },
  lineColor: "#666666"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillContainer.ts
var bonefishgrillContainerStyle = {
  background: "none"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishCmsDropdown.ts
var bonefishCmsDropdownStyle = {
  label: {
    textTransform: "",
    fontFamily: "",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  wrapper: {
    border: "1px solid #fff",
    fontFamily: "",
    fontWeight: "bold"
  },
  chevron: {
    margin: "auto 16px"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishTopMessage.ts
var bonefishTopMessage = {
  backgroundColor: "#3E7A7B",
  arrowColor: "#ffffff",
  buttonColor: "#ffffff",
  text: {
    fontColor: "#ffffff",
    fontFamily: "DinNextLTPro-Regular",
    fontWeight: "400",
    fontSizes: {
      small: "12px",
      medium: "14px",
      large: "18px"
    },
    paddingTop: "3px"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishCarousel.ts
var bonefishgrillCarouselStyle = {
  arrows: {
    height: "calc(100% - 48px)"
  },
  dots: {
    iconPlayAndPause: {
      background: "transparent",
      color: "#3E7A7B"
    },
    background: "#ffffff",
    backgroundMobile: "#ededed",
    width: "32px",
    mobileWidth: "24px",
    height: "8px",
    radius: "10px"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillDRForm.ts
var bonefishgrillDRFormStyle = {
  button: {
    fontColor: "#404041",
    backgroundColor: "#FFFFFF",
    border: "2px solid",
    fontWeight: "600",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      small: "1rem",
      medium: "1rem",
      large: "1rem;"
    },
    opacity: "1",
    hoverColor: "#444444",
    hoverFontColor: "#FFFFFF",
    borderRadius: "6px",
    hoverFontColorModal: "#FFFFFF",
    hoverColorModal: "#444444",
    letterSpacing: "2.5px"
  },
  texts: {
    title: {
      fontColor: "#232222",
      fontSizes: {
        small: "1.75rem",
        medium: "2rem",
        large: "2.25rem"
      },
      fontFamily: "DinNextLTPro-MediumCondensed",
      fontWeight: "500"
    },
    description: {
      fontColor: "#232222",
      fontSizes: { medium: "1rem" },
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "400"
    },
    label: {
      fontColor: "#404041",
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "600"
    }
  },
  backgroundColor: "transparent",
  backgroundImage: "",
  backgroundModalImage: "",
  borders: "#404041"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillDrawer.ts
var bonefishgrillDrawerStyle = {
  background: "#f5f5f5"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillHeader.ts
var bonefishgrillHeaderStyle = {
  image: {
    width: "130px",
    height: "auto"
  },
  title: {
    fontSizes: {
      small: "1rem",
      medium: "1rem"
    }
  },
  subTitle: {
    fontSizes: {
      small: "14px",
      medium: "18px"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillFooter.ts
var bonefishgrillFooterStyle = {
  letterSpacing: "0px",
  borderTop: "",
  backgroundColor: "#2d2926",
  navbar: {
    marginBottom: "10px",
    color: "#FFFFFF",
    featuredItems: {
      fontFamily: "DinNextLTPro-Medium,sans-serif",
      menuItem: {
        fontSizes: {
          smallest: "16px",
          small: "16px",
          medium: "16px",
          large: "16px",
          largest: "16px"
        }
      }
    },
    unfeaturedItems: {
      fontFamily: "DinNextLTPro-Light,sans-serif",
      menuItem: {
        fontSizes: {
          smallest: "14px",
          small: "14px",
          medium: "14px",
          large: "14px",
          largest: "14px"
        }
      }
    }
  },
  socialMediaPanel: {
    fontFamily: "DinNextLTPro-Regular,sans-serif",
    fontSizes: {
      medium: "14px",
      large: "14px",
      largest: "14px"
    }
  },
  cookiesBar: {
    backgroundColor: "#2D2926",
    fontFamily: "DinNextLTPro-Regular, sans-serif"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillNavBar.ts
var bonefishgrillNavBarStyle = {
  primary: {
    fontFamily: "DinNextLTPro-Regular, sans-serif",
    fontWeight: "bold",
    fontColor: "",
    fontSizes: {
      default: "1.5rem"
    },
    color: "#ffffff",
    backgroundColor: ""
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillAnimatedCard.ts
var bonefishgrillAnimatedCard = {
  title: {
    textAlign: "left",
    textTranform: "none",
    fontColor: "#000",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontWeight: "500",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  text: {
    textAlign: "left",
    fontColor: "#444",
    fontFamily: "DinNextLTPro-Regular",
    fontWeight: "400",
    fontSizes: {
      medium: "0.8rem"
    }
  },
  linkText: {
    textAlign: "center",
    fontColor: "#ffffff",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontWeight: "500",
    fontSizes: {
      medium: "2.5rem"
    },
    lineHeight: "2.75rem"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillBodyBanner.ts
var bonefishgrillBodyBannerStyle = {
  padding: {
    desktop: "72px 56px",
    mobile: "40px 24px"
  },
  content: {
    marginLeft: {
      desktop: "0",
      mobile: "0"
    },
    gap: {
      desktop: "56px",
      mobile: "32px"
    }
  },
  main: {
    marginLeft: {
      desktop: "0",
      mobile: "0"
    },
    gap: {
      desktop: "24px",
      mobile: "16px"
    }
  },
  backgroundColor: "",
  overtitle: {
    fontFamily: "DinNextLTPro-Condensed",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: {}
  },
  text: {
    fontFamily: "DinNextLTPro-Condensed",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: { medium: "24px", small: "24px" }
  },
  title: {
    fontFamily: "DinNextLTPro-Condensed",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: { medium: "56px", small: "40px" }
  },
  subtitle: {
    fontFamily: "DinNextLTPro-Condensed",
    fontColor: "#fff",
    fontWeight: "normal",
    fontSizes: { medium: "1.5rem", small: "5vw" }
  },
  nav: {
    fontFamily: "DinNextLTPro-Condensed",
    fontColor: "#2D2926",
    fontWeight: "normal",
    fontSizes: { medium: "1rem", small: "0.8rem" },
    justifyContent: "none",
    marginTop: {
      desktop: "0",
      mobile: "12px"
    },
    paddingRight: {
      desktop: "80px",
      mobile: "0"
    }
  },
  button: {
    fontColor: "",
    fontFamily: "",
    fontSizes: { medium: "", small: "" },
    fontWeight: "",
    hover: { fontColor: "", backgroundColor: "", borderColor: "" }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillAnimatedHero.ts
var bonefishgrillAnimatedHeroStyle = {
  title: {
    fontColor: "#232222 ",
    fontFamily: "GTWalsheim-Regular",
    fontSizes: { medium: "clamp(2.5rem,6vw,4rem)" },
    fontWeight: "500"
  },
  text: {
    fontColor: "",
    fontFamily: "GTWalsheim-Regular",
    fontSizes: {},
    fontWeight: ""
  },
  imageBannerMargin: "0 0 60px 0"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillMenuNavigation.ts
var bonefishgrillMenuNavigation = {
  backgroundColorInactive: "#eaeaea",
  backgroundColorActive: "#3E7A7B",
  separatorColor: "#aeb8c0",
  link: {
    font: {
      fontColor: "#232222",
      fontFamily: "DinNextLTPro-Medium",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "1.125rem"
      }
    },
    lineHeight: "20px"
  },
  linkActiveColor: "#ffffff",
  underlineColor: {
    inactive: {
      zeroOpacity: "rgba(35, 34, 34, 0)",
      fullOpacity: "rgba(35, 34, 34, 1)"
    },
    active: {
      zeroOpacity: "rgba(255, 255, 255, 0)",
      fullOpacity: "rgba(255, 255, 255, 1)"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishGrillMenuItem.ts
var bonefishgrillMenuItem = {
  fontColor: "#444444",
  newMessageColor: "#B00020",
  title: {
    fontFamily: "DinNextLTPro-Condensed",
    fontWeight: "400",
    fontColor: "#444444",
    fontSizes: {
      medium: "1.5rem"
    }
  },
  subTitle: {
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontWeight: "500",
    fontColor: "#444444",
    fontSizes: {
      medium: "1rem"
    }
  },
  description: {
    fontFamily: "DinNextLTPro-Regular",
    fontWeight: "400",
    fontColor: "#444444",
    fontSizes: {
      medium: "1rem"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillReservations.ts
var bonefishgrillReservations = {
  floatingLabelColor: "#FFFFFF"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillDeliveryContent.ts
var bonefishgrillDeliveryContentStyle = {
  headerTitle: {
    fontFamily: "Roboto-Bold, sans-serif",
    fontSizes: {
      large: "20px"
    },
    fontColor: "#121211",
    fontWeight: "600"
  },
  inputLabel: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  selectAddressButton: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "700"
  },
  mainText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "700"
  },
  subText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  headerButtonsColor: "#2C2C2B",
  backgroundColor: "#f6f2e8"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillDrawerMessage.ts
var bonefishgrillDrawerMessageStyle = {
  mainText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "700"
  },
  subText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  color: "#D5D0C3",
  backgroundColor: "#F6F2E8"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillVideo.ts
var bonefishgrillVideo = {
  container: {
    fontFamily: "DinNextLTPro-Condensed",
    fontWeight: "500",
    fontColor: "",
    textAlign: "center",
    padding: "0 0 40px 0",
    light: {
      fontColor: "#2c2c2b",
      backgroundColor: "transparent"
    },
    dark: {
      fontColor: "#2c2c2b",
      backgroundColor: "transparent"
    }
  },
  title: {
    fontSizes: {
      medium: "3rem"
    },
    textAlignMobile: ""
  },
  text: {
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      small: "1rem"
    },
    textAlignMobile: ""
  },
  play: {
    fontColor: "#ffffff"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillBanner.ts
var bonefishgrillBannerStyle = {
  padding: "20px 0",
  paddingMobile: "20px 0",
  title: {
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontColor: "#000",
    fontWeight: "700",
    fontSizes: { medium: "56px", small: "30px" }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCategoryItem.ts
var bonefishgrillCategoryItemStyle = {
  container: {
    backgroundImage: "",
    color: "#2C2C2B",
    padding: ""
  },
  content: {
    backgroundColor: "#EAEAEA",
    borderRadius: "0 0.25rem 0.25rem 0"
  },
  name: {
    fontFamily: "DIN Next LT Pro, sans-serif",
    fontSize: {
      fontSizes: {
        medium: "1.125rem",
        small: "0.875rem"
      }
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillProductMenu.ts
var SITECORE_IMAGES_URL7 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/bonefishgrill/Order`;
var bonefishgrillProductMenu = {
  backgroundColor: "#f6f2e8",
  itemTitle: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "16px"
    }
  },
  itemSubTitle: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "500",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "14px"
    }
  },
  containerTitle: {
    fontFamily: "Veneer, sans-serif",
    fontWeight: "500",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "32px"
    },
    textTransform: "none"
  },
  productImagePlaceholder: `${SITECORE_IMAGES_URL7}/bonefishgrill-product-placeholder.webp`,
  detailsTitle: {
    fontFamily: "Veneer, sans-serif",
    fontWeight: "500",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "32px"
    },
    textTransform: ""
  },
  detailsSubtitle: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "20px"
    }
  },
  detailsDescription: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "20px"
    }
  },
  nutritionalDescription: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      medium: "20px",
      small: "12px"
    }
  },
  detailsCalories: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      medium: "16px"
    }
  },
  detailsMoreNutritionalInfo: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      medium: "16px"
    }
  },
  detailsTag: {
    fontFamily: "IntroRust, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      small: "12px",
      medium: "16px"
    }
  },
  detailsReturnButton: {
    fontFamily: "TradeGothicLTPro, sans-serif"
  },
  highlightTag: {
    fontFamily: "BigBloke, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      small: "12px",
      medium: "16px"
    }
  },
  restrictionTag: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: "700",
    fontColor: "#121211",
    fontSizes: {
      smallest: "10px",
      small: "12px",
      medium: "14px"
    }
  },
  highlightTagBackground: "#891A1C",
  detailsBackgroundColor: "#F6F2E8",
  relatedProducts: {
    title: {
      fontFamily: "Gibson-Regular",
      fontWeight: "600",
      fontColor: "#404041",
      fontSizes: {
        medium: "16px",
        small: "14px"
      }
    },
    subtitle: {
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontColor: "#232222",
      fontSizes: {
        medium: "14px",
        small: "12px"
      }
    }
  },
  descriptionCategory: {
    fontFamily: "Gibson, sans-serif",
    backgroundColor: "#f5f3eb",
    lineHeight: "12px",
    fontColor: "#404041"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillDineRewardsForm.ts
var bonefishgrillDineRewardsForm = {
  banner: {
    container: {
      maxWidth: "1393px"
    },
    titleContainer: {
      font: {
        fontColor: "#2d2926",
        fontFamily: "DinNextLTPro-MediumCondensed",
        fontWeight: "500"
      },
      textTransform: "uppercase"
    },
    title: {
      fontSize: { fontSizes: { medium: "2rem", small: "2rem" } },
      lineHeight: { desktop: "38.4px", mobile: "38.4px" },
      content: "Want A FREE $10 reward?"
    },
    subtitle: {
      fontSize: { fontSizes: { medium: "1.5rem", small: "1rem" } },
      lineHeight: { desktop: "28.8px", mobile: "19.2px" },
      content: "Sign up for dine rewards now!"
    },
    backgroundColor: "#eaeaea",
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "DinNextLTPro-Medium", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "1rem", small: "1rem" } },
      lineHeight: { desktop: "19.2px", mobile: "19.2px" },
      backgroundColor: "rgb(62, 122, 123)",
      hoverbackgroundColor: "rgb(43, 85, 86)",
      content: "I want my free $10 reward",
      border: "0",
      hoverBannerFontColor: ""
    },
    mobile: {
      padding: {
        desktopBreakpoint: "24px 46px 24px 71px",
        desktopLargeBreakpoint: "24px 46px 24px 114px"
      }
    },
    imageUrl: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/bonefishgrill/Dine-Rewards-Form/DR-form-bonefish-banner.webp"
  },
  compact: {
    title: {
      font: { fontColor: "#2d2926", fontFamily: "DinNextLTPro-MediumCondensed", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "1.5rem", small: "1.5rem" } },
      lineHeight: { desktop: "28.8px", mobile: "28.8px" },
      firstContent: "Want A FREE $10 reward?",
      secondContent: "Sign up for dine rewards now!",
      textTransform: "uppercase"
    },
    backgroundColor: "#eaeaea",
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "DinNextLTPro-Medium", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "1rem", small: "1rem" } },
      lineHeight: { desktop: "19.2px", mobile: "19.2px" },
      backgroundColor: "rgb(62, 122, 123)",
      hoverbackgroundColor: "rgb(43, 85, 86)",
      content: "I want my free $10 reward",
      border: "0",
      hoverBannerFontColor: ""
    },
    modal: {
      title: {
        font: { fontColor: "#404041", fontFamily: "GTWalsheim-Regular", fontWeight: "700" },
        fontSize: { fontSizes: { medium: "1rem" } },
        lineHeight: { desktop: "18.32px", mobile: "18.32px" },
        content: "JOIN DINE REWARDS & GET $10 OFF YOUR FIRST VISIT"
      },
      description: {
        font: { fontColor: "#2d2926", fontFamily: "", fontWeight: "" },
        fontSize: { fontSizes: { medium: "14px" } },
        lineHeight: { desktop: "16.8px", mobile: "16.8px" },
        content: `When you join our Dine Rewards program, you'll earn a complimentary $10 reward today and
				points with every future purchase.`,
        infoContent: {
          fontFamily: ""
        }
      },
      icon: { color: "#3E7A7B", hoverColor: "rgb(43, 85, 86)" },
      logo: {
        url: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/shared/Dine-Rewards/bonefish-logo-black.svg",
        width: "104px"
      },
      backgroundColor: "#F6F6F6",
      labelColor: "#2D2926"
    }
  },
  form: {
    information: {
      font: { fontColor: "#2d2926", fontFamily: "", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "0.75rem", small: "0.75rem" } },
      lineHeight: { desktop: "14.4px", mobile: "14.4px" },
      iconColor: "#2D2926",
      fontColorCompact: "#2D2926",
      iconColorCompact: "#2D2926"
    },
    button: {
      font: { fontColor: "#FFFFFF", fontFamily: "DinNextLTPro-Medium", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "1rem", small: "1rem" } },
      lineHeight: { desktop: "19.2px", mobile: "19.2px" },
      backgroundColor: {
        banner: "rgb(62, 122, 123)",
        compact: "rgb(62, 122, 123)"
      },
      hoverbackgroundColor: {
        banner: "rgb(43, 85, 86)",
        compact: "rgb(43, 85, 86)"
      },
      content: "JOIN NOW",
      border: {
        banner: "0",
        compact: "0"
      },
      hoverBannerFontColor: ""
    },
    description: {
      font: { fontColor: " #232222", fontFamily: "", fontWeight: "400" },
      fontSize: { fontSizes: { medium: "0.75rem", small: "0.75rem" } },
      lineHeight: { desktop: "14.4px", mobile: "14.4px" },
      brand: "Bonefish Grill",
      fontColorCompact: "#232222"
    }
  },
  feature: {
    description: {
      font: { fontColor: "#2d2926", fontFamily: "DinNextLTPro-Medium", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "1rem", small: "1rem" } },
      lineHeight: { desktop: "19.2px", mobile: "19.2px" }
    },
    icon: {
      color: "#9b9b9b",
      iconSize: {
        width: "24px",
        height: "24px",
        minWidth: "24px",
        minHeight: "24px"
      }
    }
  },
  successCard: {
    title: {
      font: {
        fontColor: "#2d2926",
        fontFamily: "DinNextLTPro-MediumCondensed",
        fontWeight: "500"
      },
      textTransform: "uppercase",
      fontSize: { fontSizes: { medium: "2rem", small: "2rem" } },
      lineHeight: "38.4px"
    },
    description: {
      font: { fontColor: "#2d2926", fontFamily: "DinNextLTPro-Medium", fontWeight: "500" },
      fontSize: { fontSizes: { medium: "1rem" } },
      lineHeight: "19.2px"
    },
    banner: {
      fontColor: "#2d2926",
      button: {
        font: { fontColor: "#FFFFFF", fontFamily: "DinNextLTPro-Medium", fontWeight: "500" },
        fontSize: { fontSizes: { medium: "1rem", small: "1rem" } },
        lineHeight: "19.2px",
        backgroundColor: "rgb(62, 122, 123)",
        hoverbackgroundColor: "rgb(43, 85, 86)",
        border: "0",
        hoverBannerFontColor: ""
      }
    },
    compact: {
      fontColor: "#404041",
      button: {
        backgroundColor: "transparent",
        hoverbackgroundColor: "#404041",
        fontColor: "#404041",
        hoverFontColor: "#FFFFFF",
        borderRadius: "6px",
        border: "2px solid"
      }
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCardList.ts
var bonefishgrillCardListStyle = {
  selectedColor: "#891A1C",
  backgroundColor: "#F6F2E8",
  borderColor: "#D3C6B3",
  restaurantHoursBackgroundColor: "#E6DDCF",
  iconLocationColor: "#891A1C",
  emptyText: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#121211",
    fontWeight: "700"
  },
  restaurantName: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "16px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "600"
  },
  restaurantDistance: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  restaurantAddress: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#2C2C2B",
    fontWeight: "400"
  },
  restaurantHours: {
    fontFamily: "Roboto, sans-serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#121211",
    fontWeight: "400"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillLocationDisplay.ts
var bonefishgrillLocationDisplayStyle = {
  container: {
    backgroundColor: "#EAEAEA",
    borderColor: "#AEB8C0",
    color: "#FFFFFF",
    fontFamily: "DIN Alternate, sans-serif"
  },
  boldFontFamily: "DIN Alternate, sans-serif"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCurbsideSearchContent.ts
var bonefishgrillCurbsideSearchContentStyle = {
  headerTitle: {
    fontFamily: "DIN Next LT Pro, sans-serif",
    fontSizes: {
      large: "20px"
    },
    fontColor: "#232222",
    fontWeight: "400"
  },
  inputLabel: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#232222",
    fontWeight: "400"
  },
  selectLocationButton: {
    fontFamily: "DIN Next LT Pro, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "500"
  },
  useMyLocation: {
    fontFamily: "DIN Next LT Pro, sans serif",
    fontSizes: {
      medium: "12px"
    },
    fontColor: "#3E7A7B",
    fontWeight: "500"
  },
  toast: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      medium: "14px"
    },
    fontColor: "#FFFFFF",
    fontWeight: "400"
  },
  headerButtonsColor: "#232222",
  backgroundColor: "#F9F9F9"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillPriceTag.ts
var bonefishPriceTag = {
  cost: {
    fontFamily: "Roboto-Condensed-Bold, sans-serif",
    fontWeight: "700",
    fontColor: "#2C2C2B",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  },
  name: {
    fontFamily: "Roboto-Condensed-Regular, sans-serif",
    fontWeight: "400",
    fontColor: "#232222",
    fontSizes: {
      small: "16px",
      medium: "20px"
    }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillSeePrice.ts
var bonefishgrillSeePrice = {
  seePriceText: {
    fontFamily: "DIN Next LT Pro, sans-serif",
    fontWeight: "700",
    fontColor: "#2D2926",
    fontSizes: {
      small: "14px"
    }
  },
  backgroundColor: "#F5F3EB"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillChooseHandoffMethod.ts
var bonefishgrillChooseHandoffMethodStyle = {
  backgroundColor: "#f6f2e8",
  header: {
    fontColor: "#121211",
    fontFamily: "Roboto-Bold",
    fontWeight: "600",
    fontSizes: {
      medium: "20px"
    }
  },
  button: {
    border: "2px solid #2c2c2b",
    fontColor: "#2c2c2b",
    fontFamily: "Veneer, sans-serif",
    fontWeight: "400",
    fontSizes: {
      medium: "24px"
    },
    textTransform: "none"
  },
  disableButton: {
    borderColor: "#d4d4d4",
    backgroundColor: "#d4d4d4",
    color: "white"
  },
  selectedButton: {
    backgroundColor: "#891a1c",
    borderColor: "#891a1c",
    color: "white"
  },
  icon: { color: "#2c2c2b" },
  bottomButton: {
    fontColor: "#891a1c",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: { medium: "14px" }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillConfirmLocation.ts
var bonefishgrillConfirmLocationStyle = {
  backgroundColor: "#f6f2e8",
  header: {
    fontColor: "#121211",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "20px"
    }
  },
  title: {
    fontColor: "#2c2c2b",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "600",
    fontSizes: {
      medium: "18px"
    }
  },
  icon: {
    color: "#2c2c2b"
  },
  bottomButton: {
    fontColor: "#891a1c",
    fontFamily: "Roboto-Bold, sans-serif",
    fontWeight: "700",
    fontSizes: { medium: "14px" }
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishAlternativeMenuItem.ts
var bonefishAlternativeMenuItemStyle = {
  title: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      large: "18px"
    },
    fontColor: "#232222",
    fontWeight: "400"
  },
  icon: {
    color: "#232222",
    size: "36px"
  },
  containerBorderColor: "#3E7A7B",
  backgroundColor: ""
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillRelatedProducts.ts
var bonefishgrillRelatedProductsStyle = {
  title: {
    fontColor: "",
    fontFamily: "",
    fontSizes: {
      medium: ""
    },
    fontWeight: "",
    textTransform: ""
  },
  background: ""
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillCardSlider.ts
var bonefishgrillCardSliderStyle = {
  arrow: {
    color: "",
    hover: ""
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishgrillSideOptions.ts
var bonefishgrillSideOptionsStyle = {
  title: {
    fontColor: "#FFFFFF",
    boldColor: "#9E1E22",
    fontFamily: "Veneer, sans-serif",
    fontSizes: {
      medium: "36px",
      small: "32px"
    },
    fontWeight: "400",
    textTransform: "uppercase"
  },
  fontFamily: "Roboto, sans-serif",
  backgroundColor: "#2C2C2B"
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishProductTag.ts
var bonefishProductTag = {
  clipPathRight: {
    content: "polygon(0 0, 100% 0, calc(100% - 18px) 100%, 0% 100%)",
    before: ""
  },
  showClipPathLeft: false,
  left: "0px",
  top: "0px",
  borderTopLeft: "2px",
  smallTag: {
    fontSize: "12px",
    lineHeight: "12px",
    left: "-9px",
    content: {
      padding: "4px 26px 4px 8px"
    }
  },
  largeTag: {
    fontSize: "16px",
    lineHeight: "16px",
    left: "-13px",
    content: {
      padding: "4px 28px 4px 8px"
    }
  },
  fontWeight: 600,
  textTransform: "none",
  height: {
    large: "24px",
    small: "20px"
  },
  icon: {
    widthDesktop: "16px",
    heightDesktop: "16px",
    widthMobile: "12px",
    heightMobile: "12px"
  }
};

// src/bbi/bonefishgrill/bonefishgrillComponentsStyle/bonefishOptionCard.ts
var SITECORE_IMAGES_URL8 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/bonefishgrill/Order`;
var bonefishgrillOptionCardStyle = {
  title: {
    fontFamily: "Gibson, sans-serif",
    fontSizes: {
      small: "14px",
      medium: "16px",
      large: "18px"
    },
    fontColor: "##232222",
    fontWeight: "400"
  },
  backgroundColor: "#F5F3EB",
  optionImagePlaceHolder: `${SITECORE_IMAGES_URL8}/bonefishgrill-product-placeholder.webp`,
  defaultImageBackground: ""
};

// src/bbi/bonefishgrill/bonefishgrillUITheme.ts
var BonefishgrillUITheme = {
  accordion: bonefishgrillAccordion,
  buttonCMS: bonefishgrillButtonCmsStyle,
  carousel: bonefishgrillCarouselStyle,
  container: bonefishgrillContainerStyle,
  footer: bonefishgrillFooterStyle,
  navBar: bonefishgrillNavBarStyle,
  topMessage: bonefishTopMessage,
  header: bonefishgrillHeaderStyle,
  pod: bonefishgrillPodStyle,
  forms: bonefishgrillDRFormStyle,
  hero: bonefishgrillHero,
  animatedCard: bonefishgrillAnimatedCard,
  menuItem: bonefishgrillMenuItem,
  reservations: bonefishgrillReservations,
  deliveryContent: bonefishgrillDeliveryContentStyle,
  drawerMessage: bonefishgrillDrawerMessageStyle,
  video: bonefishgrillVideo,
  banner: bonefishgrillBannerStyle,
  productMenu: bonefishgrillProductMenu,
  cardList: bonefishgrillCardListStyle,
  dineRewardsForm: bonefishgrillDineRewardsForm,
  curbsideSearchContent: bonefishgrillCurbsideSearchContentStyle,
  priceTag: bonefishPriceTag,
  seePrice: bonefishgrillSeePrice,
  alternativeMenuItem: bonefishAlternativeMenuItemStyle,
  optionCard: bonefishgrillOptionCardStyle,
  productTag: bonefishProductTag,
  animatedCardSecondary: {
    title: {
      fontColor: "#404041",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: "2rem"
      }
    },
    text: {
      fontColor: "#666666",
      fontFamily: "Gibson-Regular",
      fontWeight: "400",
      fontSizes: {
        medium: "1rem"
      }
    },
    button: {
      fontColor: "#ffffff",
      fontFamily: "Gibson-SemiBold",
      fontWeight: "600",
      fontSizes: {
        medium: "0.875rem",
        large: "1.3rem"
      },
      backgroundColor: "#404041"
    }
  },
  catalogItem: bonefishgrillCatalogItemStyle,
  catalogItemSecondary: bonefishgrillCatalogItemSecondaryStyle,
  textButtonBanner: {
    title: {
      fontColor: "#ffffff",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem",
        large: "1.5rem"
      },
      fontWeight: "600"
    },
    button: {
      fontColor: "#404041",
      fontFamily: "Gibson-SemiBold",
      fontSizes: {
        small: "1rem",
        medium: "1.25rem",
        large: "1.5rem"
      },
      fontWeight: "600",
      backgroundColor: "#ffffff",
      hover: {
        backgroundColor: "",
        opacity: ""
      }
    }
  },
  grid: {
    maxWidth: "900px",
    margin: "auto"
  },
  cardSecondary: bonefishgrillCardSecondaryStyles,
  catalog: bonefishgrillCatalogStyle,
  drawer: bonefishgrillDrawerStyle,
  inputCms: bonefishgrillInputCmsStyle,
  jumbotron: bonefishgrillJumbotronStyles,
  line: bonefishgrillLine,
  lateralBar: bonefishgrillLateralBar,
  legalPages: bonefishgrillLegalPagesStyle,
  newsletterForm: bonefishgrillNewsletterForm,
  questionsAndAnswersItem: bonefishgrillQuestionsAndAnswersItem,
  questionsAndAnswers: bonefishgrillQuestionsAndAnswers,
  rewardsModal: bonefishgrillRewardsModal,
  seating: bonefishgrillSeating,
  tabNavigationItem: bonefishgrillTabNavigationItem,
  videoTextButton: bonefishgrillVideoTextButton,
  dropdownCms: bonefishCmsDropdownStyle,
  sectionBanner: bonefishgrillSectionBanner,
  bodyBanner: bonefishgrillBodyBannerStyle,
  animatedHero: bonefishgrillAnimatedHeroStyle,
  menuNavigation: bonefishgrillMenuNavigation,
  categoryItem: bonefishgrillCategoryItemStyle,
  locationDisplay: bonefishgrillLocationDisplayStyle,
  chooseHandoffMethod: bonefishgrillChooseHandoffMethodStyle,
  confirmLocation: bonefishgrillConfirmLocationStyle,
  relatedProducts: bonefishgrillRelatedProductsStyle,
  cardSlider: bonefishgrillCardSliderStyle,
  sideOptions: bonefishgrillSideOptionsStyle
};

// src/bbi/bonefishgrill/bonefishgrillOrderUi.tsx
var import_styled_components11 = require("styled-components");
var import_IconUnion3 = require("@maverick/icons/dist/IconUnion");
var import_IconBonefishgrillCurbside = require("@maverick/icons/dist/IconBonefishgrillCurbside");
var import_IconBonefishgrillDelivery = require("@maverick/icons/dist/IconBonefishgrillDelivery");
var import_IconBonefishgrillPickup = require("@maverick/icons/dist/IconBonefishgrillPickup");
var import_IconLocationBonefish2 = require("@maverick/icons/dist/IconLocationBonefish");
var import_IconLocationOutlineBonefish2 = require("@maverick/icons/dist/IconLocationOutlineBonefish");
var import_IconCloche = require("@maverick/icons/dist/IconCloche");
var import_IconBeefStroke2 = require("@maverick/icons/dist/IconBeefStroke");
var import_IconClipboard2 = require("@maverick/icons/dist/IconClipboard");
var import_SeloIconR2 = require("@maverick/icons/dist/SeloIconR");
var import_IconUserBonefish = require("@maverick/icons/dist/IconUserBonefish");
var import_IconUserFilledBonefish = require("@maverick/icons/dist/IconUserFilledBonefish");
var import_IconBagBonefish = require("@maverick/icons/dist/IconBagBonefish");
var import_IconBagStampBonefish = require("@maverick/icons/dist/IconBagStampBonefish");
var import_IconGlasses = require("@maverick/icons/dist/IconGlasses");
var import_IconDollarSignBonefish = require("@maverick/icons/dist/IconDollarSignBonefish");
var import_IconMapDeliveryBonefish = require("@maverick/icons/dist/IconMapDeliveryBonefish");
var import_IconLogoBonefish = require("@maverick/icons/dist/IconLogoBonefish");
var import_IconCallBonefish = require("@maverick/icons/dist/IconCallBonefish");
var import_IconEditBonefish = require("@maverick/icons/dist/IconEditBonefish");
var import_utils = require("@maverick/utils");
var import_IconRightBonefish = require("@maverick/icons/dist/IconRightBonefish");
var import_IconLeftBonefish = require("@maverick/icons/dist/IconLeftBonefish");
var import_IconFlame2 = require("@maverick/icons/dist/IconFlame");
var import_IconPin4 = require("@maverick/icons/dist/IconPin");
var import_IconCheck2 = require("@maverick/icons/dist/IconCheck");
var import_IconBonefishAppOnlyCategory = require("@maverick/icons/dist/IconBonefishAppOnlyCategory");
var import_IconMissingPoints3 = require("@maverick/icons/dist/IconMissingPoints");
var import_UserLoggedBonefish = require("@maverick/icons/dist/UserLoggedBonefish");
var import_FavoriteBonefish = require("@maverick/icons/dist/FavoriteBonefish");
var import_jsx_runtime3 = require("react/jsx-runtime");
var BRAND3 = "bonefishgrill" /* Bonefish */;
var SITECORE_IMAGES_URL9 = `https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/${BRAND3}/Order`;
var bonefishgrillOrderUi = {
  app: {
    brand: "bonefishgrill" /* Bonefish */,
    name: "Bonefish Grill",
    shortName: "Bonefish",
    providerAuth0: "bbiAuth0"
  },
  global: import_styled_components11.css`
		body {
			font-family: 'DinNextLTPro-Regular', sans-serif;
			color: #2d2926;
		}
	`,
  mapPopup: import_styled_components11.css`
		font-family: 'DinNextLTPro', sans-serif;
	`,
  colors: {
    primary: {
      lightContrast: "#D0E1E2",
      light: "#AAC8CB",
      medium: "#3E7A7B",
      dark: "#2B5556",
      variant: "#3E7A7B",
      lightVariant: "#AAC8CB"
    },
    secondary: {
      light: "#F9F9F9",
      medium: "#AEB8C0",
      dark: "#78838E",
      variant: "#CEB964",
      darkVariant: "#2D2926"
    },
    tertiary: {
      light: "#F6F6F6",
      medium: "#767676",
      dark: "#2D2926"
    },
    complementary: {
      success: "#2D8633",
      error: "#B00020",
      warning: "#FFB61F",
      information: "#1C9BDB",
      hyperlink: "#3078CD",
      disabled: "#ABABAB"
    },
    neutral: {
      light: "#FFFFFF",
      medium: "#EAEAEA",
      dark: "#232222"
    }
  },
  typography: {
    headline: {
      h1: import_styled_components11.css`
				font-family: 'GTWalsheim', sans-serif;
				font-size: 48px;
				line-height: 52px;
			`,
      h2: import_styled_components11.css`
				font-family: 'GTWalsheim', sans-serif;
				font-size: 40px;
				line-height: 44px;
			`,
      h3: import_styled_components11.css`
				font-family: 'GTWalsheim', sans-serif;
				font-size: 32px;
				line-height: 36px;
			`,
      h4: import_styled_components11.css`
				font-family: 'GTWalsheim', sans-serif;
				font-size: 24px;
				line-height: 28px;
			`,
      h4Variant: import_styled_components11.css`
				font-weight: 500;
				font-size: 24px;
				line-height: 28px;
			`,
      h5: import_styled_components11.css`
				font-weight: 500;
				font-size: 20px;
				line-height: 24px;
			`,
      h6: import_styled_components11.css`
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			`
    },
    title: {
      title: import_styled_components11.css`
				font-weight: 500;
				font-size: 32px;
				line-height: 36px;
			`,
      subtitle: import_styled_components11.css`
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			`,
      dRBanner: import_styled_components11.css`
				font-family: 'DinNextLTPro-Medium', sans-serif;
			`
    },
    body: {
      bodyLarge: import_styled_components11.css`
				font-size: 18px;
				line-height: 24px;
			`,
      body: import_styled_components11.css`
				font-size: 16px;
				line-height: 20px;
			`,
      bodySmall: import_styled_components11.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    inputs: {
      buttonLarge: import_styled_components11.css`
				font-weight: 500;
				font-size: 18px;
				line-height: 24px;
			`,
      button: import_styled_components11.css`
				font-weight: 500;
				font-size: 16px;
				line-height: 20px;
			`,
      buttonSmall: import_styled_components11.css`
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
			`,
      inputText: import_styled_components11.css`
				font-size: 16px;
				line-height: 20px;
			`,
      buttonItalic: import_styled_components11.css`
				font-weight: 500;
				font-style: italic;
				font-size: 14px;
				font-weight: 18px;
			`,
      inputLabel: import_styled_components11.css`
				font-size: 14px;
				line-height: 18px;
			`
    },
    caption: {
      caption: import_styled_components11.css`
				font-size: 12px;
				line-height: 16px;
			`,
      captionMedium: import_styled_components11.css`
				font-weight: 550;
				font-size: 12px;
				line-height: 16px;
			`
    },
    overline: {
      overline: import_styled_components11.css`
				font-size: 10px;
				line-height: 14px;
			`
    },
    tagFeature: import_styled_components11.css`
			font-family: 'GTWalsheim', sans-serif;
			font-size: 12px;
			font-weight: 400;
			padding: 4px 6px;
		`,
    tagProduct: import_styled_components11.css``
  },
  fontImports: () => /* @__PURE__ */ (0, import_jsx_runtime3.jsxs)(import_jsx_runtime3.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)("link", { rel: "preconnect", href: "https://fonts.googleapis.com" }),
    /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
      "link",
      {
        href: "https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap",
        rel: "stylesheet"
      }
    )
  ] }),
  fontFaces: import_styled_components11.css`
		@font-face {
			font-family: 'DinNextLTPro-Light';
			src: url('/order/fonts/DinNextLTPro/DINNextLTPro-Light.otf') format('opentype');
			font-weight: normal;
		}

		@font-face {
			font-family: 'DinNextLTPro-Medium';
			src: url('/order/fonts/DinNextLTPro/DINNextLTPro-Medium.otf') format('opentype');
			font-weight: 500;
		}

		@font-face {
			font-family: 'DinNextLTPro-Condensed';
			src: url('/order/fonts/DinNextLTPro/DINNextLTPro-MediumCond.otf') format('opentype');
			font-weight: normal;
		}

		@font-face {
			font-family: 'DinNextLTPro-Regular';
			src: url('/order/fonts/DinNextLTPro/DINNextLTPro-Regular.otf') format('opentype');
			font-weight: normal;
		}

		@font-face {
			font-family: 'GTWalsheim';
			src: url('/order/fonts/GTWalsheim/GT-Walsheim-Regular.woff') format('woff');
			font-weight: normal;
		}
	`,
  texts: {
    SelectHandoffMethodTitle: "Hello!",
    CheckoutPoints: "points",
    DineRewardsCardTitleGuest: "Get rewarded!",
    DineRewardsCardTitleLogged: "Thank You!",
    DineRewardsAuthenticatedBannerText: "It's our pleasure to serve you!<br/> Check back in 24 hours to see points earned from this order!",
    BlankOrderSummaryTitle: "Oops!",
    BlankOrderSummarySubtitle: `Your basket is empty. Start your order below.`,
    DineRewardsBannerTitle: "Get rewarded!",
    NewFavoriteNamePlaceholder: "e.g. Down Under Dinner",
    AddOnsDescription: "",
    DineRewardsSalutation: "Welcome",
    EmptyRecentOrdersBoldText: `It seems you don't have any orders yet.`,
    EmptyRecentOrdersNormalText: "Get your dish now!",
    MyFavoritesPageTitle: "My favorites",
    EmptyMyFavoritesBoldText: `You don't have any favorites yet.`,
    EmptyMyFavoritesNormalText: `Get your dish and favorite your order!`,
    ModalTitle: "Attention!",
    TitleMessageErrorPage: "Sorry, an unexpected error has occurred.",
    OrderSummarySuggestion: "May We Suggest",
    AddOnsSubtitle: "Starters and Sharing",
    FreeSubtitle: "Bread & Utensils",
    EmptytRewardsNormalText: "Get your dish to start earning!",
    EmptytActivityNormalText: "Get your dish now!",
    DRBannerTitle: "Get a $10 reward!",
    SignUpRewardText: "Earn points & redeem rewards",
    SignUpPhoneText: "Seamless online ordering",
    SignUpReorderText: "Quickly reorder recents & favorites",
    NoRestaurantFoundDelivery: "There are no locations near you that offer delivery to your destination. Please place your order for curbside pickup."
  },
  icons: {
    curbside: import_IconBonefishgrillCurbside.IconBonefishgrillCurbside,
    delivery: import_IconBonefishgrillDelivery.IconBonefishgrillDelivery,
    pickup: import_IconBonefishgrillPickup.IconBonefishgrillPickup,
    iconDollarSign: import_IconDollarSignBonefish.IconDollarSignBonefish,
    iconCall: import_IconCallBonefish.IconCallBonefish,
    iconEdit: import_IconEditBonefish.IconEditBonefish,
    iconUnion: import_IconUnion3.IconUnion,
    iconLocation: import_IconLocationBonefish2.IconLocationBonefish,
    iconLocationOutline: import_IconLocationOutlineBonefish2.IconLocationOutlineBonefish,
    iconLocationOutlineColor: "#2C2C2B",
    iconRecentOrders: import_IconCloche.IconCloche,
    iconMyRewards: import_SeloIconR2.SeloIconR,
    iconContactInfo: import_IconUserFilledBonefish.IconUserFilledBonefish,
    iconFooter: import_IconGlasses.IconGlasses,
    iconBasketStamp: import_IconBagStampBonefish.IconBagStampBonefish,
    iconBasketHeader: import_IconBagBonefish.IconBagBonefish,
    iconUserHeader: import_IconUserBonefish.IconUserBonefish,
    iconUserLoggedHeader: import_IconUserFilledBonefish.IconUserFilledBonefish,
    iconUserLoggedAnimation: import_UserLoggedBonefish.UserLoggedBonefish,
    iconHeaderColor: "#FFFFFF",
    iconModal: void 0,
    iconEat: import_IconBeefStroke2.IconBeefStroke,
    iconEarn: import_IconClipboard2.IconClipboard,
    iconMapDelivery: import_IconMapDeliveryBonefish.IconMapDeliveryBonefish,
    iconLocationColor: "#2D2926",
    iconLogo: import_IconLogoBonefish.IconLogoBonefish,
    iconProfilePageLeft: import_IconLeftBonefish.IconLeftBonefish,
    iconProfilePageRight: import_IconRightBonefish.IconRightBonefish,
    iconTemperature: import_IconFlame2.IconFlame,
    iconNoRestaurantsFound: import_IconPin4.IconPin,
    iconNoRestaurantsFoundSize: import_styled_components11.css`
			width: 42px;
			height: 54px;
		`,
    iconSelectedCard: import_IconCheck2.IconCheck,
    iconAppOnlyCategory: import_IconBonefishAppOnlyCategory.IconBonefishAppOnlyCategory,
    iconMissingPoints: import_IconMissingPoints3.IconMissingPoints,
    iconFavoriteAnimation: import_FavoriteBonefish.FavoriteBonefish
  },
  images: {
    checkoutBackground: `${SITECORE_IMAGES_URL9}/${BRAND3}-checkout-bg.webp`,
    orderReceivedBackground: `${SITECORE_IMAGES_URL9}/${BRAND3}-order-received-bg.webp`,
    blankOrderSummaryBackground: `${SITECORE_IMAGES_URL9}/${BRAND3}-empty-basket-bg.webp`,
    dineRewardsBanner: `${SITECORE_IMAGES_URL9}/${BRAND3}-dr-card-order-summary.webp`,
    bgRewardsBanner: `${SITECORE_IMAGES_URL9}/${BRAND3}-dr-banner-bg.webp`,
    recentOrdersRightSide: `${SITECORE_IMAGES_URL9}/${BRAND3}-recent-orders-bg.webp`,
    myFavoritesRightSide: `${SITECORE_IMAGES_URL9}/${BRAND3}-my-faves-bg.webp`,
    myRewardsRightSide: `${SITECORE_IMAGES_URL9}/${BRAND3}-my-rewards-bg.webp`,
    myActivityRightSide: `${SITECORE_IMAGES_URL9}/${BRAND3}-my-activity-bg.webp`,
    productPlaceholder: `${SITECORE_IMAGES_URL9}/${BRAND3}-product-placeholder.webp`,
    emptyUserPageBackground: import_styled_components11.css`
			background: none;

			@media screen and (min-width: ${import_utils.TABLET_BREAKPOINT}px) {
				background: url(${`${SITECORE_IMAGES_URL9}/${BRAND3}-empty-user-pages-bg.webp`}) bottom no-repeat;
				background-size: 80%;
			}

			@media screen and (min-width: ${import_utils.ULTRAWIDE_BREAKPOINT}px) {
				background-size: 895px;
			}
		`,
    DRBannerBackgroundImage: `${SITECORE_IMAGES_URL9}/${BRAND3}-order-summary-dr-banner.webp`
  },
  links: {
    nutritionAdditionalInfo: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/bonefishgrill/Files/bonefishgrill_nutritional_information.pdf",
    privacyPolicy: "https://www.bonefishgrill.com/privacy-rights/privacy-policy",
    logoMeta: "https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/bonefishgrill/Auth0/bonefishgrill-logo.svg",
    navBarBackground: "",
    appDeepLink: "bonefish://rewards",
    appStoreAndroid: "https://play.google.com/store/apps/details?id=com.bonefish",
    appStoreIos: "https://apps.apple.com/us/app/bonefish-grill/id1130417939"
  },
  booleans: {
    productImageTextureDetail: false
  },
  tipOptions: {
    tipOptions: [
      { label: "15%", value: 0.15 },
      { label: "18%", value: 0.18 },
      { label: "20%", value: 0.2 }
    ]
  },
  fonts: {
    src: 'url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/bonefishgrill/Auth0/DINNextLTPro-Regular.otf) format("opentype")',
    family: "DinNextLTPro"
  },
  specificStyle: {
    orderTimeLabelText: import_styled_components11.css`
			margin-top: 4px;
		`,
    rewardsCounter: import_styled_components11.css`
			padding: 4px 1px 0 0;
		`,
    messageInfoMessage: import_styled_components11.css`
			margin-top: 2px;
		`
  },
  productTag: {
    clipPathRight: "polygon(0 100%, 0% 0%, 100% 0, calc(100% - 12px) 50%, 100% 100%)",
    showClipPathLeft: true,
    left: "-9px",
    top: "4px",
    borderTopLeft: "0px",
    smallTag: {
      left: "-9px"
    },
    largeTag: {
      left: "-13px"
    },
    fontWeight: 400
  }
};

// src/bbi/bonefishgrill/bonefishgrillTheme.ts
var BonefishgrillTheme = __spreadProps(__spreadValues({}, BonefishgrillSharedTheme), {
  components: __spreadValues({}, BonefishgrillUITheme),
  cmsComponents: __spreadValues({}, BonefishgrillCmsTheme),
  orderUi: bonefishgrillOrderUi
});

// src/bbi/bonefishgrill/bonefishgrillButtons.ts
var import_styled_components12 = __toESM(require("styled-components"));
var BonefishgrillBaseButton = import_styled_components12.default.a`
	width: auto;
	letter-spacing: 2.5px;
	text-transform: uppercase;
	text-align: center;
	border-radius: 6px;
	border: 1px solid;
	font-family: 'DinNextLTPro-Medium';
	font-size: 1rem;
	padding: 20px;

	@media (max-width: 768px) {
		width: 100%;
	}
`;
var BonefishgrillGrayButton = (0, import_styled_components12.default)(BonefishgrillBaseButton)`
	color: #f6f6f6;
	background-color: rgb(0, 0, 0, 0.3);
	border-color: #f6f6f6;
	padding: 20px 30px;

	:hover {
		border-color: #ffffff;
		background-color: #ffffff;
		color: #444444;
	}
`;
var BonefishgrillWhiteButton = (0, import_styled_components12.default)(BonefishgrillBaseButton)`
	background-color: #ffffff;
	color: #404041;
	font-weight: 500;
	border-color: #404041;

	&:hover {
		color: #ffffff;
		background-color: #444444;
	}
`;
var BonefishgrillGiftCardButton = (0, import_styled_components12.default)(BonefishgrillBaseButton)`
	background-color: '#2D2926';
	color: '#2D2926';
	font-size: 18px;
	line-height: 24px;
	font-weight: 500;

	&:hover {
		color: #ffffff;
		background-color: #444444;
	}
`;
var BonefishgrillTranparentButton = (0, import_styled_components12.default)(BonefishgrillBaseButton)`
	background-color: transparent;
	color: #444444;
	font-weight: 500;
	border-color: #000000;

	&:hover {
		color: #ffffff;
		background-color: #444444;
	}
`;

// src/bbi/bistro/bistroComponentsStyle/bistroTextButtonBanner.ts
var bistroTextButtonStyle = {
  title: {
    fontColor: "#ffffff",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      small: "1.0rem",
      medium: "1.25rem",
      large: "1.5rem"
    },
    fontWeight: "700"
  },
  button: {
    fontColor: "#222",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontSizes: {
      small: "1rem",
      medium: "1.25rem",
      large: "1.5rem"
    },
    fontWeight: "500",
    backgroundColor: "#ffffff",
    hover: {
      backgroundColor: "#E5E5E5",
      opacity: "1"
    }
  }
};

// src/bbi/bistro/bistroComponentsStyle/bistroFooter.ts
var bistroFooterStyle = {
  letterSpacing: "0px",
  borderTop: "none",
  backgroundColor: "#1C1C1C",
  navbar: {
    marginBottom: "10px",
    color: "#EFEDE4",
    featuredItems: {
      fontFamily: "DinNextLTPro-Regular,sans-serif",
      menuItem: {
        fontSizes: {
          smallest: "16px",
          small: "16px",
          medium: "16px",
          large: "16px",
          largest: "16px"
        }
      }
    },
    unfeaturedItems: {
      fontFamily: "DinNextLTPro-Regular,sans-serif",
      menuItem: {
        fontSizes: {
          smallest: "14px",
          small: "14px",
          medium: "14px",
          large: "14px",
          largest: "14px"
        }
      }
    }
  },
  socialMediaPanel: {
    fontFamily: "DinNextLTPro-Regular,sans-serif",
    fontSizes: {
      medium: "14px",
      large: "14px",
      largest: "14px"
    }
  },
  cookiesBar: {
    backgroundColor: "#1C1C1C",
    fontFamily: "DinNextLTPro-Regular,sans-serif"
  }
};

// src/bbi/bistro/bistroComponentsStyle/bistroJumbotron.ts
var bistroJumbotronStyles = {
  title: {
    fontColor: "#222",
    fontFamily: "Queens-W",
    fontSizes: {
      medium: "2rem",
      small: ""
    },
    fontWeight: "500"
  },
  text: {
    fontColor: "#222",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      medium: "1rem",
      small: ""
    },
    fontWeight: "400",
    lineHeight: { small: "", medium: "" },
    paddingBottom: ""
  },
  singleButton: {
    fontColor: "#404041",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      small: "1.5rem",
      medium: "1.5rem"
    },
    fontWeight: "500",
    backgroundColor: "#ffffff",
    border: {
      radius: "4px",
      color: "",
      width: "",
      style: ""
    },
    hover: {
      backgroundColor: "#8F2620",
      fontColor: "",
      opacity: 1
    },
    padding: "22px 32px 16px 32px",
    letterSpace: "1px",
    lineHeight: "28px"
  },
  button: {
    fontColor: "#ffffff",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontSizes: {
      small: "1.5rem",
      medium: "1.5rem",
      large: "1.5rem"
    },
    fontWeight: "500",
    backgroundColor: "#B33028",
    hover: {
      backgroundColor: "#8F2620",
      fontColor: "",
      opacity: 1
    },
    border: {
      radius: "4px",
      width: "",
      color: "",
      style: ""
    },
    padding: "16px",
    letterSpace: "1px",
    paddingMobile: ""
  },
  notes: {
    fontColor: "#232222",
    fontFamily: "DinNextLTPro-Regular",
    fontSizes: {
      medium: "1rem"
    },
    fontWeight: "400"
  },
  padding: "0px 25px",
  titlePadding: "0px",
  paddingMobile: "40px 34px"
};

// src/bbi/bistro/bistroComponentsStyle/bistroHero.ts
var bistroHeroStyle = {
  button: {
    primary: {
      borderRadius: "4px",
      fontColor: "#1C1C1B",
      fontWeight: "700",
      fontFamily: "DinNextLTPro-MediumCondensed",
      letterSpacing: "0",
      fontSizes: {
        medium: "1.5rem"
      },
      backgroundColor: "#ffffff",
      hover: {
        opacity: 0.8,
        backgroundColor: "#FFFFFF",
        letterSpacing: "0",
        font: {
          fontColor: "#1C1C1B",
          fontFamily: "DinNextLTPro-MediumCondensed",
          fontWeight: "400"
        }
      },
      borderWidth: "2px",
      lineHeight: "50px",
      padding: "0 40px"
    },
    secondary: {
      borderRadius: "4px",
      fontColor: "#1C1C1B",
      fontWeight: "700",
      fontFamily: "DinNextLTPro-MediumCondensed",
      letterSpacing: "0",
      fontSizes: {
        medium: "1.5rem"
      },
      backgroundColor: "#ffffff",
      hover: {
        opacity: 0.8,
        backgroundColor: "#FFFFFF",
        letterSpacing: "0",
        font: {
          fontColor: "#1C1C1B",
          fontFamily: "DinNextLTPro-MediumCondensed",
          fontWeight: "400"
        }
      },
      borderWidth: "2px",
      lineHeight: "50px",
      padding: "0 40px"
    }
  },
  overTitle: {
    fontColor: "#FFFFFF",
    fontFamily: "DinNextLTPro-Regular",
    fontWeight: "600",
    textShadow: "0",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    },
    letterSpacing: "1px"
  },
  title: {
    fontColor: "#FFFFFF",
    fontFamily: "pulpo-rust-100",
    fontWeight: "500",
    textShadow: "0",
    fontSizes: {
      medium: "4.25rem",
      small: "3rem",
      smallest: "3rem"
    },
    textTransform: "none",
    lineHeight: {
      medium: "5.4rem",
      small: "3.25rem",
      smallest: "3.25rem"
    }
  },
  text: {
    fontColor: "#FFFFFF",
    fontFamily: "DinNextLTPro-Regular",
    fontWeight: "400",
    fontSizes: {
      medium: "1.5rem",
      small: "1.2rem",
      smallest: "1rem"
    }
  },
  container: {
    background: "linear-gradient(to left, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.2) 100%);"
  }
};

// src/bbi/bistro/bistroComponentsStyle/bistroCatalog.ts
var bistroCatalogStyle = {
  title: {
    fontColor: "#404041",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontSizes: {
      medium: "2rem"
    },
    fontWeight: "400"
  },
  button: {
    backgroundColor: "#B33028",
    hover: {
      backgroundColor: "#8F2620",
      fontColor: "#fff",
      opacity: 1
    },
    border: {
      width: "",
      radius: "4px",
      color: "",
      style: ""
    },
    padding: "16px 32px",
    letterSpace: "",
    fontColor: "#fff",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontWeight: "500",
    fontSizes: {
      smallest: "24px",
      small: "24px",
      medium: "24px",
      large: "24px",
      largest: "24px"
    }
  },
  backgroundColor: "#fcfaee",
  maxWidthView: "1114px",
  verticalPadding: "20px 0px",
  verticalPaddingMobile: "20px 0px",
  gap: "32px"
};

// src/bbi/bistro/bistroComponentsStyle/bistroCatalogItem.ts
var bistroCatalogItemStyle = {
  borderRadius: "8px",
  title: {
    font: {
      fontColor: "#B33028",
      fontFamily: "DinNextLTPro-MediumCondensed",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        smallest: "1rem",
        small: "2rem",
        medium: "2rem",
        large: "2rem",
        largest: "2rem"
      }
    }
  },
  subtitle: {
    font: {
      fontColor: "#222",
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        smallest: "1rem",
        small: "1rem",
        medium: "1rem",
        large: "1rem",
        largest: "1rem"
      }
    }
  },
  text: {
    font: {
      fontColor: "#222",
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        smallest: "1rem",
        small: "1rem",
        medium: "1rem",
        large: "1rem",
        largest: "1rem"
      }
    }
  },
  note: {
    font: {
      fontColor: "#222",
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        smallest: "1rem",
        small: "1rem",
        medium: "1rem",
        large: "1rem",
        largest: "1rem"
      }
    }
  },
  button: {
    backgroundColor: "#B33028",
    hover: {
      backgroundColor: "#8F2620",
      fontColor: "#fff",
      opacity: 1
    },
    border: {
      width: "",
      radius: "4px",
      color: "transparent",
      style: ""
    },
    padding: "16px 32px",
    letterSpace: "1px",
    fontColor: "#fff",
    fontFamily: "DinNextLTPro-MediumCondensed",
    fontWeight: "500",
    fontSizes: {
      smallest: "24px",
      small: "24px",
      medium: "24px",
      large: "24px",
      largest: "24px"
    },
    marginRight: ""
  },
  backgroundColor: {
    default: "#fcfaee",
    verticalView: "#ffff"
  },
  verticalView: {
    image: {
      padding: "0"
    },
    textAlign: "center"
  },
  margin: ""
};

// src/bbi/bistro/bistroComponentsStyle/bistroCatalogItemSecondary.ts
var bistroCatalogItemSecondary = {
  title: {
    font: {
      fontColor: "#222",
      fontFamily: "Queens-W",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "3.5rem",
        small: "3.5rem",
        smallest: "3.5rem"
      }
    },
    textTransform: "uppercase",
    marginBottom: "12px"
  },
  subtitle: {
    font: {
      fontFamily: "Queens-W",
      fontColor: "#222",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "3.5rem",
        small: "3.5rem",
        smallest: "3.5rem"
      }
    },
    lineHeightMobile: "72px",
    marginBottom: "27px"
  },
  text: {
    font: {
      fontColor: "#222",
      fontFamily: "DinNextLTPro-Regular",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1rem",
        small: "1rem",
        smallest: "1rem"
      }
    },
    marginBottom: "24px"
  },
  button: {
    font: {
      fontFamily: "DinNextLTPro-MediumCondensed",
      fontColor: "#fff",
      fontWeight: "500"
    },
    fontSize: {
      fontSizes: {
        medium: "1.25rem",
        small: "1rem",
        smallest: "1rem"
      }
    },
    marginTop: "44px",
    marginTopMobile: "16px",
    padding: "12px 0px",
    marginInternal: "",
    iconColor: "#666666"
  },
  backgroundColor: "#fcfaee",
  contentPaddingMobile: "24px 36px",
  contentPadding: "0px 76px",
  containerFlex: "",
  alignMain: "center",
  verticalView: {
    fontColor: "",
    title: {
      fontFamily: ""
    },
    subtitle: {
      fontFamily: ""
    }
  }
};

// src/bbi/bistro/bistroComponentsStyle/bistroLine.ts
var bistroLineStyle = {
  text: {
    font: {
      fontColor: "#222222",
      fontFamily: "pulpo-rust-100",
      fontWeight: "400"
    },
    fontSize: {
      fontSizes: {
        medium: "1.187rem",
        small: "1.187rem",
        smallest: "1.187rem"
      }
    }
  },
  borderTop: {
    Dotted: "1px dashed #222222",
    Solid: "1px solid #222222"
  },
  backgroundColor: "#fcfaee"
};

// src/bbi/bistro/bistroUITheme.ts
var BistroUITheme = __spreadProps(__spreadValues({}, CarrabbasUITheme), {
  textButtonBanner: bistroTextButtonStyle,
  footer: bistroFooterStyle,
  jumbotron: bistroJumbotronStyles,
  hero: bistroHeroStyle,
  catalog: bistroCatalogStyle,
  catalogItem: bistroCatalogItemStyle,
  catalogItemSecondary: bistroCatalogItemSecondary,
  line: bistroLineStyle
});

// src/bbi/bistro/bistroTheme.ts
var BistroTheme = __spreadProps(__spreadValues({}, CarrabbasSharedTheme), {
  components: __spreadValues({}, BistroUITheme),
  cmsComponents: __spreadValues({}, CarrabbasCmsTheme),
  orderUi: CarrabbasOrderUi
});
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  BistroTheme,
  BonefishgrillCmsTheme,
  BonefishgrillGiftCardButton,
  BonefishgrillGrayButton,
  BonefishgrillSharedTheme,
  BonefishgrillTheme,
  BonefishgrillTranparentButton,
  BonefishgrillUITheme,
  BonefishgrillWhiteButton,
  Brand,
  CarrabbasCmsTheme,
  CarrabbasGrayButton,
  CarrabbasSharedTheme,
  CarrabbasTheme,
  CarrabbasUITheme,
  OutbackCmsTheme,
  OutbackRedButton,
  OutbackSharedTheme,
  OutbackTheme,
  OutbackTransparentButton,
  OutbackUITheme
});
